import React, { memo } from "react";

import { checkIosPwa } from "helpers/devices";
import { downloadFileInPWA } from "helpers/pwa";
import share from "static/img/share.svg";
import innovativeProducts from "static/docs/innovative-products.pdf";
import recommendationStrongIdeas from "static/docs/recommendation_strong_ideas.pdf";
import attention from "static/img/attention.svg";
import presentationBestStartups from "static/docs/presentationBestStartups.png";
import finalistDiploma from "static/docs/finalistDiploma.png";
import participantCertificate from "static/docs/participantCertificate.png";
import smartDialogueFinalist from "static/docs/smartDialogueFinalist.pdf";
import congressOfYoungScientists from "static/docs/congressOfYoungScientists.pdf";

export const LandingDocs = memo(({ t }) => {
    const clickHandler = async (link, fileName) => {
        if (checkIosPwa()) {
            await downloadFileInPWA(link, fileName);
        } else {
            window.open(link, "_blank");
        }
    };

    return (
        <>
            <h2 className="landing-services__title landing-services__docsTitle">
                {t("documents_title")}
            </h2>

            <p className="landing-services__docsText">{t("documents_subtitle")}</p>

            <div className="landing-services__docsRow">
                <div className="landing-services__docsCol-yellow landing-services__docsCol">
                    <img src={share} alt="share-icon" />
                    <a
                        href="https://drive.google.com/file/d/1MWrnra2q6WmD2OE40OrHvao4VGQ52QY2/view?usp=sharing"
                        target="_blank"
                        rel="noreferrer external"
                    >
                        {t("documents_list_1")}
                    </a>
                </div>
                <div className="landing-services__docsCol-blue landing-services__docsCol">
                    <img src={share} alt="share-icon" />
                    <a
                        href="https://innovationmap.innoagency.ru/catalog/product/46408"
                        target="_blank"
                        rel="noreferrer external"
                    >
                        {t("documents_list_2")}
                    </a>
                </div>

                <div className="landing-services__docsCol-blue landing-services__docsCol">
                    <img src={share} alt="share-icon" />
                    <a
                        href="https://smarteka.com/practices/testuonline-platforma-avtomatizacii-skolnoj-psihodiagnostiki"
                        target="_blank"
                        rel="noreferrer external"
                    >
                        {t("documents_list_3")}
                    </a>
                </div>

                <div className="landing-services__docsCol-yellow landing-services__docsCol">
                    <img src={share} alt="share-icon" />
                    <button
                        onClick={() => clickHandler(innovativeProducts, "innovative-products.pdf")}
                    >
                        {t("documents_list_4")}
                    </button>
                </div>

                <div className="landing-services__docsCol-yellow landing-services__docsCol">
                    <img src={share} alt="share-icon" />
                    <a
                        href="https://reestr.digital.gov.ru/reestr/1149392/"
                        target="_blank"
                        rel="noreferrer external"
                    >
                        {t("documents_list_5")}
                    </a>
                </div>

                <div className="landing-services__docsCol-blue landing-services__docsCol">
                    <img src={share} alt="share-icon" />
                    <a
                        href="https://mbm.mos.ru/special/vitrina?page=1&q=testu"
                        target="_blank"
                        rel="noreferrer external"
                    >
                        {t("documents_list_6")}
                    </a>
                </div>

                <div className="landing-services__docsCol-blue landing-services__docsCol">
                    <img src={share} alt="share-icon" />
                    <button
                        onClick={() =>
                            clickHandler(
                                recommendationStrongIdeas,
                                "recommendation_strong_ideas.pdf"
                            )
                        }
                    >
                        {t("documents_list_7")}
                    </button>
                </div>

                <div className="landing-services__docsCol-yellow landing-services__docsCol">
                    <img src={share} alt="share-icon" />
                    <a
                        href="https://открытодлявсех.рф/tpost/rvmxmejxs1-ooo-laboratoriya-sistem-samorazvitiya"
                        target="_blank"
                        rel="noreferrer external"
                    >
                        {t("documents_list_8")}
                    </a>
                </div>

                <div className="landing-services__docsCol-yellow landing-services__docsCol">
                    <img src={share} alt="share-icon" />
                    <button
                        onClick={() =>
                            clickHandler(presentationBestStartups, "presentationBestStartups.png")
                        }
                    >
                        {t("documents_list_9")}
                    </button>
                </div>

                <div className="landing-services__docsCol-blue landing-services__docsCol">
                    <img src={share} alt="share-icon" />
                    <button onClick={() => clickHandler(finalistDiploma, "finalistDiploma.png")}>
                        {t("documents_list_10")}
                    </button>
                </div>

                <div className="landing-services__docsCol-blue landing-services__docsCol">
                    <img src={share} alt="share-icon" />
                    <button
                        onClick={() =>
                            clickHandler(participantCertificate, "participantCertificate.png")
                        }
                    >
                        {t("documents_list_11")}
                    </button>
                </div>

                <div className="landing-services__docsCol-yellow landing-services__docsCol">
                    <img src={share} alt="share-icon" />
                    <button
                        onClick={() =>
                            clickHandler(smartDialogueFinalist, "smartDialogueFinalist.pdf")
                        }
                    >
                        {t("documents_list_12")}
                    </button>
                </div>

                <div className="landing-services__docsCol-yellow landing-services__docsCol">
                    <img src={share} alt="share-icon" />
                    <button
                        onClick={() =>
                            clickHandler(congressOfYoungScientists, "congressOfYoungScientists.pdf")
                        }
                    >
                        {t("documents_list_13")}
                    </button>
                </div>
            </div>

            <div className="alertBlock">
                <div className="alertBlock__row">
                    <img src={attention} alt="" />
                    <p>{t("documents_desc_1")}</p>
                </div>
                <p className="p-2">{t("documents_desc_2")}</p>
            </div>
        </>
    );
});
