import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import axios from "axios";

import { Layout } from "components/Layout";

const styles = {
    button: {
        position: "absolute",
        alignItems: "center",
        top: "1rem",
        right: "1rem",
        background: "#f4f7f9",
        borderRadius: "7px",
        color: "#122443",
        padding: "7px 10px",
        fontFamily: "inherit",
    },
    h4: {
        color: "#122443",
        fontSize: "20px",
        letterSpacing: "0.4px",
        marginBottom: "1rem",
        fontWeight: "normal",
    },
    list: {
        flexGrow: "initial",
        marginBottom: "3rem",
    },
};

export const AdvicesPupils = () => {
    const history = useHistory();
    const classId = useParams().id;
    const [pupils, setPupils] = useState([]);
    const [classData, setClassData] = useState(null);

    useEffect(() => {
        if (classId) {
            (async () => {
                const { data } = await axios.get(`classes/pupils/${classId}`);

                setPupils(data.pupils.filter((p) => !p.isArchive));
                setClassData(data.class);
            })();
        }
    }, [classId]);

    const backBtnHandler = () => {
        const schoolId = classData?.school;
        const link = schoolId ? `/advices/schools/${schoolId}/classes` : -1;

        history.push(link);
    };

    return (
        <Layout>
            <div className="page">
                <header className="page__header">
                    <button
                        onClick={backBtnHandler}
                        className="icon-btn page__icon-btn page__icon-btn_left icon-btn_back"
                    />
                    <p className="page__title">
                        {classData?.number} {classData?.letter}
                    </p>
                </header>

                <div className="school-page__wrapper">
                    <div className="list gesturedZone" style={styles.list}>
                        {pupils.map((pupil) => (
                            <Link
                                to={`/advices?pupilId=${pupil._id}`}
                                key={pupil._id}
                                style={{
                                    position: "relative",
                                }}
                                className="list__item"
                            >
                                <p>
                                    {pupil.surname} {pupil.name}
                                </p>
                                <p className="list__desc">
                                    {classData?.psych.surname} {classData?.psych.name}{" "}
                                    {classData?.psych.patronymic}
                                </p>
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
        </Layout>
    );
};
