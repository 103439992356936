import React, { memo } from "react";
import { useHistory } from "react-router-dom";

export const BackButton = memo(({ link }) => {
    const history = useHistory();

    const goBack = () => {
        if (link) {
            history.push(link);
        } else {
            history.goBack();
        }
    };

    return (
        <button
            onClick={goBack}
            className="icon-btn page__icon-btn page__icon-btn_left icon-btn_back"
        />
    );
});
