import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Loader } from "components/Loader";
import { Layout } from "components/Layout";
import { SureAlert } from "components/SureAlert";

export const PupilsStep = (props) => {
    const { t } = useTranslation("tests");
    const [isSureAlert, setIsSureAlert] = useState(false);

    function onSendClick() {
        setIsSureAlert(true);
    }

    function onCloseClick() {
        setIsSureAlert(false);
    }

    if (props.step !== 4) {
        return null;
    }

    if (props.loading || !props.classData) {
        return <Loader />;
    }

    const group = props.classData;
    const filteredPupils = props.filteredPupils;

    return (
        <Layout>
            <div className="page__content" style={{ paddingBottom: 100 + "px" }}>
                <h3>{t("select_pupils")}</h3>
                <p className="page__desc">
                    {group.number}
                    {group.letter}, {t("total")} {group.pupils.length} {t("pupils")}
                </p>
                <p
                    className={`select-all ${props.selectAll ? "select-all_active" : null}`}
                    onClick={props.selectAllHandler}
                >
                    {t("select_all")}
                </p>
                <div className="list">
                    {filteredPupils.map((pupil) => {
                        return (
                            <div key={pupil._id} className="list__checkbox">
                                <input
                                    id={pupil._id}
                                    type="checkbox"
                                    name={pupil._id}
                                    checked={props.pupils.includes(pupil._id)}
                                    onChange={props.checkboxHandler}
                                    value={pupil._id}
                                />
                                <label htmlFor={pupil._id}>
                                    <div>
                                        <p>
                                            {pupil.surname} {pupil.name}
                                        </p>
                                    </div>
                                </label>
                            </div>
                        );
                    })}
                </div>
                {/* Временно отключена кнопка "Показывать результат ученикам" */}
                {/*<div className="bool-field">*/}
                {/*    <p>{t("show_results_to_pupils")}</p>*/}
                {/*    <div*/}
                {/*        onClick={props.visibleHandler}*/}
                {/*        className={`swipe ${props.isVisible && "swipe_active"}`}*/}
                {/*    />*/}
                {/*</div>*/}
                <div className="send-btn">
                    <button onClick={onSendClick} className="main-btn">
                        {t("send_button")}
                    </button>
                </div>
            </div>

            {isSureAlert && (
                <SureAlert
                    type="createTest"
                    handleClose={onCloseClick}
                    handleSubmit={() => {
                        props.clickHandler();
                        onCloseClick();
                    }}
                />
            )}
        </Layout>
    );
};
