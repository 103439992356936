import { useQuery } from "@tanstack/react-query";

import { fetchAllPupils } from "api/pupils/fetchAllPupils";

export function useFetchAllPupils() {
    const { data, error, isLoading } = useQuery({
        queryKey: ["pupils/all"],
        queryFn: fetchAllPupils,
    });

    return {
        allPupils: data,
        allPupilsError: error,
        allPupilsIsLoading: isLoading,
    };
}
