import bookletForParentsFutureFirstGraders from "static/docs/guidelines/prevention/Буклет_для_родителей_будущих_первоклассников.pdf";
import hotlinesForParents from "static/docs/guidelines/prevention/Горячие_линии_для_родителей.pdf";
import destructiveBehaviorTeachers from "static/docs/guidelines/prevention/Деструктивное_поведение_Алгоритм_действий_для_педагогов.pdf";
import destructiveBehaviorParents from "static/docs/guidelines/prevention/Деструктивное_поведение_Алгоритм_действий_для_родителей.pdf";
import childHelpline from "static/docs/guidelines/prevention/Детский_телефон_доверия.pdf";
import morningKindWords from "static/docs/guidelines/prevention/Картотека_Добрые_слова_с_утра.pdf";
import interviewGuideForChildren from "static/docs/guidelines/prevention/Памятка_для_детей_Собеседования.pdf";
import psychologicalPreparationForParents from "static/docs/guidelines/prevention/Памятка_для_родителей_Как_психологически_подготовить_ребенка_к_учебе.pdf";
import dailyRoutineForParents from "static/docs/guidelines/prevention/Памятка_для_родителей_Режим_дня_будущего_первоклассника.pdf";
import developmentLevelForParents from "static/docs/guidelines/prevention/Памятка_для_родителей_Уровень_развития_психических_процессов_у_первоклассника.pdf";
import zprMemo from "static/docs/guidelines/prevention/Памятка_ЗПР.pdf";
import responseToChildProvocation from "static/docs/guidelines/prevention/Памятка_Как_реагировать_на_провокации_детей.pdf";
import dealingWithBullying from "static/docs/guidelines/prevention/Памятка_Как_родителям_справиться_с_буллингом.doc";
import visionDisordersMemo from "static/docs/guidelines/prevention/Памятка_Нарушение_зрения.pdf";
import hearingDisordersMemo from "static/docs/guidelines/prevention/Памятка_Нарушения_слуха.pdf";
import nodaMemo from "static/docs/guidelines/prevention/Памятка_НОДА.pdf";
import preventionMemo from "static/docs/guidelines/prevention/Памятка_по_выявлению_и_предупреждению.pdf";
import rasMemo from "static/docs/guidelines/prevention/Памятка_РАС.pdf";
import stressCopingMemo from "static/docs/guidelines/prevention/Памятка_справиться_со_стрессом.pdf";
import selfHelpTechniquesMemo from "static/docs/guidelines/prevention/Памятка_Техники_самопомощи.pdf";
import tmnrAndUoMemo from "static/docs/guidelines/prevention/Памятка_ТМНР_и_УО.pdf";
import tnrMemo from "static/docs/guidelines/prevention/Памятка_ТНР.pdf";
import difficultiesForFirstGrader from "static/docs/guidelines/prevention/Памятка_Трудности_с_которыми_может_столкнуться_первоклассник.pdf";
import posterForParentsBeforeSchool from "static/docs/guidelines/prevention/Плакат_для_родителей_Перед_школой.pdf";
import schoolShootingPrevention from "static/docs/guidelines/prevention/Предотвращение_деструктивных_проявлений_в_форме_скулшутинга_в_школе.pdf";
import childVictimOfBullying from "static/docs/guidelines/prevention/Ребенок_жертва_буллинга.docx";
import schoolBullying from "static/docs/guidelines/prevention/Травля_в_школе.pdf";
import checklistForParentsBeforeSchool from "static/docs/guidelines/prevention/Чек_лист_для_родителей_До школы.pdf";

export const preventionReminders = {
    files: [
        {
            title: "Буклет для родителей будущих первоклассников",
            file: bookletForParentsFutureFirstGraders,
            fileName: "Буклет_для_родителей_будущих_первоклассников.pdf",
        },
        {
            title: "Горячие линии для родителей",
            file: hotlinesForParents,
            fileName: "Горячие_линии_для_родителей.pdf",
        },
        {
            title: "Алгоритм действий для педагогов при деструктивном поведении",
            file: destructiveBehaviorTeachers,
            fileName: "Деструктивное_поведение_Алгоритм_действий_для_педагогов.pdf",
        },
        {
            title: "Алгоритм действий для родителей при деструктивном поведении",
            file: destructiveBehaviorParents,
            fileName: "Деструктивное_поведение_Алгоритм_действий_для_родителей.pdf",
        },
        {
            title: "Детский телефон доверия",
            file: childHelpline,
            fileName: "Детский_телефон_доверия.pdf",
        },
        {
            title: "Добрые слова с утра",
            file: morningKindWords,
            fileName: "Картотека_Добрые_слова_с_утра.pdf",
        },
        {
            title: "Памятка для детей: Собеседования",
            file: interviewGuideForChildren,
            fileName: "Памятка_для_детей_Собеседования.pdf",
        },
        {
            title: "Памятка для родителей: Как психологически подготовить ребенка к учебе",
            file: psychologicalPreparationForParents,
            fileName: "Памятка_для_родителей_Как_психологически_подготовить_ребенка_к_учебе.pdf",
        },
        {
            title: "Памятка для родителей: Режим дня будущего первоклассника",
            file: dailyRoutineForParents,
            fileName: "Памятка_для_родителей_Режим_дня_будущего_первоклассника.pdf",
        },
        {
            title: "Памятка для родителей: Уровень развития психических процессов у первоклассника",
            file: developmentLevelForParents,
            fileName:
                "Памятка_для_родителей_Уровень_развития_психических_процессов_у_первоклассника.pdf",
        },
        {
            title: "Памятка ЗПР",
            file: zprMemo,
            fileName: "Памятка_ЗПР.pdf",
        },
        {
            title: "Как реагировать на провокации детей",
            file: responseToChildProvocation,
            fileName: "Памятка_Как_реагировать_на_провокации_детей.pdf",
        },
        {
            title: "Как родителям справиться с буллингом",
            file: dealingWithBullying,
            fileName: "Памятка_Как_родителям_справиться_с_буллингом.doc",
        },
        {
            title: "Памятка: Нарушение зрения",
            file: visionDisordersMemo,
            fileName: "Памятка_Нарушение_зрения.pdf",
        },
        {
            title: "Памятка: Нарушения слуха",
            file: hearingDisordersMemo,
            fileName: "Памятка_Нарушения_слуха.pdf",
        },
        {
            title: "Памятка НОДА",
            file: nodaMemo,
            fileName: "Памятка_НОДА.pdf",
        },
        {
            title: "Памятка по выявлению и предупреждению",
            file: preventionMemo,
            fileName: "Памятка_по_выявлению_и_предупреждению.pdf",
        },
        {
            title: "Памятка РАС",
            file: rasMemo,
            fileName: "Памятка_РАС.pdf",
        },
        {
            title: "Памятка: Как справиться со стрессом",
            file: stressCopingMemo,
            fileName: "Памятка_справиться_со_стрессом.pdf",
        },
        {
            title: "Памятка: Техники самопомощи",
            file: selfHelpTechniquesMemo,
            fileName: "Памятка_Техники_самопомощи.pdf",
        },
        {
            title: "Памятка ТМНР и УО",
            file: tmnrAndUoMemo,
            fileName: "Памятка_ТМНР_и_УО.pdf",
        },
        {
            title: "Памятка ТНР",
            file: tnrMemo,
            fileName: "Памятка_ТНР.pdf",
        },
        {
            title: "Памятка: Трудности, с которыми может столкнуться первоклассник",
            file: difficultiesForFirstGrader,
            fileName: "Памятка_Трудности_с_которыми_может_столкнуться_первоклассник.pdf",
        },
        {
            title: "Плакат для родителей перед школой",
            file: posterForParentsBeforeSchool,
            fileName: "Плакат_для_родителей_Перед_школой.pdf",
        },
        {
            title: "Предотвращение деструктивных проявлений в форме скулшутинга в школе",
            file: schoolShootingPrevention,
            fileName: "Предотвращение_деструктивных_проявлений_в_форме_скулшутинга_в_школе.pdf",
        },
        {
            title: "Ребенок жертва буллинга",
            file: childVictimOfBullying,
            fileName: "Ребенок_жертва_буллинга.docx",
        },
        {
            title: "Травля в школе",
            file: schoolBullying,
            fileName: "Травля_в_школе.pdf",
        },
        {
            title: "Чек-лист для родителей: До школы",
            file: checklistForParentsBeforeSchool,
            fileName: "Чек_лист_для_родителей_До школы.pdf",
        },
    ],
};
