import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Layout } from "../Layout";
import ic_info from "../../static/img/ic-info-black.svg";

export const DocPage = ({ handler, doc, test }) => {
    const { t } = useTranslation("tests");
    const [document, setDocument] = useState(null);

    useEffect(() => {
        const parser = new DOMParser();
        const html = doc.body.innerHTML;
        if (html !== "") {
            const document = parser.parseFromString(html, "text/html");
            document.querySelector("body p:last-child").remove();
            var ns = new XMLSerializer();
            var ss = ns.serializeToString(document);
            setDocument(ss);
        }
    }, [doc]);

    return (
        <Layout>
            <div className="page">
                <header className="page__header">
                    <button
                        onClick={handler}
                        className="icon-btn page__icon-btn page__icon-btn_left icon-btn_close"
                    />
                    <p className="page__title">{t("test_info")}</p>
                </header>
                <div className="solution-results">
                    <div className="solution-results__wrapper">
                        <p className="solution-results__name">{test.condition.name}</p>
                        <p
                            className="solution-results__desc"
                            dangerouslySetInnerHTML={{ __html: test.condition.desTeacher }}
                        />
                        <div className="solution-results__scales">
                            {test
                                ? test.solution.length > 0
                                    ? test.solution.map((item) => {
                                          return (
                                              <div className="solution-results__scale">
                                                  <label>01</label>
                                                  <h3>
                                                      {item.name} <img alt="" src={ic_info} />
                                                  </h3>
                                                  <h5>
                                                      {t("has_scale_from")}{" "}
                                                      {item.levels ? item.levels[0].from : ""}{" "}
                                                      {t("scale_to")}{" "}
                                                      {item.levels
                                                          ? item.levels[item.levels.length - 1].to
                                                          : ""}{" "}
                                                      {t("conditional_scores")}
                                                  </h5>
                                                  <div className="scale-desc__levels">
                                                      {item.levels
                                                          ? item.levels.map((level, id1) => {
                                                                return (
                                                                    <div className="scale-desc__level">
                                                                        <p>
                                                                            {t("scale_from")}{" "}
                                                                            {level.from}{" "}
                                                                            {t("scale_to")}{" "}
                                                                            {level.to}
                                                                        </p>
                                                                        <p>
                                                                            <span>
                                                                                {t("scale_level")}{" "}
                                                                                {id1 + 1}
                                                                            </span>
                                                                        </p>
                                                                    </div>
                                                                );
                                                            })
                                                          : ""}
                                                  </div>
                                              </div>
                                          );
                                      })
                                    : ""
                                : ""}
                        </div>
                    </div>
                </div>
                <Link
                    style={{ bottom: "100px" }}
                    to={`/tests/${test._id}/${test.condition.body.conditionId}?type=view`}
                    className="main-btn"
                >
                    {t("view_questions")}
                </Link>
                <div dangerouslySetInnerHTML={{ __html: document }} />
            </div>
        </Layout>
    );
};
