import React from "react";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";

export const RiskPupilsList = ({pupilsList}) => {
    const history = useHistory();
    const { schools } = useSelector((state) => state.school);
    const { t } = useTranslation();


    const clickHandler = (pupilId) => {
        if (window.location.href.indexOf("/meetings/classes/") !== -1) {
            history.push(`/pupils/${pupilId}/meetings/create`);
        } else {
            history.push(`/pupils/${pupilId}`);
        }
    };

    return (
        <div className="list gesturedZone risk-groups__list">
            {pupilsList?.map((pupil) => {
                const school = schools.find(({_id}) => _id === pupil.class.school);

                return (
                    <div
                        key={pupil._id}
                        onClick={() => {
                            clickHandler(pupil._id);
                        }}
                        className="risk-groups__list-item"
                    >
                        <div>
                            <span className="risk-groups__tag">
                                {t("risk_groups")} {pupil.categories?.length}
                            </span>
                            <p>{pupil.name} {pupil.surname}</p>
                        </div>

                        <p className="risk-groups__school">
                            {school && <span>{school.name},</span>}{" "}
                            <span>{pupil.class.number} {pupil.class.letter}</span>
                        </p>
                    </div>
                );
            })}
        </div>
    )
}