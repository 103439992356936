import { useTimer } from "react-timer-hook";

export const PsychPhoneVerificationTimer = ({ hideTimer, secondsNum = 10 }) => {
    const date = new Date();
    const { seconds } = useTimer({
        expiryTimestamp: date.setSeconds(date.getSeconds() + secondsNum),
        onExpire: () => hideTimer(),
    });

    return <div className="phone-verification__timer">{seconds}</div>;
};
