import React from "react";
import { useCreateReport } from "hooks/useCreateReport";

export const CreateReportButton = ({ url, fileName, text, loadingText }) => {
    const { createReport, isReportLoading } = useCreateReport();

    const createReportHandler = () => {
        createReport(url, fileName);
    };

    return (
        <span
            onClick={createReportHandler}
            className="solution-results__doc-btn solution-results__report-btn"
            style={{ marginBottom: 10 }}
        >
            {isReportLoading ? <>{loadingText}</> : <>{text}</>}
        </span>
    );
};
