import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { fetchAllMunicipalities } from "api/municipalities/fetchAllMunicipalities";
import {useQuery} from "@tanstack/react-query";

export const RegisterPsychMunicipalitySelect = ({ setMunicipality }) => {
    const { t } = useTranslation();
    const [input, setInput] = useState("");

    const { data } = useQuery({
        queryKey: ["municipality/all"],
        queryFn: fetchAllMunicipalities,
    });

    const changeInputHandler = (e) => {
        setInput(e.target.value);
    };

    return (
        <>
            <div className="auth-page__register-header">
                <h3>{t("choose_region")}</h3>
            </div>
            <div className="region-content">
                <div className="region-find">
                    <input
                        readOnly
                        type="text"
                        value={input}
                        onChange={changeInputHandler}
                        placeholder="Выберите из списка"
                        autoFocus
                    />
                </div>
                <div>
                    {data?.map((municipality, index) => (
                        <button
                            className="region-select-btn"
                            onClick={() => {
                                setMunicipality(municipality);
                            }}
                            key={index}
                        >
                            {municipality.name}
                        </button>
                    ))}
                </div>
            </div>
        </>
    );
};
