import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { getHistories } from "redux/actions/setHistoryActions";
import { History } from "./History";

export const Histories = () => {
    const dispatch = useDispatch();
    const { loading } = useSelector((state) => state.app);
    const { histories } = useSelector((state) => state.history);
    const { t } = useTranslation("profile");

    useEffect(() => {
        dispatch(getHistories());
    }, [dispatch]);

    if (loading) return null;

    return (
        <div className="histories">
            <div className="histories__wrapper">
                <p className="histories__title">{t("history_title")}</p>
                {histories.length === 0 && (
                    <p className="histories__plug">{t("history_description")}</p>
                )}
                {histories.map((history) => (
                    <History history={history} key={history._id} />
                ))}
            </div>
        </div>
    );
};
