import React, { useState, useEffect, useCallback } from "react";
import { useParams, Redirect, Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import moment from "moment";
import "moment/locale/ru";
import { useDispatch, useSelector } from "react-redux";
import { FaRegTrashAlt } from "react-icons/fa";
import { BiUser } from "react-icons/bi";
import TextareaAutosize from "react-textarea-autosize";
import Select from "react-select";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

import { changeMeetingStatus } from "redux/actions/setMeetingActions";
import { CONFIRM_MEETING } from "redux/types";
import { BackButton } from "components/BackButton";
import { Layout } from "components/Layout";
import { SureAlert } from "components/SureAlert";
import { selectMeetingTagStyles } from "components/meetings/react-select";

/**
 * @api {get} /pupils/:pupilId/meetings/create создание встречи для ученика
 * @apiGroup meetings
 * @apiName создание встречи для ученика
 *
 * @apiParam {String} pupilId ID ученика
 *
 * @apiDescription <p>Страница создания встречи для ученика</p>
 * <p>Компонент <code>MeetingForm.js</code> <a href="../client/src/pages/meetings/MeetingForm.js">ссылка на файл</a></p>
 */

/**
 * @api {get} /pupils/:pupilId/meetings/:meetingId редактирование встречи для ученика
 * @apiGroup meetings
 * @apiName редактирование встречи для ученика
 *
 * @apiParam {String} pupilId ID ученика
 * @apiParam {String} meetingId ID встречи
 *
 * @apiDescription <p>Страница редактирования встречи для ученика</p>
 * <p>Компонент <code>MeetingForm.js</code> <a href="../client/src/pages/meetings/MeetingForm.js">ссылка на файл</a></p>
 */

/**
 * @api {get} /meetings/:meetingId редактирование встречи по id
 * @apiGroup meetings
 * @apiName редактирование встречи по id
 *
 * @apiParam {String} meetingId ID встречи
 *
 * @apiDescription <p>Страница редактирования встречи по id</p>
 * <p>Компонент <code>MeetingForm.js</code> <a href="../client/src/pages/meetings/MeetingForm.js">ссылка на файл</a></p>
 */

const initialState = {
    name: "",
    date: "",
    description: "",
    tag: "",
};

const tagsOptions = [
    { value: "Диагностика", label: "Диагностика" },
    { value: "Консультация", label: "Консультация" },
    { value: "Просвещение", label: "Просвещение" },
    { value: "Коррекция", label: "Коррекция" },
    { value: "Профилактика", label: "Профилактика" },
];

export const MeetingForm = () => {
    const dispatch = useDispatch();
    const navigate = useHistory();
    const { t } = useTranslation("meetings");
    const { pupilId, meetingId } = useParams();
    const [redirect, setRedirect] = useState(false);
    const [form, setForm] = useState(initialState);
    const { meeting } = useSelector((state) => state.meeting);
    const { role } = useSelector((state) => state.auth);
    const [isSureAlert, setIsSureAlert] = useState(false);
    const [isShowCancelMeetingAlert, setIsShowCancelMeetingAlert] = useState(false);
    const [isShowDeleteMeetingAlert, setIsShowDeleteMeetingAlert] = useState(false);
    const [isShowCompleteMeetingAlert, setIsShowCompleteMeetingAlert] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);

    function onSendClick() {
        setIsSureAlert(true);
    }

    function onCloseClick() {
        setIsSureAlert(false);
    }

    useEffect(() => {
        if (!meetingId) {
            setIsDisabled(false);
        } else if (role === "pupil" || meeting?.status === "completed") {
            setIsDisabled(true);
        } else {
            setIsDisabled(false);
        }
    }, [role, meeting, meetingId]);

    useEffect(() => {
        if (meetingId) {
            (async () => {
                try {
                    const { data } = await axios.get(`meetings/${meetingId}`);
                    const { name, description, date, tag } = data;

                    dispatch({ type: CONFIRM_MEETING, payload: data });
                    setForm({
                        ...initialState,
                        name,
                        description,
                        tag,
                        date: moment(date).utc().format("DD.MM.YYYY HH:mm"),
                    });
                } catch (err) {}
            })();
        }
    }, [meetingId, dispatch]);

    const changeHandler = (event) => {
        const { name, value } = event.target;

        setForm({ ...form, [name]: value });
    };

    const changeDateHandler = (value) => {
        if (typeof value !== "string") {
            const now = moment();
            let date = moment(value).format("DD.MM.YYYY HH:mm");

            if (value < now) {
                const round = 5 - (now.minute() % 5);
                date = now.add(round, "minutes").format("DD.MM.YYYY HH:mm");
            }

            setForm({ ...form, date });
        }
    };

    const selectTagHandler = (e) => {
        if (e.value) {
            setForm({ ...form, tag: e.value });
        }
    };

    const isValidDate = useCallback((current) => current.isAfter(moment().subtract(1, "day")), []);

    const submitHandler = async (e) => {
        e.preventDefault();
        const date = moment(form.date, "DD.MM.YYYY HH:mm").format("YYYY-MM-DDTkk:mm");
        const data = { ...form, date };

        if (meetingId) {
            await axios.put(`meetings/${meetingId}`, data);
        } else {
            await axios.post("meetings", { ...data, pupilId });
        }

        setRedirect(true);
    };

    const deleteMeetingHandler = async () => {
        setIsShowDeleteMeetingAlert(false);

        await axios.delete(`meetings/${meetingId}`);

        setRedirect(true);
    };

    const cancelMeetingHandler = () => {
        setIsShowCancelMeetingAlert(false);
        dispatch(changeMeetingStatus(meetingId, "canceled"));
    };

    const completeMeetingHandler = () => {
        setIsShowCompleteMeetingAlert(false);
        dispatch(changeMeetingStatus(meetingId, "completed"));
    };

    const confirmMeetingHandler = () => {
        dispatch(changeMeetingStatus(meetingId, "read"));
    };

    if (redirect) {
        return <Redirect to={pupilId ? `/pupils/${pupilId}/meetings` : "/meetings"} />;
    }

    return (
        <Layout>
            <div className="page">
                <header className="page__header">
                    <BackButton />
                    <p className="page__title">{t("meetings")}</p>
                    {role === "psych" && meeting?.pupil && (
                        <Link
                            to={`/pupils/${meeting.pupil._id}`}
                            style={{ padding: "8px" }}
                            className="icon-btn page__icon-btn page__icon-btn_right"
                        >
                            <BiUser />
                        </Link>
                    )}
                </header>

                <div className="school-page__wrapper">
                    <h3>{t("meeting_details")}</h3>
                    <div className="input-field input-field_purple">
                        <TextareaAutosize
                            className="textarea-autosize"
                            placeholder={t("meeting_topic")}
                            id="name"
                            name="name"
                            onChange={changeHandler}
                            value={form.name}
                            disabled={isDisabled}
                        />
                        <label htmlFor="name">{t("meeting_topic")}</label>
                    </div>
                    <div className="input-field input-field_purple">
                        <TextareaAutosize
                            className="textarea-autosize"
                            placeholder={t("meeting_description")}
                            id="description"
                            name="description"
                            onChange={changeHandler}
                            value={form.description}
                            disabled={isDisabled}
                        />
                        <label htmlFor="description">{t("meeting_description")}</label>
                    </div>

                    <div className="input-field input-field_purple">
                        <Datetime
                            value={form.date}
                            onChange={changeDateHandler}
                            timeConstraints={{ minutes: { step: 5 } }}
                            inputProps={{
                                placeholder: t("meeting_date"),
                                readOnly: true,
                                disabled: isDisabled,
                            }}
                            isValidDate={isValidDate}
                        />
                    </div>

                    {role === "psych" && (
                        <div className="input-field input-field_purple">
                            <label className="input-field__focused-label">{t("meeting_tag")}</label>
                            <Select
                                value={{
                                    value: form.tag || t("select_meeting_tag"),
                                    label: form.tag || t("select_meeting_tag"),
                                }}
                                onChange={selectTagHandler}
                                options={tagsOptions}
                                placeholder={t("meeting_tag")}
                                styles={selectMeetingTagStyles}
                                isSearchable={false}
                                components={
                                    isDisabled
                                        ? {
                                              IndicatorSeparator: () => null,
                                              DropdownIndicator: () => null,
                                          }
                                        : {
                                              IndicatorSeparator: () => null,
                                          }
                                }
                                isDisabled={isDisabled}
                            />

                            <div style={{ marginTop: 40 }}>
                                <button
                                    onClick={() =>
                                        navigate.push(`/advices?pupilId=${meeting.pupil._id}`)
                                    }
                                    className="main-btn main-btn_green"
                                >
                                    {t("add_recommendation")}
                                </button>
                                <button
                                    onClick={() =>
                                        navigate.push(`/notes?pupilId=${meeting.pupil._id}`)
                                    }
                                    className="main-btn main-btn_orange"
                                    style={{ marginTop: 15 }}
                                >
                                    {t("add_note")}
                                </button>

                                <hr
                                    style={{
                                        margin: "40px 0",
                                        height: 1,
                                        backgroundColor: "#E0E0E0",
                                    }}
                                />
                            </div>
                        </div>
                    )}
                </div>

                {/*Кнопки психолога*/}

                {role === "psych" && (
                    <>
                        {meeting?.status && meeting.status !== "completed" && (
                            <>
                                {meetingId && (
                                    <>
                                        <button
                                            onClick={() => setIsShowCompleteMeetingAlert(true)}
                                            className="main-btn main-btn_green"
                                            style={{ marginBottom: 15 }}
                                        >
                                            {t("meeting_completed")}
                                        </button>
                                        <button
                                            onClick={onSendClick}
                                            disabled={!form.name || !form.date || !form.tag}
                                            className="main-btn main-btn_purple"
                                        >
                                            {t("change")}
                                        </button>

                                        {meeting?.status && meeting.status !== "canceled" && (
                                            <button
                                                onClick={() => setIsShowCancelMeetingAlert(true)}
                                                className="main-btn main-btn_orange"
                                                style={{ margin: "15px 0 24px" }}
                                            >
                                                {t("cancel")}
                                            </button>
                                        )}

                                        <button
                                            style={{
                                                color: "fff",
                                                width: "58px",
                                                height: "58px",
                                                margin: "20px auto",
                                                backgroundColor: "#FE2B2B",
                                                borderRadius: "50%",
                                                boxShadow: "0px 2px 12px rgb(0 110 255 / 40%)",
                                            }}
                                            onClick={() => setIsShowDeleteMeetingAlert(true)}
                                        >
                                            <FaRegTrashAlt color="fff" />
                                        </button>
                                    </>
                                )}
                            </>
                        )}

                        {!meetingId && (
                            <button
                                onClick={onSendClick}
                                disabled={!form.name || !form.date || !form.tag}
                                className="main-btn main-btn_purple"
                            >
                                {t("assign")}
                            </button>
                        )}
                    </>
                )}

                {/*Кнопки ученика*/}

                {role === "pupil" && (
                    <>
                        {meeting?.status === "unread" && (
                            <button
                                onClick={confirmMeetingHandler}
                                className="main-btn main-btn_purple"
                            >
                                {t("confirm")}
                            </button>
                        )}

                        {meeting?.status && !["canceled", "completed"].includes(meeting.status) && (
                            <button
                                onClick={() => setIsShowCancelMeetingAlert(true)}
                                className="main-btn main-btn_orange"
                                style={{ margin: "15px 0 24px" }}
                            >
                                {t("cancel")}
                            </button>
                        )}
                    </>
                )}
            </div>

            {isSureAlert && (
                <SureAlert
                    type="createMeeting"
                    handleClose={onCloseClick}
                    handleSubmit={(e) => {
                        submitHandler(e);
                        onCloseClick();
                    }}
                />
            )}

            {isShowCancelMeetingAlert && (
                <SureAlert
                    type="cancelMeeting"
                    submitText={t("cancel")}
                    handleSubmit={cancelMeetingHandler}
                    handleClose={() => setIsShowCancelMeetingAlert(false)}
                />
            )}

            {isShowDeleteMeetingAlert && (
                <SureAlert
                    text={t("meeting_delete_text")}
                    submitText={t("meeting_delete_btn")}
                    handleClose={() => setIsShowDeleteMeetingAlert(false)}
                    handleSubmit={deleteMeetingHandler}
                />
            )}

            {isShowCompleteMeetingAlert && (
                <SureAlert
                    text={t("meeting_complete_text")}
                    submitText={t("confirm")}
                    handleClose={() => setIsShowCompleteMeetingAlert(false)}
                    handleSubmit={completeMeetingHandler}
                />
            )}
        </Layout>
    );
};
