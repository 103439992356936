import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { createAlarm, getLastAlarm } from "redux/actions/setAlarmActions";
import { getTestsBiPupilId } from "redux/actions/setTestActions";
import { getSchools } from "redux/actions/setSchoolActions";
import { getAdvices } from "redux/actions/setAdviceActions";
import { getMeetings } from "redux/actions/setMeetingActions";
import { Loader } from "components/Loader";
import { SureAlert } from "components/SureAlert";

export const PupilProfile = ({ user, loading }) => {
    const dispatch = useDispatch();
    const { notPassedTests } = useSelector((state) => state.test);
    const { lastAlarm } = useSelector((state) => state.alarm);
    const { meetings } = useSelector((state) => state.meeting);
    const { advices } = useSelector((state) => state.advice);
    const [isSureAlert, setIsSureAlert] = useState(false);
    const { t } = useTranslation("profile");

    useEffect(() => {
        dispatch(getAdvices());
        dispatch(getTestsBiPupilId());
        dispatch(getLastAlarm());
        dispatch(getSchools());
        dispatch(getMeetings(user._id));
    }, [dispatch, user]);

    const alarmHandler = useCallback(() => {
        setIsSureAlert(false);
        dispatch(createAlarm({ psychId: user.psych }));
    }, [dispatch, user.psych]);

    const sureAlertPopupClose = useCallback(() => {
        setIsSureAlert(false);
    }, []);

    const setIsSureAlertHandler = () => {
        if (!loading && (!lastAlarm || lastAlarm.readAt)) {
            setIsSureAlert(true);
        }
    };

    return (
        <div className="profile-page__entities">
            {user.isArchive ? (
                <Link className="profile-page__qr-code" to="/pupil/qr">
                    <p>{t("scan_qr_code_1")}</p>
                    <p>{t("scan_qr_code_2")}</p>
                </Link>
            ) : (
                <>
                    {user.psych && (
                        <div
                            onClick={setIsSureAlertHandler}
                            className={`profile-page__alarm 
                        ${lastAlarm && !lastAlarm.readAt && "profile-page__alarm_disabled"}`}
                        >
                            <p>{t("want_to_talk")}</p>
                            <p>
                                {lastAlarm && !lastAlarm.readAt
                                    ? t("request_already_made")
                                    : t("send_request_to_psychologist")}
                            </p>
                        </div>
                    )}

                    <a href="tel:+78005004414">
                        <div className="profile-page__helpline">
                            <p>{t("call_helpline")}</p>
                            <p>{t("helpline_phone")}</p>
                        </div>
                    </a>
                </>
            )}

            {loading ? (
                <Loader style={`margin-top: calc(100%/2 - 5rem);`} />
            ) : (
                <div className="profile-page__entities-wrapper">
                    <Link to="/tests" className={`entity ${!!notPassedTests && "entity_test"}`}>
                        <p className={`entity__title ${!!notPassedTests && "entity__title_white"}`}>
                            {t("tests")}
                        </p>
                        {!notPassedTests && (
                            <p className="entity__description">{t("no_tests_yet")}</p>
                        )}
                        <span
                            className={`entity__extra ${
                                !notPassedTests && "entity__extra_pupil-null"
                            }`}
                        >
                            {notPassedTests}
                        </span>
                    </Link>
                    <Link
                        to={`/pupils/${user._id}/meetings`}
                        className={`entity ${meetings.length && "entity_meeting"}`}
                    >
                        <p className={`entity__title ${meetings.length && "entity__title_white"}`}>
                            {t("meetings")}
                        </p>
                        <p
                            className={`entity__description  ${
                                meetings.length && "entity__description_white"
                            }`}
                        >
                            {t("individually_for_you")}
                        </p>
                        <span
                            className={`entity__extra ${
                                !meetings.length && "entity__extra_pupil-null"
                            }`}
                        >
                            {meetings.length}
                        </span>
                    </Link>
                    <Link to="/advices" className={`entity ${advices.length && "entity_advice"}`}>
                        <p className={`entity__title ${advices.length && "entity__title_white"}`}>
                            {t("recommendations")}
                        </p>
                        <p
                            className={`entity__description  ${
                                advices.length && "entity__description_white"
                            }`}
                        >
                            {t("individually_for_you_from_psych")}
                        </p>
                        <span
                            className={`entity__extra ${
                                !advices.length && "entity__extra_pupil-null"
                            }`}
                        >
                            {advices.length}
                        </span>
                    </Link>
                    <Link to="/instruction" className="entity entity_instruction">
                        <p className="entity__title entity__title_white">{t("instruction")}</p>
                    </Link>
                </div>
            )}

            {isSureAlert && (
                <SureAlert
                    type="wantTalk"
                    handleClose={sureAlertPopupClose}
                    handleSubmit={alarmHandler}
                    submitText={t("send_request_button")}
                />
            )}
        </div>
    );
};
