import React from "react";

import { classNames } from "helpers/classNames";
import { declinations } from "helpers/declinations";
import { Range } from "components/Range";
import styles from "./StatisticClassesSelect.module.scss";

export const StatisticClassesSelect = ({
    classes,
    t,
    i18n,
    chosenClasses,
    setChosenClasses,
    setMultiClassStep,
}) => {
    const handleSetChosenClasses = (classId) => {
        if (chosenClasses.includes(classId)) {
            setChosenClasses(chosenClasses.filter((item) => item !== classId));
        } else {
            setChosenClasses([...chosenClasses, classId]);
        }
    };

    return (
        <>
            <div>
                <h4 className={styles.title}>{t("choose_classes_title")}</h4>

                <div className={styles.classList}>
                    {classes.map((item) => (
                        <button
                            onClick={() => handleSetChosenClasses(item._id)}
                            key={item._id}
                            className={classNames(["statistic-condition", styles.classItem], {
                                [styles.active]: chosenClasses.includes(item._id),
                            })}
                        >
                            <p className="statistic-condition__title">
                                {item.number} {item.letter.toUpperCase()}
                            </p>

                            <Range
                                total={item.total}
                                norm={item.positively}
                                notNorm={item.total - item.positively}
                            />

                            <div className="statistic-condition__footer">
                                <span className="statistic-condition__indicator green">
                                    {item.positively}
                                </span>
                                <span className="statistic-condition__indicator blue">
                                    {item.total - item.positively}
                                </span>
                                <p className="statistic-condition__members">
                                    {item.total} {declinations(item.total, "test", i18n.language)}
                                </p>
                            </div>
                        </button>
                    ))}
                </div>
            </div>

            <div className={styles.buttonWrapper}>
                <button
                    onClick={() => setMultiClassStep("conditions")}
                    disabled={chosenClasses.length === 0}
                    className="main-btn main-btn_green"
                >
                    <span>{t("choose_methods_btn")}</span>
                </button>
            </div>
        </>
    );
};
