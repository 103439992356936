import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import axios from "axios";

import { hideNotice, showNotice } from "redux/actions/setAppActions";
import { BackButton } from "components/BackButton";
import { Layout } from "components/Layout";
import { Notice } from "components/Notice";
import { SureAlert } from "components/SureAlert";

/**
 * @api {get} /classes/:id/pupils/transfer страница переноса учеников класса
 * @apiGroup schools and classes
 * @apiName страница переноса учеников класса
 *
 * @apiParam {String} id ID класса
 *
 * @apiDescription <p>Страница переноса учеников класса</p>
 * <p>Компонент <code>PupilsTransfer.js</code> <a href="../client/src/pages/PupilsTransfer.js">ссылка на файл</a></p>
 */

export const PupilsTransfer = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation("pupils");
    const classId = useParams().id;
    const { notice } = useSelector((state) => state.app);
    const [pupils, setPupils] = useState([]);
    const [classData, setClassData] = useState(null);
    const [selectedPupils, setSelectedPupils] = useState([]);
    const [isSureAlert, setIsSureAlert] = useState(false);

    useEffect(() => {
        if (classId) {
            (async () => {
                const { data } = await axios.get(`classes/pupils/${classId}`);

                setPupils(data.pupils);
                setClassData(data.class);
            })();
        }
    }, [classId]);

    const hideNoticeHandler = useCallback(() => {
        dispatch(hideNotice());
    }, [dispatch]);

    const showSureAlertHandler = () => {
        setIsSureAlert(true);
    };

    const closeSureAlertHandler = () => {
        setIsSureAlert(false);
    };

    const moveToArchiveHandler = async () => {
        setIsSureAlert(false);

        if (selectedPupils.length) {
            try {
                const { data } = await axios.put(`pupil/archive`, {
                    pupils: selectedPupils,
                    classId,
                });

                setPupils(data.pupils);
            } catch (err) {
                const message = err.response?.data?.message || t("something_went_wrong");
                dispatch(showNotice(message));
            }
        }
    };

    const selectHandler = (e) => {
        const id = e.target.id;
        let updatedSelectedPupils = [];

        if (id === "select-all") {
            if (selectedPupils.length !== pupils.length) {
                updatedSelectedPupils = pupils.map(({ _id }) => _id);
            }
        } else {
            updatedSelectedPupils = selectedPupils.includes(id)
                ? selectedPupils.filter((item) => item !== id)
                : [...selectedPupils, id];
        }

        setSelectedPupils(updatedSelectedPupils);
    };

    return (
        <Layout>
            <div className="page">
                <header className="page__header">
                    <BackButton />
                    <p className="page__title">
                        {classData?.number}
                        {classData?.letter}
                    </p>
                </header>

                {!!notice && <Notice text={notice.text} closeHandler={hideNoticeHandler} />}
                {isSureAlert && (
                    <SureAlert
                        type="moveToArchive"
                        submitText={t("move")}
                        handleClose={closeSureAlertHandler}
                        handleSubmit={moveToArchiveHandler}
                    />
                )}

                <div className="school-page__wrapper">
                    <p className="school-page__text">{t("to_archive_text")}</p>

                    <div className="list" style={{ marginTop: 16, padding: "0 16px" }}>
                        <div className="list__checkbox">
                            <input
                                id="select-all"
                                type="checkbox"
                                name="select-all"
                                checked={pupils.length === selectedPupils.length}
                                onChange={selectHandler}
                            />
                            <label htmlFor="select-all">
                                <div>
                                    <p>{t("select_all")}</p>
                                </div>
                            </label>
                        </div>

                        {pupils
                            .filter((p) => !p.isArchive)
                            .map(({ _id, surname, name }) => (
                                <div key={_id} className="list__checkbox">
                                    <input
                                        id={_id}
                                        type="checkbox"
                                        name={_id}
                                        checked={selectedPupils.includes(_id)}
                                        onChange={selectHandler}
                                        value={_id}
                                    />
                                    <label htmlFor={_id}>
                                        <div>
                                            <p>
                                                {surname} {name}
                                            </p>
                                        </div>
                                    </label>
                                </div>
                            ))}
                    </div>

                    <div className="send-btn" style={{ position: "static" }}>
                        <button
                            onClick={showSureAlertHandler}
                            className="main-btn"
                            disabled={!selectedPupils.length}
                        >
                            {t("move_to_archive")}
                        </button>
                    </div>
                </div>
            </div>
        </Layout>
    );
};
