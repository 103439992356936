import React from "react";
import TextareaAutosize from "react-textarea-autosize";
import { FaCheck } from "react-icons/fa";

import policy from "static/docs/policy.pdf";

export const LandingContactsStep4 = ({
    form,
    changeHandler,
    firstCheckboxChecked,
    firstCheckboxHandler,
    secondCheckboxChecked,
    secondCheckboxHandler,
    t,
}) => {
    return (
        <div className="landing-contacts__input-group">
            <div className="landing-contacts__input-field">
                <label htmlFor="message">{t("footer_form_request")}</label>
                <TextareaAutosize
                    className="textarea-autosize"
                    id="message"
                    name="message"
                    value={form.message}
                    placeholder={t("footer_form_request_placeholder")}
                    minRows={2}
                    maxRows={5}
                    onChange={changeHandler}
                />
            </div>
            <div className="landing-contacts__col">
                <div className="animatedCheckbox">
                    <div
                        style={
                            firstCheckboxChecked
                                ? {
                                      backgroundColor: "#2330BA",
                                  }
                                : {}
                        }
                        onClick={firstCheckboxHandler}
                        className="checkbox"
                    >
                        <FaCheck
                            style={{
                                filter: "brightness(0) invert(1)",
                            }}
                        />
                    </div>
                    <p>
                        {t("footer_form_terms_of_use_1")}{" "}
                        <a href={policy} download="download">
                            {t("footer_form_terms_of_use_2")}
                        </a>{" "}
                        {t("footer_form_terms_of_use_3")}
                    </p>
                </div>
                <div className="animatedCheckbox">
                    <div
                        style={
                            secondCheckboxChecked
                                ? {
                                      backgroundColor: "#2330BA",
                                  }
                                : {}
                        }
                        onClick={secondCheckboxHandler}
                        className="checkbox"
                    >
                        <FaCheck
                            style={{
                                filter: "brightness(0) invert(1)",
                            }}
                        />
                    </div>
                    <p>
                        {t("footer_form_terms_of_use_1")}{" "}
                        <a href={policy} download="download">
                            {t("footer_form_terms_of_use_2")}
                        </a>{" "}
                        {t("footer_form_terms_of_use_4")}
                    </p>
                </div>
            </div>
        </div>
    );
};
