import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useLocation } from "react-router-dom";

import { useFetchAllPupils } from "hooks/useQuery";
import { useDebounce } from "hooks/useDebounce";
import { Layout } from "components/Layout";
import { Loader } from "components/Loader";
import search from "static/img/ic-search.svg";
/**
 * @api {get} /pupils список всех учеников психолога
 * @apiGroup schools and classes
 * @apiName список всех учеников психолога
 *
 * @apiDescription <p>Страница со списком всех учеников психолога</p>
 * <p>Компонент <code>Pupils.js</code> <a href="../client/src/pages/pupils/PupilsPage.jsч">ссылка на файл</a></p>
 */

export const PupilsPageYanao = () => {
    const history = useHistory();
    const { search: searchParams } = useLocation();
    const { t } = useTranslation("pupils");
    const { allPupils, allPupilsIsLoading } = useFetchAllPupils();
    const [filteredPupils, setFilteredPupils] = useState([]);
    const [searchField, setSearchField] = useState("");
    const debouncedValue = useDebounce(searchField, 500);
    const [fromUrl, setFromUrl] = useState("");

    useEffect(() => {
        if (allPupils) {
            if (debouncedValue.trim()) {
                const regex = new RegExp(debouncedValue, "i");
                const filter = allPupils.filter(
                    ({ surname, name, patronymic, _id }) =>
                        regex.test(surname) || regex.test(name) || regex.test(patronymic) || regex.test(_id)
                );

                setFilteredPupils(filter);
            } else {
                setFilteredPupils(allPupils);
            }
        }
    }, [debouncedValue, allPupils]);

    useEffect(() => {
        const from = new URLSearchParams(searchParams).get("from");

        if (from) {
            setFromUrl(from);
        }
    }, [searchParams]);

    const backBtnHandler = () => {
        if (fromUrl) {
            // history.push(`${fromUrl}?from=/workWithMethods`);
            history.push("/schools");
        } else {
            history.back();
        }
    };

    return (
        <Layout>
            <div className="page">
                <header className="page__header">
                    <button
                        onClick={backBtnHandler}
                        className="icon-btn page__icon-btn page__icon-btn_left icon-btn_back"
                    />
                    <p className="page__title">{t("list_of_all_students")}</p>
                </header>

                <div className="school-page__wrapper">
                    {allPupilsIsLoading && <Loader />}

                    <div className="school-page__search" style={{ marginBottom: 20 }}>
                        <img src={search} alt="" />
                        <input
                            onChange={(e) => setSearchField(e.target.value)}
                            value={searchField}
                            placeholder="Поиск по ФИО или ID"
                            style={{ marginBottom: 0 }}
                        />
                    </div>

                    <div
                        className="list gesturedZone"
                        style={{
                            marginBottom: "3rem",
                        }}
                    >
                        {debouncedValue && !filteredPupils.length && (
                            <p style={{ textAlign: "center" }}>Ученики не найдены</p>
                        )}

                        {filteredPupils.map((pupil) => {
                            const classData = pupil.class;
                            const schoolName = classData?.school?.name;
                            const className = `${classData?.number} ${classData?.letter}`;
                            let link = `/pupils/${pupil._id}`;

                            if (fromUrl) {
                                link += `?from=pupils&to=${fromUrl}`;
                            }

                            return (
                                <Link to={link} key={pupil._id} className="list__item">
                                    <p>
                                        {pupil.surname} {pupil.name}
                                    </p>
                                    <p className="list__desc">
                                        {schoolName} • {className}
                                    </p>
                                </Link>
                            );
                        })}
                    </div>
                </div>
            </div>
        </Layout>
    );
};
