import axios from "axios";

/**
 * @param {string[]} classes - array of string
 */
export async function fetchPupilsWithConditionsByClasses(classes) {
    try {
        const response = await axios.get("/classes/pupils-with-conditions", {
            params: { classes: classes.join(",") },
        });

        return response.data;
    } catch (e) {
        const text = e.response?.data?.message || "Ошибка загрузки данных";
        throw new Error(text);
    }
}
