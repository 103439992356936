import React from "react";
import moment from "moment";

import { MeetingStatus } from "components/meetings/MeetingStatus";

export const MeetingsListItem = ({
    meeting,
    role,
    t,
    clickHandler,
    changeMeetingStatusHandler,
}) => {
    return (
        <div className="list__item-wrap">
            <p className="list__item-date">
                {moment(meeting.date).utc().format("DD MMM YYYY, HH:mm")}
            </p>
            <div
                onClick={() => {
                    clickHandler(meeting._id);
                }}
                className="list__item list__item-meeting"
            >
                {meeting.tag && role === "psych" && (
                    <p className="list__item-meeting-title" style={{ marginBottom: 8 }}>
                        <span className="list__item-meeting-tag">{meeting.tag}</span>
                        <MeetingStatus status={meeting.status} t={t} />
                    </p>
                )}
                <p className="list__item-meeting-title">
                    <span className="list__item-meeting-title-name">{meeting.name}</span>
                    {role === "psych" && !meeting.tag && (
                        <MeetingStatus status={meeting.status} t={t} />
                    )}
                    {role === "pupil" && <MeetingStatus status={meeting.status} t={t} />}
                </p>
                <p className="list__desc list__item-meeting-desc">{meeting.description}</p>

                <div className="list__item-meeting-bottom-block">
                    <p>
                        {meeting.pupil?.surname} {meeting.pupil?.name} •{" "}
                        {meeting.pupil?.class?.number}
                        {meeting.pupil?.class?.letter}
                    </p>
                    {role === "pupil" && meeting.status === "unread" && (
                        <div>
                            <button
                                className="list__item-meeting-btn"
                                onClick={(e) =>
                                    changeMeetingStatusHandler(e, meeting._id, "canceled")
                                }
                            >
                                {t("cancel")}
                            </button>
                            <button
                                className="list__item-meeting-btn accept"
                                style={{ marginLeft: 10 }}
                                onClick={(e) => changeMeetingStatusHandler(e, meeting._id, "read")}
                            >
                                {t("confirm")}
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
