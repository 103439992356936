import fetch from "../fetch";
import { startLoading, finishLoading, showAlert } from "../actions/setAppActions";
import { SET_DNEVNIKRU_USER, SET_USER, UPDATE_USER } from "../types";
import i18n from "i18n.js";

export function updateUser(data) {
    return async (dispatch, getState) => {
        const { role, user } = getState().auth;

        try {
            dispatch(startLoading());

            const json = await fetch(
                `/api/${role}/update`,
                {
                    method: "POST",
                    body: JSON.stringify({ ...data, id: user._id }),
                    headers: { "Content-Type": "application/json" },
                },
                getState,
                dispatch
            );

            dispatch({ type: UPDATE_USER, payload: json[role] });
            dispatch(finishLoading());
            dispatch(
                showAlert({
                    type: "success",
                    text: i18n.t("successful_changes", { ns: "translation" }),
                })
            );
        } catch (e) {
            dispatch(finishLoading());
            dispatch(showAlert({ type: "error", text: e.message }));
        }
    };
}

export function setUser(payload) {
    return (dispatch) => {
        dispatch({ type: SET_USER, payload });
    };
}

export function setDnevnikruUser(payload) {
    return (dispatch) => {
        dispatch({ type: SET_DNEVNIKRU_USER, payload });
    };
}

export async function saveLastLogin() {
    try {
        await fetch(`/api/auth/last-login`, { method: "POST" });
    } catch (e) {}
}
