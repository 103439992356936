import { NavLink } from "react-router-dom";
import { RiHomeLine, RiSettings4Line } from "react-icons/ri";

export const Navbar = () => {
    return (
        <div className="navbar">
            <nav>
                <NavLink to={`/dashboard`}>
                    <RiHomeLine
                        style={{
                            fontSize: "24px",
                            color: "rgba(18, 36, 67, 0.6)",
                        }}
                    />
                </NavLink>
                <NavLink to={`/settings`}>
                    <RiSettings4Line
                        style={{
                            fontSize: "24px",
                            color: "rgba(18, 36, 67, 0.6)",
                        }}
                    />
                </NavLink>
            </nav>
        </div>
    );
};
