import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Layout } from "components/Layout";
import { BackButton } from "components/BackButton";
import { mainGuidelinesList } from "components/profile/guidelines/mainGuidelines";

export const GuidelinesPage = () => {
    const { t } = useTranslation("profile");

    return (
        <Layout>
            <div className="page full-header">
                <header className="page__header full-header">
                    <BackButton />
                    <p className="page__title">{t("guidelines")}</p>
                </header>

                <div className="workWithMethods-page__wrapper">
                    <div className="list workWithMethods-page__list">
                        {mainGuidelinesList.map(({ title, link }) => (
                            <Link to={`/guidelines${link}`} className="main-btn" key={link}>
                                {title}
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
        </Layout>
    );
};
