import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import TextareaAutosize from "react-textarea-autosize";
import axios from "axios";

import { getPupil } from "redux/actions/setPupilActions";
import { showAlert } from "redux/actions/setAppActions";
import { BackButton } from "components/BackButton";
import { Layout } from "components/Layout";

/**
 * @api {get} /pupils/:id/parent-contacts контакты родителей ученика
 * @apiGroup profile
 * @apiName контакты родителей ученика
 *
 * @apiParam {String} id ID ученика
 *
 * @apiDescription <p>Страница контактов родителей ученика</p>
 * <p>Компонент <code>ParentContacts.js</code> <a href="../client/src/pages/ParentContacts.js">ссылка на файл</a></p>
 */

export const ParentContacts = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation("pupils");
    const { id } = useParams();
    const { pupilData } = useSelector((state) => state.pupil);
    const [contactsValue, setContactsValue] = useState("");

    useEffect(() => {
        dispatch(getPupil(id));
    }, [dispatch, id]);

    useEffect(() => {
        if (pupilData) {
            setContactsValue(pupilData.parentContacts);
        }
    }, [pupilData]);

    const changeHandler = (e) => {
        const value = e.target.value;

        if (value.length < 150) {
            setContactsValue(value);
        }
    };

    const saveHandler = async () => {
        try {
            await axios.put(`/pupil/${id}/parent-contacts`, {
                parentContacts: contactsValue.trim(),
            });

            dispatch(showAlert({ type: "success", text: t("contacts_of_parents_success") }));
        } catch (err) {
            const text = err.response?.data?.message || t("something_went_wrong");

            dispatch(
                showAlert({
                    type: "error",
                    text,
                })
            );
        }
    };

    return (
        <Layout>
            <div className="page">
                <header className="page__header">
                    <BackButton />
                    <p className="page__title">{t("contacts_of_parents")}</p>
                </header>

                <div className="school-page__wrapper">
                    <div className="input-field">
                        <TextareaAutosize
                            className="textarea-autosize"
                            id="contacts-description"
                            name="title"
                            value={contactsValue}
                            placeholder={t("contacts_of_parents_description")}
                            onChange={changeHandler}
                        />
                        <label htmlFor="contacts-description">
                            {t("contacts_of_parents_description")}
                        </label>
                    </div>
                </div>

                <button onClick={saveHandler} disabled={!contactsValue.trim()} className="main-btn">
                    {t("save_button")}
                </button>
            </div>
        </Layout>
    );
};
