import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { getConditionsINedded } from "redux/actions/setConditionActions";
import { useDebounce } from "hooks/useDebounce";
import { BackButton } from "components/BackButton";
import { Layout } from "components/Layout";
import { showAlert } from "redux/actions/setAppActions";
import { Info } from "components/Info";
import "static/scss/problemsPage.scss";
import { SuitableMethodsList } from "components/methods/SuitableMethodsList";

export const MethodsSuicidalRisks = ({ methods }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { conditions } = useSelector((state) => state.test);
    const { t } = useTranslation("methods");
    const [filteredConditions, setFilteredConditions] = useState([]);
    const [info, setInfo] = useState(null);
    const [searchField, setSearchField] = useState("");
    const debouncedValue = useDebounce(searchField, 500);

    useEffect(() => {
        dispatch(getConditionsINedded());
    }, [dispatch]);

    useEffect(() => {
        if (conditions.length && methods?.length) {
            const suicidalRisksList = methods
                .filter(({ category }) => category === "suicidal-risks")
                .map(({ methods }) => methods)
                .flat();
            const suicidalRisksListUniq = Array.from(new Set(suicidalRisksList));
            const suicidalRisksConditions = conditions.filter(({ _id }) =>
                suicidalRisksListUniq.includes(_id)
            );

            if (debouncedValue.trim()) {
                const regex = new RegExp(debouncedValue, "i");
                const filter = suicidalRisksConditions.filter(
                    ({ name, methodAuthor }) => regex.test(name) || regex.test(methodAuthor)
                );

                setFilteredConditions(filter);
            } else {
                setFilteredConditions(suicidalRisksConditions);
            }
        }
    }, [conditions, methods, debouncedValue]);

    const searchHandler = (e) => {
        setSearchField(e.target.value);
    };

    const sendTestHandler = (condition) => {
        if (condition) {
            history.push(`/tests/create/${condition._id}`);
        } else {
            dispatch(showAlert({ type: "error", text: t("test_not_found") }));
        }
    };

    const showInfoHandler = (e, condition) => {
        if (!info) {
            e.stopPropagation();

            setInfo({
                name: condition.name,
                desc: condition.message,
                conditionId: condition._id,
                author: condition.methodAuthor,
                desTeacher: condition.desTeacher,
                message: condition.message,
                scales: condition.scales,
            });
        } else {
            setInfo(null);
        }
    };

    if (info) {
        return (
            <Layout>
                <Info
                    name={info.name}
                    desc={info.desc}
                    handler={showInfoHandler}
                    conditionId={info.conditionId}
                    author={info.author}
                    desTeacher={info.desTeacher}
                    message={info.message}
                    scales={info.scales}
                />
            </Layout>
        );
    }

    return (
        <Layout>
            <div className="page full-header">
                <header className="page__header full-header">
                    <BackButton />
                    <p className="page__title">{t("suicidal_risks")}</p>
                </header>

                <SuitableMethodsList
                    debouncedValue={debouncedValue}
                    filteredConditions={filteredConditions}
                    searchField={searchField}
                    searchHandler={searchHandler}
                    sendTestHandler={sendTestHandler}
                    showInfoHandler={showInfoHandler}
                />
            </div>
        </Layout>
    );
};
