import React from "react";
import { useTranslation } from "react-i18next";

export const Notice = ({ text, closeHandler }) => {
    const { t } = useTranslation();

    return (
        <div className="notice">
            <div className="notice__content">
                <p>{text}</p>
                <button onClick={closeHandler}>{t("ok_button")}</button>
            </div>
        </div>
    );
};
