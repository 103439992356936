export const LOGIN = "AUTH/LOGIN";
export const LOGOUT = "AUTH/LOGOUT";
export const UPDATE_USER = "AUTH/UPDATE_USER";
export const SET_USER = "AUTH/SET_USER";
export const SET_DNEVNIKRU_USER = "AUTH/SET_DNEVNIKRU_USER";
export const CHANGE_SHOW_TG_CONNECT = "AUTH/CHANGE_SHOW_TG_CONNECT";

export const START_LOADING = "APP/START_LOADING";
export const FINISH_LOADING = "APP/FINISH_LOADING";
export const SHOW_ALERT = "APP/SHOW_ALERT";
export const HIDE_ALERT = "APP/HIDE_ALERT";
export const SHOW_NOTICE = "APP/SHOW_NOTICE";
export const HIDE_NOTICE = "APP/HIDE_NOTICE";
export const DOWNLOAD_FILE_NOTICE = "APP/DOWNLOAD_FILE_NOTICE";

export const GET_SCHOOLS = "SCHOOL/GET_SCHOOLS";
export const GET_SCHOOL = "SCHOOL/GET_SCHOOL";
export const GET_CLASS = "SCHOOL/GET_CLASS";
export const START_SCHOOL_CREATING = "SCHOOL/START_SCHOOL_CREATING";
export const FINISH_SCHOOL_CREATING = "SCHOOL/FINISH_SCHOOL_CREATING";
export const START_CLASS_CREATING = "SCHOOL/START_CLASS_CREATING";
export const FINISH_CLASS_CREATING = "SCHOOL/FINISH_CLASS_CREATING";
export const CREATE_SCHOOL = "SCHOOL/CREATE_SCHOOL";
export const CREATE_CLASS = "SCHOOL/CREATE_CLASS";

export const GET_CONDITIONS = "TEST/GET_CONDITIONS";
export const GET_CONDITION = "TEST/GET_CONDITION";
export const CHANGE_CONDITION_ID = "TEST/CHANGE_CONDITION_ID";
export const STEP_UP = "TEST/STEP_UP";
export const STEP_DOWN = "TEST/STEP_DOWN";
export const STEP_RESET = "TEST/STEP_RESET";
export const STEP_SET = "TEST/STEP_SET";
export const GET_TESTS = "TEST/GET_TESTS";
export const SET_NOT_PASSED_TESTS = "TEST/SET_NOT_PASSED_TESTS";
export const SET_TESTS_COUNT = "TEST/SET_TESTS_COUNT";

export const GET_HISTORIES = "HISTORY/GET_HISTORIES";
export const GET_HISTORY = "HISTORY/GET_HISTORY";

export const GET_STATISTIC_CONDITIONS = "STATISTIC/GET_CONDITIONS";
export const GET_STATISTIC_CONDITION_ID = "STATISTIC/GET_CONDITION_ID";
export const GET_STATISTIC_SCHOOL_ID = "STATISTIC/GET_SCHOOL_ID";
export const GET_STATISTIC_CLASS_ID = "STATISTIC/GET_CLASS_ID";
export const GET_STATISTIC_PUPILS = "STATISTIC/GET_PUPILS";
export const SET_STATISTIC_FILTERS = "STATISTIC/SET_FILTERS_";
export const SET_STATISTIC_FILTER_CATEGORY = "STATISTIC/SET_FILTER_CATEGORY";

export const GET_CREATE_TEST_CONDITIONS = "CREATE_TEST/GET_CONDITIONS";
export const GET_CREATE_TEST_CONDITION_ID = "CREATE_TEST/GET_CONDITION_ID";
export const GET_CREATE_TEST_SCHOOL_ID = "CREATE_TEST/GET_SCHOOL_ID";
export const GET_CREATE_TEST_CLASS_ID = "CREATE_TEST/GET_CLASS_ID";

export const GET_PUPIL = "PUPIL/GET_PUPIL";
export const GET_CATEGORIES = "PUPIL/GET_CATEGORIES";

export const GET_PUPILS = "PUPILS/GET_PUPILS";

export const GET_ADVICES = "ADVICE/GET_ADVICES";
export const CREATE_ADVICE = "ADVICE/CREATE_ADVICE";
export const CONFIRM_ADVICE = "ADVICE/CONFIRM_ADVICE";

export const GET_MEETINGS = "MEETING/GET_MEETINGS";
export const CREATE_MEETING = "MEETING/CREATE_MEETING";
export const CONFIRM_MEETING = "MEETING/CONFIRM_MEETING";

export const GET_METHODS = "METHODS/GET_METHODS";
export const GET_FAVORITE_METHODS = "METHODS/GET_FAVORITE_METHODS";
export const GET_CONDITIONS_DIRECTIONS = "CONDITIONS/GET_CONDITIONS_DIRECTIONS";

export const GET_NOTES = "NOTE/GET_NOTES";
export const CREATE_NOTE = "NOTE/CREATE_NOTE";

export const GET_LAST_ALARM = "ALARM/GET_LAST_ALARM";
export const CREATE_ALARM = "ALARM/CREATE_ALARM";
export const GET_ALARMS = "ALARM/GET_ALARMS";
export const READ_ALARMS = "ALARM/READ_ALARMS";
