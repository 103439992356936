import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Loader } from "components/Loader";
import { Layout } from "components/Layout";
import search from "static/img/ic-search.svg";
import { useDebounce } from "hooks/useDebounce";
import { MethodsListItem } from "components/methods/MethodsListItem";

export const TestsStep = ({ step, loading, conditions, clickHandler, infoBtnHandler }) => {
    const { t, i18n } = useTranslation("tests");
    const [searchField, setSearchField] = useState("");
    const [filteredConditions, setFilteredConditions] = useState([]);
    const debouncedValue = useDebounce(searchField, 500);

    useEffect(() => {
        if (conditions.length) {
            if (debouncedValue.trim()) {
                const regex = new RegExp(debouncedValue, "i");
                const filter = conditions.filter(
                    ({ name, methodAuthor }) => regex.test(name) || regex.test(methodAuthor)
                );

                setFilteredConditions(filter);
            } else {
                setFilteredConditions(conditions);
            }
        }
    }, [conditions, debouncedValue]);

    const searchHandler = (e) => {
        setSearchField(e.target.value);
    };

    if (step !== 1) {
        return null;
    }

    if (loading) {
        return <Loader />;
    }

    return (
        <Layout>
            <div className="page__content">
                <h3>{t("select_methodology")}</h3>

                <div className="school-page__search">
                    <img src={search} alt="" />
                    <input
                        onChange={searchHandler}
                        value={searchField}
                        placeholder={t("search_placeholder")}
                    />
                </div>

                <h4 className="problem-page__h4" style={{ textAlign: "center" }}>
                    {t("suitable_methods_title")}{" "}
                    {debouncedValue && !filteredConditions.length && t("select_not_found_title")}
                </h4>

                {filteredConditions.map((condition) => (
                    <MethodsListItem
                        condition={condition}
                        showInfo={infoBtnHandler}
                        sendTest={clickHandler}
                        language={i18n.language}
                        t={t}
                        key={condition._id}
                    />
                ))}
            </div>
        </Layout>
    );
};
