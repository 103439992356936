import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { getConditionsINedded } from "redux/actions/setConditionActions";
import { showAlert } from "redux/actions/setAppActions";
import { selectMethod } from "redux/methodReducer";
import { getMethodsIfNeeded } from "redux/actions/setMethodActions";
import { useDebounce } from "hooks/useDebounce";
import { Layout } from "components/Layout";
import { BackButton } from "components/BackButton";
import { Info } from "components/Info";
import { Loader } from "components/Loader";
import { MethodsListItem } from "components/methods/MethodsListItem";
import "static/scss/problemPage.scss";
import search from "static/img/ic-search.svg";

/**
 * @api {get} /workWithMethods/problems/:id список методик по современным проблемам подросткового возраста
 * @apiGroup methods
 * @apiName список методик по современным проблемам подросткового возраста
 *
 * @apiParam {String} id ID проблемы
 *
 * @apiDescription <p>Страница со списком методик по современным проблемам подросткового возраста</p>
 * <p>Компонент <code>WorkWithMethodsById.js</code> <a href="../client/src/methods/WorkWithMethodsById.js">ссылка на файл</a></p>
 */

export const WorkWithMethodsById = () => {
    const { t, i18n } = useTranslation("methods");
    const problemId = useParams().id;
    const history = useHistory();
    const dispatch = useDispatch();
    const { loading } = useSelector((state) => state.app);
    const { conditions } = useSelector((state) => state.test);
    const { methods } = useSelector(selectMethod);

    const [isDescription, setIsDescription] = useState(false);
    const [info, setInfo] = useState(null);
    const [method, setMethod] = useState(null);
    const [conditionsList, setConditionsList] = useState([]);
    const [filteredConditions, setFilteredConditions] = useState([]);
    const [searchField, setSearchField] = useState("");
    const debouncedValue = useDebounce(searchField, 500);

    useEffect(() => {
        dispatch(getConditionsINedded());
        dispatch(getMethodsIfNeeded());
    }, [dispatch]);

    useEffect(() => {
        if (methods) {
            const findMethod = methods.find(({ _id }) => _id === problemId);

            if (findMethod) {
                setMethod(findMethod);
                setConditionsList(conditions.filter(({ _id }) => findMethod.methods.includes(_id)));
            } else {
                dispatch(
                    showAlert({
                        type: "error",
                        text: t("method_not_found"),
                    })
                );
            }
        }
    }, [problemId, methods, conditions, dispatch, t]);

    useEffect(() => {
        if (conditionsList.length) {
            if (debouncedValue.trim()) {
                const regex = new RegExp(debouncedValue, "i");
                const filter = conditionsList.filter(
                    ({ name, methodAuthor }) => regex.test(name) || regex.test(methodAuthor)
                );

                setFilteredConditions(filter);
            } else {
                setFilteredConditions(conditionsList);
            }
        }
    }, [conditionsList, debouncedValue]);

    const sendTestHandler = (condition) => {
        if (condition) {
            history.push(`/tests/create/${condition._id}`);
        } else {
            dispatch(showAlert({ type: "error", text: t("test_not_found") }));
        }
    };

    const searchHandler = (e) => {
        setSearchField(e.target.value);
    };

    const showInfoHandler = (e, condition) => {
        if (!info) {
            e.stopPropagation();

            setInfo({
                name: condition.name,
                desc: condition.message,
                conditionId: condition._id,
                author: condition.methodAuthor,
                desTeacher: condition.desTeacher,
                message: condition.message,
                scales: condition.scales,
            });
        } else {
            setInfo(null);
        }
    };

    if (loading) {
        return <Loader />;
    }

    if (isDescription) {
        return (
            <Layout>
                <div className="page">
                    <header className="page__header">
                        <button
                            onClick={() => setIsDescription(false)}
                            className="icon-btn page__icon-btn page__icon-btn_left icon-btn_back"
                        />

                        <p className="page__title">{t("description")}</p>
                    </header>
                    <div className="workWithMethods-page__wrapper">
                        <div className="list">
                            <p className="problem-page__text">{method.description}</p>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }

    if (info) {
        return (
            <Layout>
                <Info
                    name={info.name}
                    desc={info.desc}
                    handler={showInfoHandler}
                    conditionId={info.conditionId}
                    author={info.author}
                    desTeacher={info.desTeacher}
                    message={info.message}
                    scales={info.scales}
                />
            </Layout>
        );
    }

    return (
        <Layout>
            {method && (
                <div className="page">
                    <header className="page__header">
                        <BackButton />
                        <p className="page__title">{t("work_with_methods")}</p>
                    </header>
                    <div className="workWithMethods-page__wrapper">
                        <h4 className="problem-page__h4">{method.title}</h4>

                        {method.description && (
                            <button
                                style={{ marginTop: 40 }}
                                className="main-btn"
                                onClick={() => setIsDescription(true)}
                            >
                                {t("show_description")}
                            </button>
                        )}

                        <h4 className="problem-page__h4">{t("methods_for_your")}:</h4>

                        <div className="school-page__search" style={{ marginBottom: 0 }}>
                            <img src={search} alt="" />
                            <input
                                onChange={searchHandler}
                                value={searchField}
                                placeholder={t("search_placeholder")}
                            />
                        </div>

                        {debouncedValue && !filteredConditions.length && (
                            <p className="problem-page__not-found">
                                {t("suitable_methods_title")} {t("select_not_found_title")}
                            </p>
                        )}

                        {filteredConditions.map((condition) => (
                            <MethodsListItem
                                condition={condition}
                                sendTest={sendTestHandler}
                                showInfo={showInfoHandler}
                                language={i18n.language}
                                t={t}
                                key={condition._id}
                            />
                        ))}
                    </div>
                </div>
            )}
        </Layout>
    );
};
