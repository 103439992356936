import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import axios from "axios";

import { Loader } from "components/Loader";
import { showAlert } from "redux/actions/setAppActions";

export const RegisterPsychPhoneNoCall = ({ setPhoneVerification, phone }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation("registration");
    const [isLoading, setIsLoading] = useState(true);
    const [requestId, setRequestId] = useState(null);
    const [receivePhone, setReceivePhone] = useState(null);

    useEffect(() => {
        (async () => {
            try {
                const { data } = await axios.post("/phone/receive", {
                    phone: phone.replace(/[^0-9]/g, ""),
                });

                setRequestId(data.requestId);
                setReceivePhone(data.number);
            } catch (err) {
                const text = err.response?.data?.message || t("phone_confirm_error");

                dispatch(showAlert({ type: "error", text }));
            } finally {
                setIsLoading(false);
            }
        })();
    }, [phone, dispatch, t]); // eslint-disable-line react-hooks/exhaustive-deps

    const confirmHandler = () => {
        if (requestId) {
            (async () => {
                setIsLoading(true);

                try {
                    const { data } = await axios.post("/phone/receive-status", {
                        requestId,
                    });

                    if (data.success) {
                        setPhoneVerification();
                    } else {
                        setIsLoading(false);
                        dispatch(
                            showAlert({
                                type: "error",
                                text: t("phone_confirm_incorrect_pin"),
                            })
                        );
                    }
                } catch (err) {
                    const text = err.response?.data?.message || t("phone_confirm_incorrect_pin");

                    setIsLoading(false);
                    dispatch(showAlert({ type: "error", text }));
                }
            })();
        }
    };

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <div className="auth-page__register-header">
                        {receivePhone && (
                            <>
                                <h3>
                                    {receivePhone.replace(
                                        /(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/,
                                        "+$1 ($2) $3-$4-$5"
                                    )}
                                </h3>

                                <p className="auth-page__register-text">
                                    {t("make_call_to_number_1")}
                                    <br />
                                    {t("make_call_to_number_2")}
                                </p>
                            </>
                        )}
                    </div>

                    {requestId && (
                        <div className="phone-verification__confirm-btn">
                            <button onClick={confirmHandler} disabled={isLoading}>
                                {t("phone_confirm_btn")}
                            </button>
                        </div>
                    )}
                </>
            )}
        </>
    );
};
