import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { getConditionsINedded } from "redux/actions/setConditionActions";
import { getConditionsDirectionsIfNeeded } from "redux/actions/setMethodActions";
import { selectMethod } from "redux/methodReducer";
import { MethodsByDirectionList } from "components/methods/MethodsByDirectionList";
import { Layout } from "components/Layout";
import "static/scss/problemsPage.scss";
import chat from "static/img/ic-whatsapp.svg";
import arrowRight from "static/img/ic-arrow-next.svg";

export const MethodsByDirection = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { conditions } = useSelector((state) => state.test);
    const { conditionsDirections } = useSelector(selectMethod);
    const { t } = useTranslation("methods");
    const [chosenDirection, setChosenDirection] = useState(null);

    useEffect(() => {
        dispatch(getConditionsINedded());
        dispatch(getConditionsDirectionsIfNeeded());
    }, [dispatch]);

    const chooseDirectionHandler = (direction) => {
        setChosenDirection(direction);
    };

    if (chosenDirection) {
        return (
            <MethodsByDirectionList
                backBtnHandler={() => setChosenDirection(null)}
                conditions={conditions}
                chosenDirection={chosenDirection}
            />
        );
    }

    return (
        <Layout>
            <div className="page full-header">
                <header className="page__header full-header">
                    <button
                        onClick={() => history.goBack()}
                        className="icon-btn page__icon-btn page__icon-btn_left icon-btn_back"
                    />
                    <p className="page__title">{t("by_direction")}</p>
                </header>

                <div className="workWithMethods-page__wrapper">
                    {conditionsDirections && (
                        <div className="list">
                            {conditionsDirections.map(({ _id, name }) => {
                                const capitalizedName =
                                    name.charAt(0).toUpperCase() + name.slice(1);

                                return (
                                    <button
                                        onClick={() => chooseDirectionHandler(_id)}
                                        className="problems-list__item"
                                        key={_id}
                                    >
                                        <div className="problems-list__row">
                                            <img className="problems-list__img" src={chat} alt="" />
                                            <p className="problems-list__text">{capitalizedName}</p>
                                        </div>
                                        <div className="problems-list__btn">
                                            <img
                                                style={{ filter: "invert(1)" }}
                                                src={arrowRight}
                                                alt=""
                                            />
                                        </div>
                                    </button>
                                );
                            })}
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    );
};
