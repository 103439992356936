import React from "react";

import { TestButton } from "./TestButton";
import { CONSTRUCTOR_LINK } from "config";

export const SelectAnswers = ({
    answers,
    question,
    confirmBtnHandler,
    changeAnswer,
    isConfirmPossible,
    isBackBtnActive,
    backBtnHandler,
}) => {
    return (
        <>
            <div className="answers">
                {question.answers.map(({ id, name, isImage, image }, index) => {
                    if (isImage && image) {
                        return (
                            <label className="answers__item" key={id}>
                                <input
                                    type="checkbox"
                                    value={id}
                                    checked={answers[index] === id}
                                    onChange={(e) => {
                                        changeAnswer(e.target.value, index);
                                    }}
                                />
                                <div className="answers__item-image">
                                    <img
                                        src={`${CONSTRUCTOR_LINK}/api/static/images/${image}`}
                                        alt=""
                                        key={id}
                                    />
                                </div>
                            </label>
                        );
                    }

                    return (
                        <label className="answers__item" key={id}>
                            <input
                                type="checkbox"
                                value={id}
                                checked={answers[index] === id}
                                onChange={(e) => {
                                    changeAnswer(e.target.value, index);
                                }}
                            />
                            <span>{name}</span>
                        </label>
                    );
                })}
            </div>
            <div className="answers-wrapper__btns-block">
                <TestButton
                    isActive={isBackBtnActive}
                    type="back"
                    onClickHandler={backBtnHandler}
                />
                <TestButton isActive={isConfirmPossible} onClickHandler={confirmBtnHandler} />
            </div>
        </>
    );
};
