import React, { memo } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import clock from "static/img/ic-clock.svg";
import radar from "static/img/ic-radar.svg";
import pie from "static/img/ic-pie.svg";
import officialPartner from "../../static/docs/official_partner_national_projects.pdf";

export const LandingHero = memo(({ user, t, lang, showPopup }) => {
    return (
        <section className="landing-main" id="landing-main">
            <div className="landing-main__container">
                <div className="landing-main__inner">
                    {/*<h1 className="landing-main__title">{t("title")}</h1>*/}
                    <p className="landing-main__text">
                        {t("subtitle_1a")}{" "}
                        <span>
                            {t("subtitle_1b")}
                            <br className="mob-br" /> {t("subtitle_1c")}
                            <br className="mob-br" /> {t("subtitle_1d")}
                        </span>
                        {/*<br />*/}
                        {/*{t("subtitle_2a")} <br className="mob-br" />*/}
                        {/*<span>{t("subtitle_2b")}</span>, <br className="mob-br" />*/}
                        {/*{t("subtitle_2c")} <span>{t("subtitle_2d")}</span>,<br className="mob-br" />*/}
                        {/*<br />*/}
                        {/*{t("subtitle_2e")} <br className="mob-br" />*/}
                        {/*<span>{t("subtitle_2f")}</span> {t("subtitle_2g")} <br className="mob-br" />*/}
                        {/*{t("subtitle_2h")}.*/}
                    </p>

                    {user ? (
                        <div className="landing-main__buttons-wrapper">
                            <div className="landing-main__buttons-flex">
                                <HashLink
                                    className="landing-main__btn landing-main__btn_blue"
                                    to="#for-parents"
                                    style={{ flexGrow: 0, width: 300, textAlign: "center" }}
                                >
                                    {t("for_parents")}
                                </HashLink>
                            </div>
                            <div
                                className="landing-main__buttons-flex landing-main__buttons-line-2"
                                style={{ flexDirection: "column" }}
                            >
                                <Link
                                    to="/dashboard"
                                    className="landing-main__btn"
                                    style={{ width: 300, textAlign: "center" }}
                                >
                                    {t("button_profile")}
                                </Link>

                                <a
                                    href="https://t.me/testuvisor"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="landing-main__btn landing-main__btn_orange"
                                    style={{ flexGrow: 0 }}
                                >
                                    {t("tg_button")}
                                </a>
                            </div>
                        </div>
                    ) : (
                        <div className="landing-main__buttons-wrapper">
                            <div className="landing-main__buttons-flex">
                                <HashLink
                                    className="landing-main__btn landing-main__btn_blue"
                                    to="#for-parents"
                                    style={{ textAlign: "center" }}
                                >
                                    {t("for_parents")}
                                </HashLink>
                            </div>

                            <div className="landing-main__buttons-flex landing-main__buttons-line-2">
                                <Link to="/psych/login" className="landing-main__btn">
                                    {t("button_psych_login")}
                                </Link>
                                <Link to="/pupil/login" className="landing-main__btn">
                                    {t("button_pupils_login")}
                                </Link>
                            </div>
                            <div className="landing-main__buttons-flex landing-main__buttons-line-2">
                                <HashLink
                                    className="landing-main__btn landing-main__btn_blue"
                                    to="#landing-contacts"
                                    style={{ textAlign: "center" }}
                                >
                                    {t("button_get_familiar")}
                                </HashLink>
                                <div className="landing-main__btn-wrapper">
                                    <button
                                        onClick={showPopup}
                                        className="landing-main__btn landing-main__btn_blue"
                                    >
                                        {t("button_want_to_implement")}
                                    </button>
                                </div>
                            </div>

                            <div className="landing-main__buttons-flex landing-main__buttons-line-3">
                                <a
                                    href="https://t.me/testuvisor"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="landing-main__btn landing-main__btn_orange"
                                    style={{ flexGrow: 0 }}
                                >
                                    {t("tg_button")}
                                </a>
                            </div>
                        </div>
                    )}

                    <div className="landing-main__buttons-flex landing-main__buttons-official_partner">
                        <a
                            href={officialPartner}
                            target="_blank"
                            rel="noreferrer"
                            className="landing-main__btn landing-main__btn_blue landing-main__btn_official_partner"
                            style={{ whiteSpace: "wrap" }}
                        >
                            {t("official_partner")}
                        </a>
                    </div>
                </div>
            </div>

            {lang === "ru" && (
                <div className="landing-main__for_whom">
                    <div className="landing-main__for_whom-inner">
                        <h5 className="landing-main__for_whom-title">Подходит для внедрения:</h5>
                        <div className="landing-main__for_whom-tags">
                            <span>Школы</span>
                            <span>Социально-реабилитационные центры</span>
                            <span>Дома-интернаты</span>
                        </div>
                        <div className="landing-main__for_whom-tags">
                            <span>СУВУ</span>
                            <span>Колледжи и техникумы</span>
                            <span>
                                Организации по работе с несовершеннолетними правонарушителями
                            </span>
                        </div>
                    </div>
                </div>
            )}

            <div className="landing-main__container">
                <div className="landing-main__inner">
                    <div className="landing-main__row">
                        <section className="landing-bullets">
                            <div className="landing-bullet">
                                <span className="landing-bullet__icon">
                                    <img src={clock} alt="icon" />
                                </span>
                                <p className="landing-bullet__text">{t("hero_list_1")}</p>
                            </div>
                            <div className="landing-bullet">
                                <span className="landing-bullet__icon">
                                    <img src={radar} alt="icon" />
                                </span>
                                <p className="landing-bullet__text">{t("hero_list_2")}</p>
                            </div>
                            <div className="landing-bullet">
                                <span className="landing-bullet__icon">
                                    <img src={pie} alt="icon" />
                                </span>
                                <p className="landing-bullet__text">{t("hero_list_3")}</p>
                            </div>
                        </section>

                        <div className="landing-main__video">
                            <div>
                                <iframe src="https://rutube.ru/play/embed/7c7af1ee038176f77d547e844e6dab28/"
                                        frameBorder="0" allow="clipboard-write; autoplay" webkitAllowFullScreen
                                        mozallowfullscreen allowFullScreen/>
                            </div>

                            <div>
                                <iframe src="https://rutube.ru/play/embed/e25a5e0cc07a5e04e129958e5f02093f/"
                                        frameBorder="0" allow="clipboard-write; autoplay" webkitAllowFullScreen
                                        mozallowfullscreen allowFullScreen/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
});
