import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import axios from "axios";

import { showAlert } from "redux/actions/setAppActions";
import { BackButton } from "components/BackButton";
import { Layout } from "components/Layout";
import { Input } from "components/forms/Input";

/**
 * @api {get} /schools/:schoolId/classes/create создание класса
 * @apiGroup schools and classes
 * @apiName создание класса
 *
 *  @apiParam {String} schoolId ID школы
 *
 * @apiDescription <p>Страница создания класса</p>
 * <p>Компонент <code>ClassForm.js</code> <a href="../client/src/pages/classes/ClassForm.js">ссылка на файл</a></p>
 */

/**
 * @api {get} /classes/:id редактирование класса
 * @apiGroup schools and classes
 * @apiName создание редактирование
 *
 * @apiParam {String} id ID класса
 *
 * @apiDescription <p>Страница редактирования класса</p>
 * <p>Компонент <code>ClassForm.js</code> <a href="../client/src/pages/classes/ClassForm.js">ссылка на файл</a></p>
 */

const initialState = {
    number: "",
    letter: "",
    max: "",
};

export const ClassForm = () => {
    const { t } = useTranslation("classes");
    const dispatch = useDispatch();
    const history = useHistory();
    const classId = useParams().id;
    const schoolId = useParams().schoolId;
    const [data, setData] = useState(null);
    const [form, setForm] = useState(initialState);
    const [incorrectFields, setIncorrectFields] = useState([]);
    const formFields = {
        number: t("class_number"),
        letter: t("class_letter"),
        max: t("class_max"),
    };

    useEffect(() => {
        if (classId) {
            (async () => {
                const { data } = await axios.get(`classes/${classId}`);
                setData(data);
                setForm({
                    ...initialState,
                    number: data.number,
                    letter: data.letter,
                    max: data.max,
                });
            })();
        }
    }, [classId]);

    const changeHandler = (event) => {
        const { name, value } = event.target;

        if (incorrectFields.includes(name)) {
            setIncorrectFields(incorrectFields.filter((item) => item !== name));
        }

        if (name === "letter" && value.length > 20) {
            return;
        }

        setForm({ ...form, [name]: value });
    };

    const submit = async (e) => {
        e.preventDefault();
        const errorFields = [];
        if (!form.number.trim()) errorFields.push("number");
        if (form.max < 1) errorFields.push("max");

        if (!errorFields.length) {
            if (data) {
                await axios.post(`classes/${data._id}`, {
                    ...form,
                    schoolId,
                });
            } else {
                form.schoolId = schoolId;
                await axios.post("classes/create", form);
            }

            history.goBack();
        } else {
            const incorrectFieldsMessage = errorFields.map((item) => formFields[item]).join("\n");
            const text = `${t("incorrectly_filled_fields", {
                ns: "translation",
            })}:\n\n${incorrectFieldsMessage}`;

            setIncorrectFields(errorFields);
            dispatch(
                showAlert({
                    type: "error",
                    text,
                })
            );
        }
    };

    return (
        <Layout>
            <div className="page">
                <header className="page__header">
                    <BackButton />
                    <p className="page__title">{t("classes")}</p>
                </header>

                <div className="school-page__wrapper">
                    <h3>{data ? t("change_class") : t("add_class")}</h3>
                    <Input
                        id="number"
                        type="text"
                        name="number"
                        placeholder={t("class_number")}
                        onChange={changeHandler}
                        value={form.number}
                        isError={incorrectFields.includes("number")}
                    />
                    <Input
                        id="letter"
                        type="text"
                        name="letter"
                        placeholder={t("class_letter")}
                        onChange={changeHandler}
                        value={form.letter}
                        isError={incorrectFields.includes("letter")}
                    />
                    <Input
                        id="max"
                        type="number"
                        name="max"
                        placeholder={t("class_max")}
                        onChange={changeHandler}
                        value={form.max}
                        min="1"
                        isError={incorrectFields.includes("max")}
                    />
                    <p style={{ opacity: 0.3 }}>{t("class_text")}</p>
                </div>
                <button onClick={submit} disabled={false} className="main-btn">
                    {data ? t("change_class") : t("add_class")}
                </button>
            </div>
        </Layout>
    );
};
