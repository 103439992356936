import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { FiDelete } from "react-icons/fi";

import { classNames } from "helpers/classNames";
import styles from "./PwaPincodeScreen.module.scss";

const numbers = Array.from({ length: 9 }, (_, i) => i + 1);
const dots = Array.from({ length: 4 }, (_, i) => i + 1);

export const PwaPincodeScreen = ({ handleSendCode, isLoading, pwaUser }) => {
    const history = useHistory();
    const [pinValue, setPinValue] = useState("");

    useEffect(() => {
        if (pinValue.length === 4) {
            handleSendCode(pinValue);
            setPinValue("");
        }
    }, [pinValue]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleClick = (number) => {
        setPinValue(pinValue + number);
    };

    const handleDelete = () => {
        setPinValue(pinValue.slice(0, -1));
    };

    const handleEnterWithLogin = () => {
        history.push(`/${pwaUser.role}/login`);
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.pinDots}>
                {dots.map((dot) => (
                    <div
                        key={dot}
                        className={classNames(styles.pinDotsItem, {
                            [styles.pinDotsItemSuccess]: pinValue.length >= dot,
                        })}
                    />
                ))}
            </div>

            <div className={styles.grid}>
                {numbers.map((number) => (
                    <button key={number} onClick={() => handleClick(number)} disabled={isLoading}>
                        {number}
                    </button>
                ))}
                <div />
                <button onClick={() => handleClick(0)} disabled={isLoading}>
                    0
                </button>
                <button onClick={handleDelete} className={styles.removeBtn} disabled={isLoading}>
                    <FiDelete />
                </button>
            </div>

            {pwaUser && (
                <div>
                    <button onClick={handleEnterWithLogin} className={styles.withLoginButton}>
                        Войти с помощью логина
                    </button>
                </div>
            )}
        </div>
    );
};
