import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import axios from "axios";

import { FaCheck } from "react-icons/fa";
import { UPDATE_USER } from "redux/types";
import terms from "static/docs/LSSR_terms_of_use.pdf";
import policy from "static/docs/policy.pdf";

export const PupilAgreementsInProfile = ({ role, user }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation("registration");
    const [firstCheckboxChecked, setFirstCheckboxChecked] = useState(false);
    const [secondCheckboxChecked, setSecondCheckboxChecked] = useState(false);
    const [thirdCheckboxChecked, setThirdCheckboxChecked] = useState(false);
    const [fourthCheckboxChecked, setFourthCheckboxChecked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    function firstCheckboxHandler() {
        setFirstCheckboxChecked(!firstCheckboxChecked);
    }

    function secondCheckboxHandler() {
        setSecondCheckboxChecked(!secondCheckboxChecked);
    }

    function thirdCheckboxHandler() {
        setThirdCheckboxChecked(!thirdCheckboxChecked);
    }

    function fourthCheckboxHandler() {
        setFourthCheckboxChecked(!fourthCheckboxChecked);
    }

    const register = async () => {
        try {
            if (
                firstCheckboxChecked &&
                secondCheckboxChecked &&
                thirdCheckboxChecked &&
                fourthCheckboxChecked
            ) {
                setIsLoading(true);
                await axios.patch(`${role}/update`, { status: "Active" });
                dispatch({ type: UPDATE_USER, payload: { ...user, status: "Active" } });
            }
        } catch (err) {
            console.log(err);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="profile-page__entities">
            <div className="auth-page__wrapper" style={{ padding: "2rem 1.5rem 2.5rem" }}>
                <div className="landing-contacts__col">
                    <div className="animatedCheckbox">
                        <div
                            style={
                                firstCheckboxChecked
                                    ? { backgroundColor: "#2330BA" }
                                    : { backgroundColor: "white" }
                            }
                            onClick={firstCheckboxHandler}
                            className="checkbox"
                        >
                            <FaCheck
                                style={{
                                    filter: "brightness(0) invert(1)",
                                }}
                            />
                        </div>
                        <p>
                            {t("terms_of_use_1a")}{" "}
                            <a href={terms} target="_blank" rel="noreferrer">
                                {t("user_agreement_1")}
                            </a>{" "}
                            {t("terms_of_use_1b")}
                        </p>
                    </div>

                    <div className="animatedCheckbox">
                        <div
                            style={
                                secondCheckboxChecked
                                    ? { backgroundColor: "#2330BA" }
                                    : { backgroundColor: "white" }
                            }
                            onClick={secondCheckboxHandler}
                            className="checkbox"
                        >
                            <FaCheck
                                style={{
                                    filter: "brightness(0) invert(1)",
                                }}
                            />
                        </div>
                        <p>
                            {t("terms_of_use_1a")}{" "}
                            <a href={policy} target="_blank" rel="noreferrer">
                                {t("policy_of_personal_data")}
                            </a>
                        </p>
                    </div>

                    <div className="animatedCheckbox">
                        <div
                            style={
                                thirdCheckboxChecked
                                    ? { backgroundColor: "#2330BA" }
                                    : { backgroundColor: "white" }
                            }
                            onClick={thirdCheckboxHandler}
                            className="checkbox"
                        >
                            <FaCheck
                                style={{
                                    filter: "brightness(0) invert(1)",
                                }}
                            />
                        </div>
                        <p>
                            {t("terms_of_use_2a")}{" "}
                            <a href={terms} target="_blank" rel="noreferrer">
                                {t("user_agreement_2")}
                            </a>{" "}
                            {t("terms_of_use_2b")}
                        </p>
                    </div>

                    <div className="animatedCheckbox">
                        <div
                            style={
                                fourthCheckboxChecked
                                    ? { backgroundColor: "#2330BA" }
                                    : { backgroundColor: "white" }
                            }
                            onClick={fourthCheckboxHandler}
                            className="checkbox"
                        >
                            <FaCheck
                                style={{
                                    filter: "brightness(0) invert(1)",
                                }}
                            />
                        </div>
                        <p>{t("terms_of_use_3")}</p>
                    </div>
                </div>

                <p style={{ paddingBottom: "2rem", paddingTop: "2rem" }}>
                    {t("terms_of_use_4a")}{" "}
                    <a href={policy} target="_blank" rel="noreferrer">
                        {t("policy_of_personal_data")}
                    </a>{" "}
                    {t("terms_of_use_4b")}{" "}
                    <a href={terms} target="_blank" rel="noreferrer">
                        {t("user_agreement_2")}
                    </a>
                </p>

                <button
                    style={
                        firstCheckboxChecked &&
                        secondCheckboxChecked &&
                        thirdCheckboxChecked &&
                        fourthCheckboxChecked
                            ? {}
                            : { background: "gray", boxShadow: "none" }
                    }
                    onClick={register}
                    disabled={isLoading}
                    className="main-btn auth-page__btn"
                >
                    {isLoading ? "• • •" : t("registration_button")}
                </button>
            </div>
        </div>
    );
};
