import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "../static/scss/freemiumBanner.scss";
import cross from "../static/img/ic-close.svg";

export default function FreemiumBanner(props) {
    const { t } = useTranslation("landing");

    return (
        <div className="freemium-banner">
            <h3 className="freemium-banner__h3">{t("modal_title")}</h3>
            <h4 className="freemium-banner__h4">{t("modal_subtitle")}</h4>
            <h4 className="freemium-banner__h4 freemium-banner__h4-blue">
                {t("modal_list_title")}
            </h4>
            <div className="freemium-banner__row">
                <div className="freemium-banner__point">
                    <div className="freemium-banner__circle">01</div>
                    <p className="freemium-banner__p">{t("modal_list_1")}</p>
                </div>
                <div className="freemium-banner__point">
                    <div className="freemium-banner__circle">02</div>
                    <p className="freemium-banner__p">{t("modal_list_2")}</p>
                </div>
                <div className="freemium-banner__point">
                    <div className="freemium-banner__circle">03</div>
                    <p className="freemium-banner__p">{t("modal_list_3")}</p>
                </div>
                <div className="freemium-banner__point">
                    <div className="freemium-banner__circle">04</div>
                    <p className="freemium-banner__p">{t("modal_list_4")}</p>
                </div>
                <div className="freemium-banner__point">
                    <div className="freemium-banner__circle">05</div>
                    <p className="freemium-banner__p">{t("modal_list_5")}</p>
                </div>
                <div className="freemium-banner__point">
                    <div className="freemium-banner__circle">06</div>
                    <p className="freemium-banner__p">{t("modal_list_6")}</p>
                </div>
            </div>
            <div className="freemium-banner__rowBottom">
                <Link to={"/psych/register"} className="freemium-banner__btn">
                    {t("modal_registration")}
                </Link>
                <h5 className="freemium-banner__h5">
                    {t("modal_signature_1")}
                    <br />
                    {t("modal_signature_2")}
                </h5>
            </div>
            <button
                onClick={() => {
                    props.showPopup();
                }}
                className="freemium-banner__close"
            >
                <img src={cross} alt="" />
            </button>
        </div>
    );
}
