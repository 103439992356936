import React from "react";
import { IS_YANAO } from "../config";
import { yanaoComponents } from "../consts/yanaoComponents";

export const YanaoComponent = ({ children, componentName }) => {
    const siteVersion = IS_YANAO ? "yanao" : "main";

    const modifiedChildren = React.Children.map(children, (child) => {
        if (!child || !React.isValidElement(child)) {
            return <></>;
        }
        if (!yanaoComponents?.[componentName]?.[siteVersion]) {
            return child;
        }

        const newElement = React.createElement(
            yanaoComponents[componentName][siteVersion],
            child.props
        );

        if (!React.isValidElement(newElement)) {
            return child;
        }

        return newElement;
    });
    return <>{modifiedChildren}</>;
};
