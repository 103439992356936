import React from "react";
import { Link } from "react-router-dom";

import "static/scss/problemsPage.scss";
import chat from "static/img/ic-whatsapp.svg";
import arrowRight from "static/img/ic-arrow-next.svg";

export const WorkWithMethodsList = ({ methodsList }) => {
    return (
        <div className="workWithMethods-page__wrapper">
            <div className="list">
                {methodsList.map(({ _id, title, methods }) => (
                    <Link
                        to={`/workWithMethods/methods/${_id}`}
                        className="problems-list__item"
                        key={_id}
                    >
                        <div className="problems-list__row">
                            <img className="problems-list__img" src={chat} alt="" />
                            <p className="problems-list__text">
                                {title}{" "}
                                <span className="problems-list__text-span">
                                    (Кол-во методик: {methods.length})
                                </span>
                            </p>
                        </div>
                        <div className="problems-list__btn">
                            <img style={{ filter: "invert(1)" }} src={arrowRight} alt="" />
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
};
