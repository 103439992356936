import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Layout } from "components/Layout";
import { BackButton } from "components/BackButton";

/**
 * @api {get} /classes/:id/addPupils Добавление учеников
 * @apiGroup psychologists
 * @apiName Добавление учеников
 *
 * @apiParam {String} id ID Класса
 *
 * @apiDescription <p>Страница с Добавлением учеников и ссылками на методы их добавления</p>
 * <p>Компонент <code>AddPupils.js</code> <a href="../client/src/pages/AddPupils.js">ссылка на файл</a></p>
 */

export const AddPupils = ({location}) => {
    const { t } = useTranslation("pupils");
    const classId = useParams().id;
    const [data, setData] = useState(null);

    useEffect(() => {
        (async () => {
            const { data } = await axios.get(`classes/${classId}`);
            setData(data);
        })();
    }, [classId]);

    return (
        <Layout>
            <div className="page addPupils-page">
                <header className="page__header">
                    <BackButton />
                    <p className="page__title addPupils-page__title">
                        {data?.school.name}, {data?.number} {data?.letter}
                    </p>
                </header>

                <div className="page__content addPupils-page__content">
                    <Link
                        className="addPupils-page__btn-wrapper addPupils-page__btn-wrapper_orange addPupils-page__add-list"
                        to={`/classes/${classId}/add-pupils/excel`}
                    >
                        <span>{t("add_via_list")}</span>
                        <p className="">( {t("recommended_variant")} )</p>
                    </Link>
                    <Link
                        className="addPupils-page__btn-wrapper addPupils-page__add-list"
                        to={`/classes/${classId}/add-pupils/code`}
                    >
                        <span>{t("add_via_link")}</span>

                    </Link>
                    <Link
                        className="addPupils-page__btn-wrapper addPupils-page__add-list"
                        to={{pathname: `/pupil/${classId}`, state: {prevPath: location.pathname}}}
                    >
                        <span>{t("add_via_form")}</span>
                    </Link>
                </div>
            </div>
        </Layout>
    );
};
