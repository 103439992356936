import React, { useEffect, useState } from "react";
import moment from "moment";
import { Link, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { changeMeetingStatus, getMeetings } from "redux/actions/setMeetingActions";
import { BackButton } from "components/BackButton";
import { Layout } from "components/Layout";
import { SureAlert } from "components/SureAlert";
import { MeetingsListItem } from "components/meetings/MeetingsListItem";
import { groupByDate } from "components/meetings/groupByDate";
import styles from "./Meetings.module.scss";

/**
 * @api {get} /pupils/:pupilId/meetings встречи ученика
 * @apiGroup pupils
 * @apiName встречи ученика
 *
 * @apiParam {String} pupilId ID ученика
 *
 * @apiDescription <p>Страница встреч ученика</p>
 * <p>Компонент <code>Meetings.js</code> <a href="../client/src/pages/meetings/Meetings.js">ссылка на файл</a></p>
 */

/**
 * @api {get} /meetings список встреч
 * @apiGroup meetings
 * @apiName список встреч
 * *
 * @apiDescription <p>Страница со списоком встреч</p>
 * <p>Компонент <code>Meetings.js</code> <a href="../client/src/pages/meetings/Meetings.js">ссылка на файл</a></p>
 */

export const Meetings = () => {
    const { t, i18n } = useTranslation("meetings");
    const dispatch = useDispatch();
    const history = useHistory();
    const { pupilId } = useParams();
    const { meetings, meeting } = useSelector((state) => state.meeting);
    const { role } = useSelector((state) => state.auth);
    const [cancelMeetingId, setCancelMeetingId] = useState(null);
    const [upcomingMeetings, setUpcomingMeetings] = useState([]);
    const [pastMeetings, setPastMeetings] = useState([]);
    moment.locale(i18n.language);

    useEffect(() => {
        dispatch(getMeetings(pupilId));
    }, [dispatch, pupilId, role, meeting]);

    useEffect(() => {
        const upcomingList = [];
        const pastList = [];

        for (const meeting of meetings) {
            if (moment(meeting.date).isBefore(moment())) {
                pastList.push(meeting);
            } else {
                upcomingList.push(meeting);
            }

            setUpcomingMeetings(groupByDate(upcomingList));
            setPastMeetings(
                groupByDate(pastList).sort((a, b) => new Date(b.date) - new Date(a.date))
            );
        }
    }, [meetings]);

    const clickHandler = (id) => {
        const href = pupilId ? `/pupils/${pupilId}/meetings/${id}` : `/meetings/${id}`;

        history.push(href);
    };

    const changeMeetingStatusHandler = (e, id, status) => {
        e.stopPropagation();

        if (status === "read") {
            dispatch(changeMeetingStatus(id, status));
        } else if (status === "canceled") {
            setCancelMeetingId(id);
        }
    };

    const cancelMeetingHandler = () => {
        setCancelMeetingId(null);
        dispatch(changeMeetingStatus(cancelMeetingId, "canceled"));
    };

    return (
        <Layout>
            {cancelMeetingId && (
                <SureAlert
                    type="cancelMeeting"
                    submitText={t("cancel")}
                    handleSubmit={cancelMeetingHandler}
                    handleClose={() => setCancelMeetingId(null)}
                />
            )}

            <div className="page">
                <header className="page__header">
                    <BackButton />
                    {role === "psych" && (
                        <Link
                            to={
                                pupilId ? `/pupils/${pupilId}/meetings/create` : "/meetings/schools"
                            }
                            className="icon-btn  page__icon-btn page__icon-btn_right icon-btn_add"
                        />
                    )}
                    <p className="page__title">{t("meetings")}</p>
                </header>

                {role === "psych" && (
                    <Link
                        to={pupilId ? `/pupils/${pupilId}/meetings/create` : "/meetings/schools"}
                        className="main-btn main-btn_purple"
                        style={{ marginBottom: 32 }}
                    >
                        {t("create_meeting")}
                    </Link>
                )}

                <div className="school-page__wrapper">
                    <div className="list" style={{ marginBottom: 0 }}>
                        <h3 style={{ marginBottom: 0 }}>{t("upcoming_meetings")}</h3>

                        {upcomingMeetings.length ? (
                            upcomingMeetings.map(({ date, items }) => (
                                <div key={date}>
                                    <h2 className={styles.dateTitle}>
                                        {moment(date).format("DD MMM YYYY")}
                                    </h2>

                                    {items.map((meeting) => (
                                        <MeetingsListItem
                                            meeting={meeting}
                                            role={role}
                                            t={t}
                                            clickHandler={clickHandler}
                                            changeMeetingStatusHandler={changeMeetingStatusHandler}
                                            key={meeting._id}
                                        />
                                    ))}
                                </div>
                            ))
                        ) : (
                            <p className={styles.noMeetings}>{t("no_upcoming_meetings")}</p>
                        )}

                        <h3 style={{ marginTop: 32, marginBottom: 0 }}>{t("past_meetings")}</h3>

                        {pastMeetings.length ? (
                            pastMeetings.map(({ date, items }) => (
                                <div key={date}>
                                    <h2 className={styles.dateTitle}>
                                        {moment(date).format("DD MMM YYYY")}
                                    </h2>

                                    {items.map((meeting) => (
                                        <MeetingsListItem
                                            meeting={meeting}
                                            role={role}
                                            t={t}
                                            clickHandler={clickHandler}
                                            changeMeetingStatusHandler={changeMeetingStatusHandler}
                                            key={meeting._id}
                                        />
                                    ))}
                                </div>
                            ))
                        ) : (
                            <p className={styles.noMeetings}>{t("no_past_meetings")}</p>
                        )}
                    </div>
                </div>
            </div>
        </Layout>
    );
};
