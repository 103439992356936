import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { getConditionsINedded } from "redux/actions/setConditionActions";
import { useDebounce } from "hooks/useDebounce";
import { BackButton } from "components/BackButton";
import { Layout } from "components/Layout";
import { showAlert } from "redux/actions/setAppActions";
import { Info } from "components/Info";
import { SuitableMethodsList } from "components/methods/SuitableMethodsList";
import "static/scss/problemsPage.scss";

export const MethodsMy = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { conditions } = useSelector((state) => state.test);
    const { user } = useSelector((state) => state.auth);
    const { t } = useTranslation("methods");
    const [filteredConditions, setFilteredConditions] = useState([]);
    const [info, setInfo] = useState(null);
    const [isNotFound, setIsNotFound] = useState(false);
    const [searchField, setSearchField] = useState("");
    const debouncedValue = useDebounce(searchField, 500);

    useEffect(() => {
        dispatch(getConditionsINedded());
    }, [dispatch]);

    useEffect(() => {
        if (user && conditions.length) {
            const myConditions = conditions.filter(({ psychId }) => psychId === user._id);

            if (!myConditions.length) {
                setIsNotFound(true);
            } else {
                if (debouncedValue.trim()) {
                    const regex = new RegExp(debouncedValue, "i");
                    const filter = myConditions.filter(
                        ({ name, methodAuthor }) => regex.test(name) || regex.test(methodAuthor)
                    );

                    setFilteredConditions(filter);
                } else {
                    setFilteredConditions(myConditions);
                }
            }
        }
    }, [conditions, user, debouncedValue]);

    const searchHandler = (e) => {
        setSearchField(e.target.value);
    };

    const sendTestHandler = (condition) => {
        if (condition) {
            history.push(`/tests/create/${condition._id}`);
        } else {
            dispatch(showAlert({ type: "error", text: t("test_not_found") }));
        }
    };

    const showInfoHandler = (e, condition) => {
        if (!info) {
            e.stopPropagation();

            setInfo({
                name: condition.name,
                desc: condition.message,
                conditionId: condition._id,
                author: condition.methodAuthor,
                desTeacher: condition.desTeacher,
                message: condition.message,
                scales: condition.scales,
            });
        } else {
            setInfo(null);
        }
    };

    if (info) {
        return (
            <Layout>
                <Info
                    name={info.name}
                    desc={info.desc}
                    handler={showInfoHandler}
                    conditionId={info.conditionId}
                    author={info.author}
                    desTeacher={info.desTeacher}
                    message={info.message}
                    scales={info.scales}
                />
            </Layout>
        );
    }

    return (
        <Layout>
            <div className="page full-header">
                <header className="page__header full-header">
                    <BackButton />
                    <p className="page__title">{t("my_methods")}</p>
                </header>

                {isNotFound ? (
                    <p className="problem-page__not-found">{t("not_my_methods")}</p>
                ) : (
                    <SuitableMethodsList
                        debouncedValue={debouncedValue}
                        filteredConditions={filteredConditions}
                        searchField={searchField}
                        searchHandler={searchHandler}
                        sendTestHandler={sendTestHandler}
                        showInfoHandler={showInfoHandler}
                        isShowTitle={false}
                    />
                )}
            </div>
        </Layout>
    );
};
