import React from "react";
import { useTranslation } from "react-i18next";

import { Loader } from "components/Loader";
import { Layout } from "components/Layout";

export const SchoolsStep = (props) => {
    const { t } = useTranslation("tests");

    if (props.step !== 2) {
        return null;
    }

    if (props.loading) {
        return <Loader />;
    }

    return (
        <Layout>
            <div className="page__content">
                <h3>{t("select_school")}</h3>
                <ul className="list">
                    {props.schools.map((school) => {
                        return (
                            <li
                                onClick={props.clickHandler}
                                className="list__item"
                                key={school._id}
                                id={school._id}
                            >
                                <p>{school.name}</p>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </Layout>
    );
};
