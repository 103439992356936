import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { checkIosPwa } from "helpers/devices";
import { downloadFileInPWA } from "helpers/pwa";
import { Layout } from "components/Layout";
import { BackButton } from "components/BackButton";
import { mainGuidelinesData } from "components/profile/guidelines/mainGuidelines";
import downloadImg from "static/img/download.svg";
import btnRightImg from "static/img/btn-right.svg";
import styles from "./GuidelinesSlugPage.module.scss";

export const GuidelinesSlugPage = () => {
    const { slug } = useParams();
    const { t } = useTranslation("profile");
    const [data, setData] = useState(null);

    useEffect(() => {
        if (mainGuidelinesData[slug]) {
            setData(mainGuidelinesData[slug]);
        } else {
            setData(null);
        }
    }, [slug]);

    const handleClick = async (fileInfo) => {
        if (!fileInfo) return;

        if (checkIosPwa()) {
            await downloadFileInPWA(fileInfo.file, fileInfo.fileName);
        } else {
            window.open(fileInfo.file, "_blank");
        }
    };

    return (
        <Layout>
            <div className="page full-header">
                <header className="page__header full-header">
                    <BackButton />
                    <p className="page__title">{t("guidelines")}</p>
                </header>

                <div className="workWithMethods-page__wrapper">
                    {!mainGuidelinesData[slug] && (
                        <div className="data-error">Раздел не найден</div>
                    )}

                    {data?.folders && (
                        <>
                            <div className="list workWithMethods-page__list">
                                {data.folders.map(({ link, title }) => (
                                    <Link to={`/guidelines${link}`} className="main-btn" key={link}>
                                        {title}
                                    </Link>
                                ))}
                            </div>

                            <hr className={styles.hr} />
                        </>
                    )}

                    {data?.files && (
                        <div className="list workWithMethods-page__list">
                            {data.files.map((item) => (
                                <button
                                    className={styles.fileBtn}
                                    onClick={() => handleClick(item)}
                                    style={{ marginTop: 0 }}
                                    key={item.title}
                                >
                                    <img src={downloadImg} alt="" />
                                    <span>{item.title}</span>
                                    <img src={btnRightImg} alt="" />
                                </button>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    );
};
