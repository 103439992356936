import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const History = ({ history }) => {
    const { t, i18n } = useTranslation("profile");

    const formatDate = useCallback(
        (date) => {
            return new Date(date).toLocaleString(i18n.language, {
                hour: "numeric",
                minute: "numeric",
                year: "numeric",
                month: "short",
                day: "numeric",
            });
        },
        [i18n.language]
    );

    const getUrl = () => {
        if (history.type === "advices") return `/advices?pupilId=${history.pupils[0]._id}`;
        if (history.type === "notes") return `/notes?pupilId=${history.pupils[0]._id}`;
        if (history.type === "meetings") return `/pupils/${history.pupils[0]?._id}/meetings`;
        if (history.type === "tests") return `/schools/${history.schoolId}/classes`;
        return "/";
    };

    return (
        <Link to={getUrl} className="history">
            <p className="history__date">{formatDate(history.date)}</p>
            <div className="history__wrapper">
                <span className={`history__tag history__tag_${history.type}`}>
                    {history.tag || history.type}
                </span>
                <p className="history__title">{history.title}</p>
                <p className="history__desc" dangerouslySetInnerHTML={{ __html: history.desc }} />
                <p className="history__pupils">
                    {history.pupils.length} {t("pupils")}
                </p>
            </div>
        </Link>
    );
};
