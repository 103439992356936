export const checkIosPwa = () => window.navigator.standalone;

export const checkAndroidPwa = () => window.matchMedia("(display-mode: standalone)").matches;

export const checkPwa = () => checkIosPwa() || checkAndroidPwa();

export const checkIsAndroid = () => /Android/i.test(navigator.userAgent);

export const checkIsIos = () => /iPhone|iPad|iPod/i.test(navigator.userAgent);

export const checkIsMobile = () => checkIsAndroid() || checkIsIos();
