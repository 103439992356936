import React from "react";
import { useTranslation } from "react-i18next";

import close from "../static/img/ic-close.svg";
import "../static/scss/sureAlert.scss";

export const SureAlert = ({ title, type, text, handleSubmit, handleClose, submitText }) => {
    const { t } = useTranslation("alert");

    return (
        <div className="sureAlert">
            <div className="sureAlert__inner">
                <h3>{title || t("alert_title")}</h3>

                {type === "createTest" && (
                    <p>
                        {t("create_test")}
                        <br />
                        <br />
                        {t("cancel_action")}
                    </p>
                )}

                {type === "createMeeting" && <p>{t("create_meeting")}</p>}

                {type === "createNote" && (
                    <p>
                        {t("create_note")}
                        <br />
                        <br />
                        {t("cancel_action")}
                    </p>
                )}

                {type === "createAdvice" && (
                    <p>
                        {t("create_recommendation")}
                        <br />
                        <br />
                        {t("cancel_action")}
                    </p>
                )}

                {type === "wantTalk" && (
                    <p>
                        {t("want_to_talk_1")}
                        <br />
                        <br />
                        {t("want_to_talk_2")}
                    </p>
                )}

                {type === "moveToArchive" && <p>{t("move_to_archive")}</p>}

                {type === "deletePupil" && <p>{t("delete_pupil")}</p>}

                {type === "cancelMeeting" && <p>{t("meeting_cancel")}</p>}

                {text && <p>{text}</p>}

                <button onClick={handleSubmit} className="send">
                    {submitText || t("send_button")}
                </button>

                <button onClick={handleClose} className="close">
                    <img alt="" src={close} />
                </button>
            </div>
        </div>
    );
};
