import React from "react";

export const LandingContactsStep1 = ({ form, changeHandler, t }) => {
    return (
        <div className="landing-contacts__input-group">
            <div className="landing-contacts__input-field">
                <label htmlFor="name">{t("footer_form_name")}</label>
                <input
                    placeholder={t("footer_form_name")}
                    id="name"
                    type="text"
                    name="name"
                    onChange={changeHandler}
                    value={form.name}
                />
            </div>
            <div className="landing-contacts__input-field">
                <label htmlFor="surname">{t("footer_form_surname")}</label>
                <input
                    placeholder={t("footer_form_surname")}
                    id="surname"
                    type="text"
                    name="surname"
                    onChange={changeHandler}
                    value={form.surname}
                />
            </div>
            <div className="landing-contacts__input-field">
                <label htmlFor="surname">{t("footer_form_patronymic")}</label>
                <input
                    placeholder={t("footer_form_patronymic")}
                    id="patronymic"
                    type="text"
                    name="patronymic"
                    onChange={changeHandler}
                    value={form.patronymic}
                />
            </div>
        </div>
    );
};
