import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import TextareaAutosize from "react-textarea-autosize";
import i18n from "i18n";
import { MdAdd } from "react-icons/md";
import { IoCloseSharp } from "react-icons/io5";

import { Layout } from "components/Layout";
import { SureAlert } from "components/SureAlert";
import { showAlert } from "redux/actions/setAppActions";
import { getFileFormat } from "helpers/getFileFormat";
import styles from "./advices.module.scss";

const allowedFileTypes = [
    "image/png",
    "image/jpeg",
    "image/gif",
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

export const CreateAdvice = ({ createHandler, closeHandler }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation("advices");
    const [form, setForm] = useState({ title: "", text: "" });
    const [isSureAlert, setIsSureAlert] = useState(false);
    const [document, setDocument] = useState(null);
    const inputRef = useRef(null);

    function onSendClick() {
        setIsSureAlert(true);
    }

    function onCloseClick() {
        setIsSureAlert(false);
    }

    const changeHandler = (event) => {
        setForm({ ...form, [event.target.name]: event.target.value });
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            const fileSize = file.size / 1024 / 1024;

            if (!allowedFileTypes.includes(file.type)) {
                dispatch(
                    showAlert({
                        type: "error",
                        text: i18n.t("invalid_file_type", { ns: "translation" }),
                    })
                );
                event.target.value = null;
            }

            if (fileSize > 20) {
                dispatch(
                    showAlert({
                        type: "error",
                        text: `${i18n.t("maximum_file_size_exceeded", {
                            ns: "translation",
                        })} 20 MB`,
                    })
                );
                event.target.value = null;
            }

            const reader = new FileReader();
            reader.onloadend = () => {
                setDocument({
                    name: file.name,
                    type: getFileFormat(file.type),
                    size: file.size,
                    doc: reader.result,
                });
            };
            reader.readAsDataURL(file);
        }
    };
    const handleSubmit = async () => {
        createHandler({ ...form, document });
        setDocument(null);
        onCloseClick();
    };

    const handleRemoveFile = () => {
        setDocument(null);

        if (inputRef?.current) {
            inputRef.current.value = null;
        }
    };

    return (
        <Layout>
            <div className="page">
                <header className="page__header">
                    <button
                        onClick={closeHandler}
                        className="icon-btn page__icon-btn page__icon-btn_left icon-btn_back"
                    />
                    <p className="page__title">{t("recommendations")}</p>
                </header>

                <div className="school-page__wrapper">
                    <h3>{t("write_recommendation")}</h3>
                    <div className="input-field input-field_green">
                        <TextareaAutosize
                            className="textarea-autosize"
                            id="title"
                            name="title"
                            value={form.title}
                            placeholder={t("recommendation_title_placeholder")}
                            onChange={changeHandler}
                        />
                        <label htmlFor="number">{t("recommendation_title_placeholder")}</label>
                    </div>
                    <div className="input-field input-field_green">
                        <TextareaAutosize
                            className="textarea-autosize"
                            id="text"
                            name="text"
                            value={form.text}
                            onChange={changeHandler}
                            placeholder={t("recommendation_text_placeholder")}
                        />
                        <label htmlFor="letter">{t("recommendation_text_placeholder")}</label>
                    </div>

                    <div className={styles.buttonBlock}>
                        <label className={styles.button}>
                            <input
                                ref={inputRef}
                                type="file"
                                onChange={handleFileChange}
                                accept="image/*,.doc,.docx,.pdf"
                                hidden
                            />
                            {!document && <MdAdd />}
                            <span>{document?.name ? document.name : t("add_file")}</span>
                        </label>

                        {document && (
                            <button onClick={handleRemoveFile} className={styles.clearButton}>
                                <IoCloseSharp />
                            </button>
                        )}
                    </div>
                </div>

                <button
                    onClick={onSendClick}
                    disabled={!form.title || !form.text}
                    className="main-btn main-btn_green"
                >
                    {t("recommendation_send_button")}
                </button>
            </div>

            {isSureAlert && (
                <SureAlert
                    type="createAdvice"
                    handleClose={onCloseClick}
                    handleSubmit={handleSubmit}
                />
            )}
        </Layout>
    );
};
