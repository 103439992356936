import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import axios from "axios";
import { FaRegFileAlt } from "react-icons/fa";

import { Layout } from "components/Layout";
import styles from "./advices.module.scss";

export const ShowAdvice = ({ advice, backHandler, t, i18n }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [doc, setDoc] = useState(null);
    const [isDocumentError, setIsDocumentError] = useState(false);

    useEffect(() => {
        if (advice.doc) {
            setIsLoading(true);

            (async () => {
                try {
                    const { data } = await axios.get(`/advices/doc/${advice.doc}`);

                    if (data) {
                        setDoc(data);
                    }
                } catch (err) {
                    setIsDocumentError(true);
                } finally {
                    setIsLoading(false);
                }
            })();
        }
    }, [advice]);

    const handleDownloadFile = async () => {
        if (doc?.doc) {
            const fileBase64 = doc.doc.split(",")[1];
            const contentType = doc.doc.split(",")[0].split(":")[1].split(";")[0];

            const byteCharacters = atob(fileBase64);
            const byteNumbers = new Array(byteCharacters.length);

            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: contentType });

            const blobUrl = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = blobUrl;
            link.download = doc.name;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            URL.revokeObjectURL(blobUrl);
        }
    };

    return (
        <Layout>
            <div className="page">
                <header className="page__header">
                    <button
                        onClick={backHandler}
                        className="icon-btn page__icon-btn page__icon-btn_left icon-btn_back"
                    />
                    <p className="page__title">
                        <Moment locale={i18n.language} format="DD MMM, YYYY">
                            {advice.createdAt}
                        </Moment>
                    </p>
                </header>
                <div className="page__content">
                    <h3>{advice.title}</h3>
                    <p>{advice.text}</p>

                    {advice.doc && (
                        <div className={styles.attachedFileBlock}>
                            <h4 className={styles.attachedFileTile}>{t("attached_file")}</h4>

                            <div className={styles.buttonBlock}>
                                {isLoading && (
                                    <button className={styles.button}>
                                        <span>{t("file_loading")}</span>
                                    </button>
                                )}

                                {isDocumentError && (
                                    <button className={styles.button} style={{ color: "red" }}>
                                        <span> {t("file_loading_error")}</span>
                                    </button>
                                )}

                                {doc && (
                                    <button onClick={handleDownloadFile} className={styles.button}>
                                        <FaRegFileAlt />
                                        <span>{doc.name}</span>
                                    </button>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    );
};
