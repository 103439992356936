import React from "react";
import { useTranslation } from "react-i18next";

import { Loader } from "components/Loader";
import { Layout } from "components/Layout";

export const ClassesStep = (props) => {
    const { t } = useTranslation("tests");

    if (props.step !== 3) {
        return null;
    }

    if (props.loading || !props.schoolData) {
        return <Loader />;
    }

    return (
        <Layout>
            <div className="page__content">
                <h3>{t("select_class")}</h3>
                <ul className="list">
                    {props.schoolData.classes.map((group) => {
                        return (
                            <li
                                onClick={props.clickHandler}
                                className="list__item"
                                key={group._id}
                                id={group._id}
                            >
                                <p>
                                    {group.number}
                                    {group.letter}
                                </p>
                                <p className="list__desc">
                                    {group.pupils.length} {t("members")}
                                </p>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </Layout>
    );
};
