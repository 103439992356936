import { useQuery } from "@tanstack/react-query";

import { fetchPupilsWithCategories } from "api/pupils/fetchPupilsWithRiskGroups";
import { useMemo, useState } from "react";

export function useFetchPupilsWithCategories() {
    const { data, error, isLoading } = useQuery({
        queryKey: ["pupils/with-categories"],
        queryFn: fetchPupilsWithCategories,
    });

    const initialState = {
        pupilsWithAllCategories: [],
        pupilsWithTargetCategories: [],
        pupilsWithProfileCategories: [],
    };

    const [filters, setFilters] = useState([]);

    const newData = useMemo(() => {
        return (
            data?.reduce((acc, item) => {
                if (!item.categories.length && !filters.length) {
                    return acc;
                }

                for (const category of item.categories) {
                    if (filters.includes(category._id) || !filters.length) {
                        acc.pupilsWithAllCategories.push(item);
                        category.type === "target" && acc.pupilsWithTargetCategories.push(item);
                        category.type === "profile" && acc.pupilsWithProfileCategories.push(item);

                        return acc;
                    }
                }

                return acc;
            }, initialState) || initialState
        );
    }, [filters, data]);

    const changeFiltersList = (categoriesArray) => {
        setFilters(categoriesArray);
    };

    return {
        pupilsWithAllCategories: newData.pupilsWithAllCategories,
        pupilsWithTargetCategories: newData.pupilsWithTargetCategories,
        pupilsWithProfileCategories: newData.pupilsWithProfileCategories,
        changeFiltersList,
        pupilsWithCategoriesError: error,
        pupilsWithCategoriesIsLoading: isLoading,
    };
}
