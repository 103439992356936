import React, { useState, useEffect } from "react";
import Moment from "react-moment";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { createAdvice, getAdvices, readAdvice } from "redux/actions/setAdviceActions";
import { BackButton } from "components/BackButton";
import { Layout } from "components/Layout";
import { Loader } from "components/Loader";
import { CreateAdvice } from "components/advices/CreateAdvice";
import { ShowAdvice } from "components/advices/ShowAdvice";

/**
 * @api {get} /advices?pupilId=pupilId рекомендации
 * @apiGroup profile
 * @apiName рекомендации
 *
 * @apiQuery {String} pupilId ID ученика
 *
 * @apiDescription <p>Страница создания и просмотра списка рекомендаций</p>
 * <p>Компонент <code>AdvicesPage.js</code> <a href="../client/src/pages/AdvicesPage.js">ссылка на файл</a></p>
 */

export const AdvicesPage = () => {
    const dispatch = useDispatch();
    const { search } = useLocation();
    const { t, i18n } = useTranslation("advices");
    const [pupilId, setPupilId] = useState(null);
    const [advice, setAdvice] = useState(null);
    const [isCreate, setIsCreate] = useState(false);
    const { advices, changedAdvice } = useSelector((state) => state.advice);
    const { loading } = useSelector((state) => state.app);
    const { role } = useSelector((state) => state.auth);

    useEffect(() => {
        const query = new URLSearchParams(search);
        const pupilId = query.get("pupilId");

        dispatch(getAdvices(pupilId));
        setPupilId(pupilId);
    }, [dispatch, search, role, changedAdvice]);

    const createHandler = async (form) => {
        dispatch(createAdvice({ ...form, pupilId }));
    };

    if (loading) return <Loader />;

    if (isCreate) {
        return (
            <CreateAdvice
                closeHandler={() => setIsCreate(!isCreate)}
                createHandler={createHandler}
            />
        );
    }

    if (advice) {
        return <ShowAdvice advice={advice} backHandler={() => setAdvice(null)} t={t} i18n={i18n} />;
    }

    const setAdviceHandler = (advice) => {
        setAdvice(advice);

        if (role === "pupil" && !advice.readAt) {
            dispatch(readAdvice(advice._id));
        }
    };

    return (
        <Layout>
            <div className="page">
                <header className="page__header">
                    <BackButton />
                    {role === "psych" && (
                        <button
                            onClick={() => setIsCreate(!isCreate)}
                            className="icon-btn  page__icon-btn page__icon-btn_right icon-btn_add"
                        />
                    )}
                    <p className="page__title">{t("recommendations")}</p>
                </header>

                {role === "psych" && (
                    <button
                        onClick={() => setIsCreate(!isCreate)}
                        className="main-btn main-btn_green"
                        style={{ marginBottom: "1.5rem" }}
                    >
                        {t("write_recommendation")}
                    </button>
                )}

                <div className="school-page__wrapper">
                    <div className="list" style={{ marginBottom: 0 }}>
                        {advices.map((advice) => (
                            <div key={advice._id} className="list__item-wrap">
                                <p className="list__item-date">
                                    <Moment locale={i18n.language} format="DD MMM, YYYY">
                                        {advice.createdAt}
                                    </Moment>
                                </p>
                                <div
                                    className="list__item list__item-advice"
                                    onClick={() => setAdviceHandler(advice)}
                                >
                                    <p className="list__item-advice-title">
                                        <span className="list__item-advice-title-name">
                                            {advice.title}
                                        </span>
                                        {advice.readAt ? (
                                            <span className="list__item-advice-status list__item-advice-read">
                                                {t("read")}
                                            </span>
                                        ) : (
                                            <span className="list__item-advice-status list__item-advice-not-read">
                                                {t("unread")}
                                            </span>
                                        )}
                                    </p>
                                    <p className="list__desc list__item-advice-desc">
                                        {advice.text}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </Layout>
    );
};
