import axios from "axios";

export async function fetchAllMunicipalities() {
    try {
        const response = await axios.get("municipality");

        return response.data;
    } catch (e) {
        const text = e.response?.data?.message || "Ошибка загрузки данных";
        throw new Error(text);
    }
}