import { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";

import { getCookie, setCookie } from "helpers/cookies";
import { UPDATE_USER } from "redux/types";
import { ModalTelegramConnect } from "components/modals/ModalTelegramConnect";

export const ProfileModals = ({ user, role, isShowTgConnect, t }) => {
    const dispatch = useDispatch();
    const isWatchedCookies = getCookie("watchedCookies");
    const [isShowedCookies, setIsShowedCookies] = useState(true);

    useEffect(() => {
        if (isWatchedCookies) {
            setIsShowedCookies(false);
        }
    }, [isWatchedCookies]);

    function cookiesHandler() {
        setCookie("watchedCookies", "true", "365");
        setIsShowedCookies(false);
    }

    const updateShowingMessage = async () => {
        try {
            await axios.patch(`${role}/update`, { isShowTgMessage: false });

            dispatch({ type: UPDATE_USER, payload: { ...user, isShowTgMessage: false } });
        } catch (err) {
            console.log(err);
        }
    };

    const goToTgChannelHandler = () => {
        window.open("https://t.me/testuvisor", "_blank");
        updateShowingMessage();
    };

    return (
        <>
            {isShowedCookies && (
                <div className="cookies">
                    <div className="page">
                        <p>{t("cookie_text")}</p>
                        <button onClick={cookiesHandler}>{t("cookie_accept")}</button>
                    </div>
                </div>
            )}

            {user?.isShowTgMessage ? (
                <div className="modal-messages">
                    <div className="page">
                        <p>
                            {role === "psych" ? t("modal_tg_psych_text") : t("modal_tg_pupil_text")}
                        </p>
                        <div className="modal-messages__buttons">
                            <button onClick={updateShowingMessage}>
                                {t("modal_tg_close_btn")}
                            </button>
                            <button onClick={goToTgChannelHandler}>{t("modal_tg_link_btn")}</button>
                        </div>
                    </div>
                </div>
            ) : (
                <>{isShowTgConnect && user && !user.tgChatId && <ModalTelegramConnect />}</>
            )}
        </>
    );
};
