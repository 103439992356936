import React, { useCallback, useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { FaPen } from "react-icons/fa";

import { hideNotice, showNotice } from "redux/actions/setAppActions";
import { Layout } from "components/Layout";
import { Notice } from "components/Notice";
import { SureAlert } from "components/SureAlert";

/**
 * @api {get} /classes/:id/pupils список учеников класса
 * @apiGroup schools and classes
 * @apiName список учеников класса
 *
 * @apiParam {String} id ID класса
 *
 * @apiDescription <p>Страница со списком учеников класса</p>
 * <p>Компонент <code>Pupils.js</code> <a href="../client/src/pages/Pupils.js">ссылка на файл</a></p>
 */

/**
 * @api {get} /meetings/classes/:id/pupils список учеников при создании встречи
 * @apiGroup meetings
 * @apiName список учеников при создании встречи
 *
 * @apiParam {String} id ID класса
 *
 * @apiDescription <p>Страница со списком учеников при создании встречи</p>
 * <p>Компонент <code>Pupils.js</code> <a href="../client/src/pages/Pupils.js">ссылка на файл</a></p>
 */

const styles = {
    button: {
        position: "absolute",
        alignItems: "center",
        top: "1rem",
        right: "1rem",
        background: "#f4f7f9",
        borderRadius: "7px",
        color: "#122443",
        padding: "7px 10px",
        fontFamily: "inherit",
    },
    h4: {
        color: "#122443",
        fontSize: "20px",
        letterSpacing: "0.4px",
        marginBottom: "1rem",
        fontWeight: "normal",
    },
    list: {
        flexGrow: "initial",
        marginBottom: "3rem",
        marginTop: "2rem",
    },
};

export const Pupils = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const classId = useParams().id;
    const { notice } = useSelector((state) => state.app);
    const { t } = useTranslation("pupils");
    const [pupils, setPupils] = useState([]);
    const [pupilsInArchive, setPupilsInArchive] = useState([]);
    const [classData, setClassData] = useState(null);
    const [pupilIdSureAlert, setPupilIdSureAlert] = useState(null);

    useEffect(() => {
        if (classId) {
            (async () => {
                const { data } = await axios.get(`classes/pupils/${classId}`);

                setPupils(data.pupils.filter((p) => !p.isArchive));
                setPupilsInArchive(data.pupils.filter((p) => p.isArchive));
                setClassData(data.class);
            })();
        }
    }, [classId]);

    const onPupilClickHandler = (id) => {
        if (window.location.href.indexOf("/meetings/classes/") !== -1) {
            history.push(`/pupils/${id}/meetings/create`);
        } else {
            history.push(`/pupils/${id}`);
        }
    };

    const removeFromArchive = async () => {
        setPupilIdSureAlert(null);

        try {
            await axios.put(`pupil/${pupilIdSureAlert}`, {
                isArchive: false,
                classId,
            });

            const updatedPupil = pupilsInArchive.find(({ _id }) => _id === pupilIdSureAlert);

            if (updatedPupil) {
                setPupils(
                    [...pupils, updatedPupil].sort((a, b) => (a["surname"] > b["surname"] ? 1 : -1))
                );
                setPupilsInArchive(pupilsInArchive.filter(({ _id }) => _id !== pupilIdSureAlert));
            } else {
                dispatch(showNotice(t("something_went_wrong", { ns: "translation" })));
            }
        } catch (err) {
            const message =
                err.response?.data?.message || t("something_went_wrong", { ns: "translation" });
            dispatch(showNotice(message));
        }
    };

    const removeFromArchiveHandler = (e) => {
        e.stopPropagation();

        setPupilIdSureAlert(e.currentTarget.id);
    };

    const hideNoticeHandler = useCallback(() => {
        dispatch(hideNotice());
    }, [dispatch]);

    const backBtnHandler = () => {
        const schoolId = classData?.school;
        const link = schoolId ? `/schools/${schoolId}/classes` : -1;

        history.push(link);
    };

    return (
        <Layout>
            {!!notice && <Notice text={notice.text} closeHandler={hideNoticeHandler} />}
            {pupilIdSureAlert && (
                <SureAlert
                    text={t("sure_move_from_archive")}
                    submitText={t("move")}
                    handleClose={() => setPupilIdSureAlert(null)}
                    handleSubmit={removeFromArchive}
                />
            )}

            <div className="page">
                <header className="page__header">
                    <button
                        onClick={backBtnHandler}
                        className="icon-btn page__icon-btn page__icon-btn_left icon-btn_back"
                    />
                    <p className="page__title">
                        {classData?.number} {classData?.letter}
                    </p>
                    {classId && (
                        <Link
                            to={`/classes/${classId}`}
                            className="icon-btn page__icon-btn page__icon-btn_right"
                        >
                            <FaPen />
                        </Link>
                    )}
                </header>

                <div className="school-page__wrapper">
                    {!!pupils.length && (
                        <Link to={`/classes/${classId}/pupils/transfer`} className="main-btn">
                            {t("transferring_pupils")}
                        </Link>
                    )}

                    <div className="list gesturedZone" style={styles.list}>
                        {pupils.map((pupil) => (
                            <div
                                key={pupil._id}
                                style={{
                                    position: "relative",
                                }}
                                onClick={() => onPupilClickHandler(pupil._id)}
                                className="list__item"
                            >
                                <p>
                                    {pupil.surname} {pupil.name}
                                </p>
                                <p className="list__desc">
                                    {classData?.psych.surname} {classData?.psych.name}{" "}
                                    {classData?.psych.patronymic}
                                </p>
                            </div>
                        ))}
                    </div>

                    <h4 style={styles.h4}>{t("archive")}</h4>
                    <div className="list">
                        {pupilsInArchive.map((pupil) => (
                            <div
                                key={pupil._id}
                                style={{
                                    position: "relative",
                                }}
                                onClick={() => onPupilClickHandler(pupil._id)}
                                className="list__item"
                            >
                                <p>
                                    {pupil.surname} {pupil.name}
                                </p>
                                <p className="list__desc">
                                    {classData?.psych.surname} {classData?.psych.name}{" "}
                                    {classData?.psych.patronymic}
                                </p>
                                <button
                                    id={pupil._id}
                                    onClick={removeFromArchiveHandler}
                                    style={styles.button}
                                >
                                    {t("move_from_archive")}
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </Layout>
    );
};
