import orangeTraining from "static/docs/guidelines/prevention/prevention-programs/Апельсиновый_тренинг_Действенный_тренинг.doc";
import socialEmotionalDevelopmentYear1 from "static/docs/guidelines/prevention/prevention-programs/Программа_социально_эмоционального_развития_1_год.pdf";
import socialEmotionalDevelopmentYear2 from "static/docs/guidelines/prevention/prevention-programs/Программа_социально_эмоционального_развития_2_год.pdf";
import antisocialBehaviorPrevention from "static/docs/guidelines/prevention/prevention-programs/Профилактика_асоциального_поведения_подростков.doc";
import autoDestructiveBehaviorPrevention from "static/docs/guidelines/prevention/prevention-programs/Профилактика_аутодеструктивного_поведения_.pdf";
import safetyProgramForThirdGrade from "static/docs/guidelines/prevention/prevention-programs/Психопрофилактическое_мероприятие_для_3_класса_Программа_по_безопасной.docx";
import rpWarmHouse from "static/docs/guidelines/prevention/prevention-programs/Р.П. для родителей Теплый дом.pdf";
import rpMyHome from "static/docs/guidelines/prevention/prevention-programs/Р.П. Дом моего Я.pdf";
import rpLifeInMyHands from "static/docs/guidelines/prevention/prevention-programs/Р.П. Жизнь в моих руках.pdf";
import rpReconciliationTerritory from "static/docs/guidelines/prevention/prevention-programs/Р.П. Территория примирения.pdf";
import rpEverythingInYourHands from "static/docs/guidelines/prevention/prevention-programs/Р.П.Все в твоих руках.pdf";
import rpMaladaptiveBehaviorPrevention from "static/docs/guidelines/prevention/prevention-programs/Р_П_Профилактика_дезадаптивных_форм_поведения_несовершеннолетних.pdf";
import rpChooseLifeInHarmony from "static/docs/guidelines/prevention/prevention-programs/Р_П_Я_выбираю_жизнь_в_гармонии_с_собой_.pdf";
import parentEducationWorldBeginsWithFamily from "static/docs/guidelines/prevention/prevention-programs/Родительский_всеобуч_Мир_начинается_с_семьи_.pdf";

// prevention-programs-npr
import preventionProgramsNprFile from "static/docs/guidelines/prevention/prevention-programs/Программа_по_нравственно_половому_воспитанию.docx";
import preventionProgramsPreventionEarlyPregnancy from "static/docs/guidelines/prevention/prevention-programs/Программа_по_профилактике_ранней_беременности.docx";

// prevention-programs-rlp
import iAndYou57 from "static/docs/guidelines/prevention/prevention-programs/1 - Я и ты [5-7 класс].pdf";
import iAndYou811 from "static/docs/guidelines/prevention/prevention-programs/2 - Я и ты [8-11 класс].pdf";

// prevention-programs-sel
import selfRegulation10 from "static/docs/guidelines/prevention/prevention-programs/10_класс_Управляй_собой_Саморегуляция.pdf";
import selfActualization11 from "static/docs/guidelines/prevention/prevention-programs/11_класс_Реализуй_себя_Самоактуализация.pdf";
import selfKnowledge5 from "static/docs/guidelines/prevention/prevention-programs/5_класс_Познай_себя_Самопознание.pdf";
import selfEducation6 from "static/docs/guidelines/prevention/prevention-programs/6_класс_Сделай_себя_сам_Самовоспитание.pdf";
import selfLearning7 from "static/docs/guidelines/prevention/prevention-programs/7_класс_Научи_себя_учиться_Самообучение.pdf";
import selfAffirmation8 from "static/docs/guidelines/prevention/prevention-programs/8_класс_Утверждай_себя_Самоутрверждение.pdf";
import selfDetermination9 from "static/docs/guidelines/prevention/prevention-programs/9_класс_Найди_себя_Самоопределение.pdf";

// prevention-programs-tk
import pathToMyselfMethodology14 from "static/docs/guidelines/prevention/prevention-programs/Методичка Тропинка к своему Я 1-4.pdf";
import pathToMyselfMethodology56 from "static/docs/guidelines/prevention/prevention-programs/Методичка Тропинка к своему Я 5-6.pdf";
import pathToMyselfMethodology78 from "static/docs/guidelines/prevention/prevention-programs/Методичка_Тропинка_к_своему_Я_7_8_класс.pdf";
import pathToMyselfProgram18 from "static/docs/guidelines/prevention/prevention-programs/Прогр.  Тропинка к своему я 1-8 кл.docx";
import pathToMyselfWorkbook1 from "static/docs/guidelines/prevention/prevention-programs/Тропинка Рабочая тетрадь 1 класс.pdf";
import pathToMyselfWorkbook2 from "static/docs/guidelines/prevention/prevention-programs/Тропинка Рабочая тетрадь 2 класс.pdf";
import pathToMyselfWorkbook3 from "static/docs/guidelines/prevention/prevention-programs/Тропинка Рабочая тетрадь 3 класс.pdf";
import pathToMyselfWorkbook4 from "static/docs/guidelines/prevention/prevention-programs/Тропинка Рабочая тетрадь 4 класс.pdf";

// prevention-programs-school
import umkMethodGuide6Plus from "static/docs/guidelines/prevention/prevention-programs/УМК Методическое пособие 6+.pdf";
import schoolOfOpportunities2Class from "static/docs/guidelines/prevention/prevention-programs/Школа_возможностей_2_класс_методическое_пособие_занятия.pdf";
import schoolOfOpportunitiesParentsGuideYear1 from "static/docs/guidelines/prevention/prevention-programs/Школа_возможностей_Методичка_для_родителей_1_год.pdf";
import schoolOfOpportunitiesParentsGuideYear2 from "static/docs/guidelines/prevention/prevention-programs/Школа_возможностей_Методичка_для_родителей_2_год.pdf";
import schoolOfOpportunitiesSocialEmotional from "static/docs/guidelines/prevention/prevention-programs/Школа_возможностей_РП_социально_эмоционального_развития_младших.docx";

// prevention-programs-extreme
import ministryOfEducationGuidelines2023 from "static/docs/guidelines/prevention/prevention-programs/Метод_рек_от_МинПросв_о_профил_работе_2023.pdf";
import extremeMethodicalHour from "static/docs/guidelines/prevention/prevention-programs/Методический час по экстр.pdf";
import extremeProfile1011 from "static/docs/guidelines/prevention/prevention-programs/Профил экстр 10-11 кл.pdf";
import extremeProfile2 from "static/docs/guidelines/prevention/prevention-programs/Профил экстр 2 кл.pdf";
import extremeProfile34 from "static/docs/guidelines/prevention/prevention-programs/Профил экстр 3-4 кл.pdf";
import extremeProfile56 from "static/docs/guidelines/prevention/prevention-programs/Профил экстр 5-6 кл.pdf";
import extremeProfile79 from "static/docs/guidelines/prevention/prevention-programs/Профил экстр 7-9 кл.pdf";
import extremismPreventionCollection2020 from "static/docs/guidelines/prevention/prevention-programs/Сборник_по_профилактике_экстремизма_в_молодежной_среде_2020.pdf";

export const preventionPrograms = {
    folders: [
        {
            title: "Программа нравственно полового развития",
            link: "/prevention-programs-npr",
        },
        {
            title: "Программа развития личностного потенциала",
            link: "/prevention-programs-rlp",
        },
        {
            title: "Программа самосовершенствование личности Селевко",
            link: "/prevention-programs-sel",
        },
        {
            title: "Программа Тропинка к своему Я О.В. Хухлаева",
            link: "/prevention-programs-tk",
        },
        {
            title: "Программа Школа возможностей",
            link: "/prevention-programs-school",
        },
        {
            title: "Профилактика экстремизма",
            link: "/prevention-programs-extreme",
        },
    ],
    files: [
        {
            title: "Апельсиновый тренинг: Действенный тренинг",
            file: orangeTraining,
            fileName: "Апельсиновый_тренинг_Действенный_тренинг.doc",
        },
        {
            title: "Программа социально-эмоционального развития (1 год)",
            file: socialEmotionalDevelopmentYear1,
            fileName: "Программа_социально_эмоционального_развития_1_год.pdf",
        },
        {
            title: "Программа социально-эмоционального развития (2 год)",
            file: socialEmotionalDevelopmentYear2,
            fileName: "Программа_социально_эмоционального_развития_2_год.pdf",
        },
        {
            title: "Профилактика асоциального поведения подростков",
            file: antisocialBehaviorPrevention,
            fileName: "Профилактика_асоциального_поведения_подростков.doc",
        },
        {
            title: "Профилактика аутодеструктивного поведения",
            file: autoDestructiveBehaviorPrevention,
            fileName: "Профилактика_аутодеструктивного_поведения_.pdf",
        },
        {
            title: "Психопрофилактическое мероприятие для 3 класса: Программа по безопасности",
            file: safetyProgramForThirdGrade,
            fileName: "Психопрофилактическое_мероприятие_для_3_класса_Программа_по_безопасной.docx",
        },
        {
            title: "Р.П. для родителей: Теплый дом",
            file: rpWarmHouse,
            fileName: "Р.П. для родителей Теплый дом.pdf",
        },
        {
            title: "Р.П. Дом моего Я",
            file: rpMyHome,
            fileName: "Р.П. Дом моего Я.pdf",
        },
        {
            title: "Р.П. Жизнь в моих руках",
            file: rpLifeInMyHands,
            fileName: "Р.П. Жизнь в моих руках.pdf",
        },
        {
            title: "Р.П. Территория примирения",
            file: rpReconciliationTerritory,
            fileName: "Р.П. Территория примирения.pdf",
        },
        {
            title: "Р.П. Все в твоих руках",
            file: rpEverythingInYourHands,
            fileName: "Р.П.Все в твоих руках.pdf",
        },
        {
            title: "Р.П. Профилактика дезадаптивных форм поведения несовершеннолетних",
            file: rpMaladaptiveBehaviorPrevention,
            fileName: "Р_П_Профилактика_дезадаптивных_форм_поведения_несовершеннолетних.pdf",
        },
        {
            title: "Р.П. Я выбираю жизнь в гармонии с собой",
            file: rpChooseLifeInHarmony,
            fileName: "Р_П_Я_выбираю_жизнь_в_гармонии_с_собой_.pdf",
        },
        {
            title: "Родительский всеобуч: Мир начинается с семьи",
            file: parentEducationWorldBeginsWithFamily,
            fileName: "Родительский_всеобуч_Мир_начинается_с_семьи_.pdf",
        },
    ],
};

export const preventionProgramsNpr = {
    files: [
        {
            title: "Программа по нравственно половому воспитанию",
            file: preventionProgramsNprFile,
            fileName: "Программа_по_нравственно_половому_воспитанию.docx",
        },
        {
            title: "Программа по профилактике ранней беременности",
            file: preventionProgramsPreventionEarlyPregnancy,
            fileName: "Программа_по_профилактике_ранней_беременности.docx",
        },
    ],
};

export const preventionProgramsRlp = {
    files: [
        {
            title: "Я и ты [5-7 класс]",
            file: iAndYou57,
            fileName: "1 - Я и ты [5-7 класс].pdf",
        },
        {
            title: "Я и ты [8-11 класс]",
            file: iAndYou811,
            fileName: "2 - Я и ты [8-11 класс].pdf",
        },
    ],
};

export const preventionProgramsSel = {
    files: [
        {
            title: "5 класс: Познай себя (Самопознание)",
            file: selfKnowledge5,
            fileName: "5_класс_Познай_себя_Самопознание.pdf",
        },
        {
            title: "6 класс: Сделай себя сам (Самовоспитание)",
            file: selfEducation6,
            fileName: "6_класс_Сделай_себя_сам_Самовоспитание.pdf",
        },
        {
            title: "7 класс: Научи себя учиться (Самообучение)",
            file: selfLearning7,
            fileName: "7_класс_Научи_себя_учиться_Самообучение.pdf",
        },
        {
            title: "8 класс: Утверждай себя (Самоутверждение)",
            file: selfAffirmation8,
            fileName: "8_класс_Утверждай_себя_Самоутрверждение.pdf",
        },
        {
            title: "9 класс: Найди себя (Самоопределение)",
            file: selfDetermination9,
            fileName: "9_класс_Найди_себя_Самоопределение.pdf",
        },
        {
            title: "10 класс: Управляй собой (Саморегуляция)",
            file: selfRegulation10,
            fileName: "10_класс_Управляй_собой_Саморегуляция.pdf",
        },
        {
            title: "11 класс: Реализуй себя (Самоактуализация)",
            file: selfActualization11,
            fileName: "11_класс_Реализуй_себя_Самоактуализация.pdf",
        },
    ],
};

export const preventionProgramsTk = {
    files: [
        {
            title: "Методичка: Тропинка к своему Я (1-4 классы)",
            file: pathToMyselfMethodology14,
            fileName: "Методичка Тропинка к своему Я 1-4.pdf",
        },
        {
            title: "Методичка: Тропинка к своему Я (5-6 классы)",
            file: pathToMyselfMethodology56,
            fileName: "Методичка Тропинка к своему Я 5-6.pdf",
        },
        {
            title: "Методичка: Тропинка к своему Я (7-8 классы)",
            file: pathToMyselfMethodology78,
            fileName: "Методичка_Тропинка_к_своему_Я_7_8_класс.pdf",
        },
        {
            title: "Программа: Тропинка к своему Я (1-8 классы)",
            file: pathToMyselfProgram18,
            fileName: "Прогр.  Тропинка к своему я 1-8 кл.docx",
        },
        {
            title: "Тропинка к своему Я: Рабочая тетрадь 1 класс",
            file: pathToMyselfWorkbook1,
            fileName: "Тропинка Рабочая тетрадь 1 класс.pdf",
        },
        {
            title: "Тропинка к своему Я: Рабочая тетрадь 2 класс",
            file: pathToMyselfWorkbook2,
            fileName: "Тропинка Рабочая тетрадь 2 класс.pdf",
        },
        {
            title: "Тропинка к своему Я: Рабочая тетрадь 3 класс",
            file: pathToMyselfWorkbook3,
            fileName: "Тропинка Рабочая тетрадь 3 класс.pdf",
        },
        {
            title: "Тропинка к своему Я: Рабочая тетрадь 4 класс",
            file: pathToMyselfWorkbook4,
            fileName: "Тропинка Рабочая тетрадь 4 класс.pdf",
        },
    ],
};

export const preventionProgramsSchool = {
    files: [
        {
            title: "УМК: Методическое пособие 6+",
            file: umkMethodGuide6Plus,
            fileName: "УМК Методическое пособие 6+.pdf",
        },
        {
            title: "Школа возможностей: 2 класс (Методическое пособие)",
            file: schoolOfOpportunities2Class,
            fileName: "Школа_возможностей_2_класс_методическое_пособие_занятия.pdf",
        },
        {
            title: "Школа возможностей: Методичка для родителей (1 год)",
            file: schoolOfOpportunitiesParentsGuideYear1,
            fileName: "Школа_возможностей_Методичка_для_родителей_1_год.pdf",
        },
        {
            title: "Школа возможностей: Методичка для родителей (2 год)",
            file: schoolOfOpportunitiesParentsGuideYear2,
            fileName: "Школа_возможностей_Методичка_для_родителей_2_год.pdf",
        },
        {
            title: "Школа возможностей: РП социально-эмоционального развития младших",
            file: schoolOfOpportunitiesSocialEmotional,
            fileName: "Школа_возможностей_РП_социально_эмоционального_развития_младших.docx",
        },
    ],
};

export const preventionProgramsExtreme = {
    files: [
        {
            title: "Методические рекомендации от Минпросвещения о профилактической работе (2023)",
            file: ministryOfEducationGuidelines2023,
            fileName: "Метод_рек_от_МинПросв_о_профил_работе_2023.pdf",
        },
        {
            title: "Методический час по экстренным ситуациям",
            file: extremeMethodicalHour,
            fileName: "Методический час по экстр.pdf",
        },
        {
            title: "Профилактика экстремизма: 2 класс",
            file: extremeProfile2,
            fileName: "Профил экстр 2 кл.pdf",
        },
        {
            title: "Профилактика экстремизма: 3-4 классы",
            file: extremeProfile34,
            fileName: "Профил экстр 3-4 кл.pdf",
        },
        {
            title: "Профилактика экстремизма: 5-6 классы",
            file: extremeProfile56,
            fileName: "Профил экстр 5-6 кл.pdf",
        },
        {
            title: "Профилактика экстремизма: 7-9 классы",
            file: extremeProfile79,
            fileName: "Профил экстр 7-9 кл.pdf",
        },
        {
            title: "Профилактика экстремизма: 10-11 классы",
            file: extremeProfile1011,
            fileName: "Профил экстр 10-11 кл.pdf",
        },
        {
            title: "Сборник по профилактике экстремизма в молодежной среде (2020)",
            file: extremismPreventionCollection2020,
            fileName: "Сборник_по_профилактике_экстремизма_в_молодежной_среде_2020.pdf",
        },
    ],
};
