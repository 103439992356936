import {
    FINISH_LOADING,
    SHOW_ALERT,
    HIDE_ALERT,
    START_LOADING,
    HIDE_NOTICE,
    SHOW_NOTICE,
    DOWNLOAD_FILE_NOTICE,
} from "../types";

export function startLoading() {
    return {
        type: START_LOADING,
    };
}

export function finishLoading() {
    return {
        type: FINISH_LOADING,
    };
}

export function showAlert(alert) {
    return (dispatch) => {
        dispatch({
            type: SHOW_ALERT,
            payload: alert,
        });

        setTimeout(() => {
            dispatch(hideAlert());
        }, 3001);
    };
}

export function hideAlert() {
    return {
        type: HIDE_ALERT,
    };
}

export function showNotice(text) {
    return (dispatch) => {
        dispatch({
            type: SHOW_NOTICE,
            payload: { text },
        });
    };
}

export function hideNotice() {
    return (dispatch) => {
        dispatch({ type: HIDE_NOTICE });
    };
}

export function showDownloadFileNotice(value) {
    return (dispatch) => {
        dispatch({
            type: DOWNLOAD_FILE_NOTICE,
            payload: value,
        });
    };
}
