// psychological-interventions
import counselingAlgorithm1 from "static/docs/guidelines/psychological-interventions/1_Алгоритм_психологического_консультирования.pdf";
import counselingAlgorithm2 from "static/docs/guidelines/psychological-interventions/2_Алгоритм_психологического_консультирования.pdf";
import counselingAlgorithm3 from "static/docs/guidelines/psychological-interventions/3_Алгоритм_психологического_консультирования.pdf";
import counselingAlgorithm4 from "static/docs/guidelines/psychological-interventions/4_Алгоритм_психологического_консультирования.pdf";
import counselingAlgorithm5 from "static/docs/guidelines/psychological-interventions/5_Алгоритм_психологического_консультирования.pdf";
import counselingAlgorithm6 from "static/docs/guidelines/psychological-interventions/6_Алгоритм_психологического_консультирования.pdf";

// psychological-interventions
import preparationWorkWithStudents from "static/docs/guidelines/psychological-interventions/Вложение_1_Работа_с_обучающимися.pdf";
import preparationWorkWithParents from "static/docs/guidelines/psychological-interventions/Вложение_2_Работа_с_родителями.pdf";
import preparationWorkWithTeachers from "static/docs/guidelines/psychological-interventions/Вложение_3_Работа с педагогами.pdf";
import notAfraidOfInterview from "static/docs/guidelines/psychological-interventions/Конспект_Я_не_боюсь_собеседования.pdf";
import examPreparationProgram from "static/docs/guidelines/psychological-interventions/Программа_Психологическая_подготовка_к_ЕГЭ.pdf";

// psychological-interventions
import aggressionCorrectionShort from "static/docs/guidelines/psychological-interventions/Программа_коррекции_агрессивного_поведения_короткая.docx";
import anxietyAssessmentMethod from "static/docs/guidelines/psychological-interventions/Психодиагностическая_методика_для_многомерной_оценки_детской_тревожности.pdf";
import anxietyAndWorry from "static/docs/guidelines/psychological-interventions/Тревога_и_беспокойство.pdf";
import anxietyAndFears from "static/docs/guidelines/psychological-interventions/Тревога_и_страхи.pdf";
import angerManagement from "static/docs/guidelines/psychological-interventions/Управляем_гневом_РТ.pdf";
import schoolAnxiety from "static/docs/guidelines/psychological-interventions/Школьная_тревожность.pdf";

// psychological-interventions
import antiBullyingWorkbook from "static/docs/guidelines/psychological-interventions/Антибуллинг_Рабочая_тетрадь_для_учащихся_11_13_лет.pdf";
import groupSessionNoBullying from "static/docs/guidelines/psychological-interventions/Групповое_занятие_Школьному_буллингу_нет.docx";
import gamesAndExercisesForBullyingPrevention from "static/docs/guidelines/psychological-interventions/Игры_и_упражнения_по_профилактике_травли.docx";
import conflictsInOurLife from "static/docs/guidelines/psychological-interventions/Конфликты_в_нашей_жизни.doc";
import schoolWithoutConflictsCollection from "static/docs/guidelines/psychological-interventions/Сборник_Школа_без_конфликтов.pdf";
import groupCohesionExercises from "static/docs/guidelines/psychological-interventions/Цикл_занятий_Как_сплотить_детей_в_младшей_группе.doc";

// psychological-interventions-hyperactivity
import hyperactiveChildrenCorrection from "static/docs/guidelines/psychological-interventions/Гиперактивные_дети_Коррекция_психомоторного_развития.pdf";
import overcomingADHD from "static/docs/guidelines/psychological-interventions/Победа_над_СДВГ.pdf";

// psychological-interventions-violent
import artPracticesInCrisisFamilies from "static/docs/guidelines/psychological-interventions/Арт_практики_в_работе_с_кризисными_семьями.pdf";
import psychologicalHelpForAbusedChildren from "static/docs/guidelines/psychological-interventions/Психологическая_помощь_детям_пострадавшим_от_насилия.pdf";

// psychological-interventions-support
import tipsToOvercomeAnxiety from "static/docs/guidelines/psychological-interventions/10_советов,_как_преодолеть_тревогу_страх_и_беспокойство.pdf";
import importantSkillsDuringStress from "static/docs/guidelines/psychological-interventions/Важные_навыки_в_периоды_стресса.pdf";
import keyToSelfEmotionsUnderControl from "static/docs/guidelines/psychological-interventions/Ключ_к_себе_Берем_эмоции_под_контроль.pdf";
import surviveGriefAcceptanceNotebook1 from "static/docs/guidelines/psychological-interventions/Пережить_горе_Принятие_Тетрадь_1.pdf";
import surviveGriefHeavyEmotionsNotebook2 from "static/docs/guidelines/psychological-interventions/Пережить_горе_Тяжелые_эмоции_Тетрадь_2.pdf";
import surviveGriefFearNotebook3 from "static/docs/guidelines/psychological-interventions/Пережить горе_Страх_Тетрадь_3.pdf";
import calmWorkbookGrades1To4 from "static/docs/guidelines/psychological-interventions/Рабочая_тетрадь_Я_спокоен_1-4 классы.docx";
import stayCalmTeenagers from "static/docs/guidelines/psychological-interventions/Спокойно_ты_справишься_Подростки.pdf";

// psychological-interventions-play-therapy
import correctionalFairyTalesDuringDivorce from "static/docs/guidelines/psychological-interventions/Психокорекционные_сказки_при_разводе_родителей.docx";
import rainbowOfEmotionsProgram from "static/docs/guidelines/psychological-interventions/Радуга_эмоций_Программа.pdf";
import collectionOfPlayPracticesEmotionalIntelligence from "static/docs/guidelines/psychological-interventions/Сборник_игровых_практик_Эмоциональный_интеллект_детей.pdf";
import fairyTaleTherapyWithSpecialChild from "static/docs/guidelines/psychological-interventions/Сказкотерапия_с_особым_ребенком.pdf";
import cycleOfSessionsWithElementsOfFairyTaleTherapy from "static/docs/guidelines/psychological-interventions/Цикл_занятий_с_элементами_сказкотерапии.pdf";

// psychological-interventions-suicide-risk
import individualProgram from "static/docs/guidelines/psychological-interventions/Индивидуальная программа.docx";
import howToHelpThoseWhoDontWantToLive from "static/docs/guidelines/psychological-interventions/Как_помочь_тем_кто_не_хочет_жить.pdf";
import methodsForSuicidePreventionPrograms from "static/docs/guidelines/psychological-interventions/Метод_рекоменд_по_профил_суацидов_с_названиями_программ.pdf";
import guidelinesForDiagnosingSuicideAmongChildren from "static/docs/guidelines/psychological-interventions/Методич._рекомендации_по_диагностике_суицида_среди_детей_и_подростков.pdf";
import guidelinesForDiagnosingSuicide from "static/docs/guidelines/psychological-interventions/Методические_рекомендации_по_диагностике_суицида.pdf";
import overcomingSuicidalThoughtsAmongTeens from "static/docs/guidelines/psychological-interventions/Преодоление_суицидальных_мыслей_у_подростков.epub";
import suicidePreventionProgramForTeens from "static/docs/guidelines/psychological-interventions/Программа_Профилактики_суицидального_поведения_подростков.doc";
import suicidePrevention from "static/docs/guidelines/psychological-interventions/Профилактика суицида.pdf";
import suicideAlgorithmForTeachers from "static/docs/guidelines/psychological-interventions/Суицид. Алгоритм для педагогов.pdf";
import suicideHandbookForPsychologistsAndSocialPedagogues from "static/docs/guidelines/psychological-interventions/Суицид_Методичка_для_психологов_и_соцпедагогов.pdf";
import suicidalBehaviorAmongTeens from "static/docs/guidelines/psychological-interventions/Суицидальное_поведение_подростков.pdf";

// psychological-interventions-lnr-dnr
import guidelinesPreventingIllegalActionsRisks from "static/docs/guidelines/psychological-interventions/Методические_рекомендации_Предупреждение_рисков_противоправных_деяний.pdf";
import memoChildrenFromLNRDNRUkraine from "static/docs/guidelines/psychological-interventions/Памятка_Дети_из_ДНР_ЛНР_Украины.pdf";
import memoForTeensDuringUnstableWorldSituation from "static/docs/guidelines/psychological-interventions/Памятка_Для_подростков_В_период_нестабильной_ситуации_в_мире.pdf";
import memoForPsychologistsDuringUnstableWorldSituation from "static/docs/guidelines/psychological-interventions/Памятка_Для_психологов_В_период_нестабильной_ситуации_в_мире.pdf";
import memoForParentsDuringUnstableWorldSituation from "static/docs/guidelines/psychological-interventions/Памятка_Для_родителей_В_период_нестабильной_ситуации_в_мире.pdf";
import memoForTeachersDuringUnstableWorldSituation from "static/docs/guidelines/psychological-interventions/Памятка_Для_учителей_В_период_нестабильной_ситуации_в_мире.pdf";
import memoEmotionalStateStabilization from "static/docs/guidelines/psychological-interventions/Памятка_Стабилизация эмоционального состояния.pdf";
import psychologicalSupportProgramForChildrenForcedMigrants from "static/docs/guidelines/psychological-interventions/Программа_психологического_сопровождения_детей_вынужденных_мигрантов.docx";

// psychological-interventions-language-work
import guidelinesLanguageAndSocioculturalIntegration from "static/docs/guidelines/psychological-interventions/Методические_рекомендации_Языковая_и_социокультурная_интеграция.pdf";
import assessmentOfLanguagePreparationLevel from "static/docs/guidelines/psychological-interventions/Оценка_уровня_языковой_подготовки.pdf";
import programRKI from "static/docs/guidelines/psychological-interventions/Программа_РКИ.pdf";
import recommendationsForConductingRKIDiagnostics from "static/docs/guidelines/psychological-interventions/Рекомендации_по_проведению_диагностики_РКИ.pdf";
import languageAdaptationForChildrenFromMigrantFamilies from "static/docs/guidelines/psychological-interventions/Языковая_адаптация_детей_из_семей_мигрантов.pdf";

export const psychologicalInterventions = {
    folders: [
        {
            title: "Алгоритм психологической работы СВО",
            link: "/psychological-interventions-algorithm",
        },
        {
            title: "Психологическая подготовка к ГИА и ЕГЭ",
            link: "/psychological-interventions-preparation",
        },
        {
            title: "Работа с агрессией и тревожностью",
            link: "/psychological-interventions-aggression",
        },
        {
            title: "Работа с буллингом",
            link: "/psychological-interventions-bulling",
        },
        {
            title: "Работа с вынужденными мигрантами",
            link: "/psychological-interventions-migrant",
        },
        {
            title: "Работа с гиперактивностью",
            link: "/psychological-interventions-hyperactivity",
        },
        {
            title: "Работа с пострадавшими от насилия",
            link: "/psychological-interventions-violent",
        },
        {
            title: "Самопомощь для подростков",
            link: "/psychological-interventions-support",
        },
        {
            title: "Сказко- и игротерапия",
            link: "/psychological-interventions-play-therapy",
        },
        {
            title: "Сопровождение при суицидальном риске",
            link: "/psychological-interventions-suicide-risk",
        },
    ],
};

export const psychologicalInterventionsAlgorithm = {
    files: [
        {
            title: "Алгоритм психологического консультирования 1",
            file: counselingAlgorithm1,
            fileName: "1_Алгоритм_психологического_консультирования.pdf",
        },
        {
            title: "Алгоритм психологического консультирования 2",
            file: counselingAlgorithm2,
            fileName: "2_Алгоритм_психологического_консультирования.pdf",
        },
        {
            title: "Алгоритм психологического консультирования 3",
            file: counselingAlgorithm3,
            fileName: "3_Алгоритм_психологического_консультирования.pdf",
        },
        {
            title: "Алгоритм психологического консультирования 4",
            file: counselingAlgorithm4,
            fileName: "4_Алгоритм_психологического_консультирования.pdf",
        },
        {
            title: "Алгоритм психологического консультирования 5",
            file: counselingAlgorithm5,
            fileName: "5_Алгоритм_психологического_консультирования.pdf",
        },
        {
            title: "Алгоритм психологического консультирования 6",
            file: counselingAlgorithm6,
            fileName: "6_Алгоритм_психологического_консультирования.pdf",
        },
    ],
};

export const psychologicalInterventionsPreparation = {
    files: [
        {
            title: "Работа с обучающимися",
            file: preparationWorkWithStudents,
            fileName: "Вложение_1_Работа_с_обучающимися.pdf",
        },
        {
            title: "Работа с родителями",
            file: preparationWorkWithParents,
            fileName: "Вложение_2_Работа_с_родителями.pdf",
        },
        {
            title: "Работа с педагогами",
            file: preparationWorkWithTeachers,
            fileName: "Вложение_3_Работа с педагогами.pdf",
        },
        {
            title: "Я не боюсь собеседования (Конспект)",
            file: notAfraidOfInterview,
            fileName: "Конспект_Я_не_боюсь_собеседования.pdf",
        },
        {
            title: "Программа: Психологическая подготовка к ЕГЭ",
            file: examPreparationProgram,
            fileName: "Программа_Психологическая_подготовка_к_ЕГЭ.pdf",
        },
    ],
};

export const psychologicalInterventionsAggression = {
    files: [
        {
            title: "Программа коррекции агрессивного поведения (короткая)",
            file: aggressionCorrectionShort,
            fileName: "Программа_коррекции_агрессивного_поведения_короткая.docx",
        },
        {
            title: "Психодиагностическая методика для многомерной оценки детской тревожности",
            file: anxietyAssessmentMethod,
            fileName:
                "Психодиагностическая_методика_для_многомерной_оценки_детской_тревожности.pdf",
        },
        {
            title: "Тревога и беспокойство",
            file: anxietyAndWorry,
            fileName: "Тревога_и_беспокойство.pdf",
        },
        {
            title: "Тревога и страхи",
            file: anxietyAndFears,
            fileName: "Тревога_и_страхи.pdf",
        },
        {
            title: "Управляем гневом",
            file: angerManagement,
            fileName: "Управляем_гневом_РТ.pdf",
        },
        {
            title: "Школьная тревожность",
            file: schoolAnxiety,
            fileName: "Школьная_тревожность.pdf",
        },
    ],
};

export const psychologicalInterventionsBulling = {
    files: [
        {
            title: "Антибуллинг: Рабочая тетрадь для учащихся 11-13 лет",
            file: antiBullyingWorkbook,
            fileName: "Антибуллинг_Рабочая_тетрадь_для_учащихся_11_13_лет.pdf",
        },
        {
            title: "Групповое занятие: Школьному буллингу - нет!",
            file: groupSessionNoBullying,
            fileName: "Групповое_занятие_Школьному_буллингу_нет.docx",
        },
        {
            title: "Игры и упражнения по профилактике травли",
            file: gamesAndExercisesForBullyingPrevention,
            fileName: "Игры_и_упражнения_по_профилактике_травли.docx",
        },
        {
            title: "Конфликты в нашей жизни",
            file: conflictsInOurLife,
            fileName: "Конфликты_в_нашей_жизни.doc",
        },
        {
            title: "Сборник: Школа без конфликтов",
            file: schoolWithoutConflictsCollection,
            fileName: "Сборник_Школа_без_конфликтов.pdf",
        },
        {
            title: "Цикл занятий: Как сплотить детей в младшей группе",
            file: groupCohesionExercises,
            fileName: "Цикл_занятий_Как_сплотить_детей_в_младшей_группе.doc",
        },
    ],
};

export const psychologicalInterventionsMigrant = {
    folders: [
        {
            title: "Памятки по работе с детьми из ЛНР и ДНР",
            link: "/psychological-interventions-lnr-dnr",
        },
        {
            title: "Языковая работа",
            link: "/psychological-interventions-language-work",
        },
    ],
};

export const psychologicalInterventionsHyperactivity = {
    files: [
        {
            title: "Гиперактивные дети: Коррекция психомоторного развития",
            file: hyperactiveChildrenCorrection,
            fileName: "Гиперактивные_дети_Коррекция_психомоторного_развития.pdf",
        },
        {
            title: "Победа над СДВГ",
            file: overcomingADHD,
            fileName: "Победа_над_СДВГ.pdf",
        },
    ],
};

export const psychologicalInterventionsViolent = {
    files: [
        {
            title: "Арт-практики в работе с кризисными семьями",
            file: artPracticesInCrisisFamilies,
            fileName: "Арт_практики_в_работе_с_кризисными_семьями.pdf",
        },
        {
            title: "Психологическая помощь детям, пострадавшим от насилия",
            file: psychologicalHelpForAbusedChildren,
            fileName: "Психологическая_помощь_детям_пострадавшим_от_насилия.pdf",
        },
    ],
};

export const psychologicalInterventionsSupport = {
    files: [
        {
            title: "10 советов, как преодолеть тревогу, страх и беспокойство",
            file: tipsToOvercomeAnxiety,
            fileName: "10_советов,_как_преодолеть_тревогу_страх_и_беспокойство.pdf",
        },
        {
            title: "Важные навыки в периоды стресса",
            file: importantSkillsDuringStress,
            fileName: "Важные_навыки_в_периоды_стресса.pdf",
        },
        {
            title: "Ключ к себе: Берем эмоции под контроль",
            file: keyToSelfEmotionsUnderControl,
            fileName: "Ключ_к_себе_Берем_эмоции_под_контроль.pdf",
        },
        {
            title: "Пережить горе: Принятие (Тетрадь 1)",
            file: surviveGriefAcceptanceNotebook1,
            fileName: "Пережить_горе_Принятие_Тетрадь_1.pdf",
        },
        {
            title: "Пережить горе: Тяжелые эмоции (Тетрадь 2)",
            file: surviveGriefHeavyEmotionsNotebook2,
            fileName: "Пережить_горе_Тяжелые_эмоции_Тетрадь_2.pdf",
        },
        {
            title: "Пережить горе: Страх (Тетрадь 3)",
            file: surviveGriefFearNotebook3,
            fileName: "Пережить горе_Страх_Тетрадь_3.pdf",
        },
        {
            title: "Рабочая тетрадь 'Я спокоен' (1-4 классы)",
            file: calmWorkbookGrades1To4,
            fileName: "Рабочая_тетрадь_Я_спокоен_1-4 классы.docx",
        },
        {
            title: "Спокойно, ты справишься (Подростки)",
            file: stayCalmTeenagers,
            fileName: "Спокойно_ты_справишься_Подростки.pdf",
        },
    ],
};

export const psychologicalInterventionsPlayTherapy = {
    files: [
        {
            title: "Психокоррекционные сказки при разводе родителей",
            file: correctionalFairyTalesDuringDivorce,
            fileName: "Психокорекционные_сказки_при_разводе_родителей.docx",
        },
        {
            title: "Радуга эмоций: Программа",
            file: rainbowOfEmotionsProgram,
            fileName: "Радуга_эмоций_Программа.pdf",
        },
        {
            title: "Сборник игровых практик: Эмоциональный интеллект детей",
            file: collectionOfPlayPracticesEmotionalIntelligence,
            fileName: "Сборник_игровых_практик_Эмоциональный_интеллект_детей.pdf",
        },
        {
            title: "Сказкотерапия с особым ребенком",
            file: fairyTaleTherapyWithSpecialChild,
            fileName: "Сказкотерапия_с_особым_ребенком.pdf",
        },
        {
            title: "Цикл занятий с элементами сказкотерапии",
            file: cycleOfSessionsWithElementsOfFairyTaleTherapy,
            fileName: "Цикл_занятий_с_элементами_сказкотерапии.pdf",
        },
    ],
};

export const psychologicalInterventionsSuicideRisk = {
    files: [
        {
            title: "Индивидуальная программа",
            file: individualProgram,
            fileName: "Индивидуальная программа.docx",
        },
        {
            title: "Как помочь тем, кто не хочет жить",
            file: howToHelpThoseWhoDontWantToLive,
            fileName: "Как_помочь_тем_кто_не_хочет_жить.pdf",
        },
        {
            title: "Методы по профилактике суицидов с названиями программ",
            file: methodsForSuicidePreventionPrograms,
            fileName: "Метод_рекоменд_по_профил_суацидов_с_названиями_программ.pdf",
        },
        {
            title: "Методические рекомендации по диагностике суицида среди детей и подростков",
            file: guidelinesForDiagnosingSuicideAmongChildren,
            fileName: "Методич._рекомендации_по_диагностике_суицида_среди_детей_и_подростков.pdf",
        },
        {
            title: "Методические рекомендации по диагностике суицида",
            file: guidelinesForDiagnosingSuicide,
            fileName: "Методические_рекомендации_по_диагностике_суицида.pdf",
        },
        {
            title: "Преодоление суицидальных мыслей у подростков",
            file: overcomingSuicidalThoughtsAmongTeens,
            fileName: "Преодоление_суицидальных_мыслей_у_подростков.epub",
        },
        {
            title: "Программа профилактики суицидального поведения подростков",
            file: suicidePreventionProgramForTeens,
            fileName: "Программа_Профилактики_суицидального_поведения_подростков.doc",
        },
        {
            title: "Профилактика суицида",
            file: suicidePrevention,
            fileName: "Профилактика суицида.pdf",
        },
        {
            title: "Суицид: Алгоритм для педагогов",
            file: suicideAlgorithmForTeachers,
            fileName: "Суицид. Алгоритм для педагогов.pdf",
        },
        {
            title: "Суицид: Методичка для психологов и соцпедагогов",
            file: suicideHandbookForPsychologistsAndSocialPedagogues,
            fileName: "Суицид_Методичка_для_психологов_и_соцпедагогов.pdf",
        },
        {
            title: "Суицидальное поведение подростков",
            file: suicidalBehaviorAmongTeens,
            fileName: "Суицидальное_поведение_подростков.pdf",
        },
    ],
};

export const psychologicalInterventionsLNRDNR = {
    files: [
        {
            title: "Методические рекомендации: Предупреждение рисков противоправных деяний",
            file: guidelinesPreventingIllegalActionsRisks,
            fileName: "Методические_рекомендации_Предупреждение_рисков_противоправных_деяний.pdf",
        },
        {
            title: "Памятка: Дети из ДНР, ЛНР, Украины",
            file: memoChildrenFromLNRDNRUkraine,
            fileName: "Памятка_Дети_из_ДНР_ЛНР_Украины.pdf",
        },
        {
            title: "Памятка для подростков: В период нестабильной ситуации в мире",
            file: memoForTeensDuringUnstableWorldSituation,
            fileName: "Памятка_Для_подростков_В_период_нестабильной_ситуации_в_мире.pdf",
        },
        {
            title: "Памятка для психологов: В период нестабильной ситуации в мире",
            file: memoForPsychologistsDuringUnstableWorldSituation,
            fileName: "Памятка_Для_психологов_В_период_нестабильной_ситуации_в_мире.pdf",
        },
        {
            title: "Памятка для родителей: В период нестабильной ситуации в мире",
            file: memoForParentsDuringUnstableWorldSituation,
            fileName: "Памятка_Для_родителей_В_период_нестабильной_ситуации_в_мире.pdf",
        },
        {
            title: "Памятка для учителей: В период нестабильной ситуации в мире",
            file: memoForTeachersDuringUnstableWorldSituation,
            fileName: "Памятка_Для_учителей_В_период_нестабильной_ситуации_в_мире.pdf",
        },
        {
            title: "Памятка: Стабилизация эмоционального состояния",
            file: memoEmotionalStateStabilization,
            fileName: "Памятка_Стабилизация эмоционального состояния.pdf",
        },
        {
            title: "Программа психологического сопровождения детей вынужденных мигрантов",
            file: psychologicalSupportProgramForChildrenForcedMigrants,
            fileName: "Программа_психологического_сопровождения_детей_вынужденных_мигрантов.docx",
        },
    ],
};

export const psychologicalInterventionsLanguageWork = {
    files: [
        {
            title: "Методические рекомендации: Языковая и социокультурная интеграция",
            file: guidelinesLanguageAndSocioculturalIntegration,
            fileName: "Методические_рекомендации_Языковая_и_социокультурная_интеграция.pdf",
        },
        {
            title: "Оценка уровня языковой подготовки",
            file: assessmentOfLanguagePreparationLevel,
            fileName: "Оценка_уровня_языковой_подготовки.pdf",
        },
        {
            title: "Программа РКИ",
            file: programRKI,
            fileName: "Программа_РКИ.pdf",
        },
        {
            title: "Рекомендации по проведению диагностики РКИ",
            file: recommendationsForConductingRKIDiagnostics,
            fileName: "Рекомендации_по_проведению_диагностики_РКИ.pdf",
        },
        {
            title: "Языковая адаптация детей из семей мигрантов",
            file: languageAdaptationForChildrenFromMigrantFamilies,
            fileName: "Языковая_адаптация_детей_из_семей_мигрантов.pdf",
        },
    ],
};
