import { CHANGE_SHOW_TG_CONNECT, SET_USER, SET_DNEVNIKRU_USER, UPDATE_USER } from "./types";

const initialState = {
    role: null,
    user: null,
    isShowTgConnect: false,
    dnevnikruUser: null,
};

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_USER:
            return { ...state, user: action.payload };
        case SET_USER:
            const { user, role } = action.payload;
            const isShowTgConnect = user?.isShowTgConnect || false;

            return { ...state, user, role, isShowTgConnect };
        case SET_DNEVNIKRU_USER:
            return { ...state, dnevnikruUser: action.payload };
        case CHANGE_SHOW_TG_CONNECT:
            return { ...state, isShowTgConnect: action.payload };
        default:
            return state;
    }
};

export const selectUser = (state) => state.auth;
