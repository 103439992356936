import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { showAlert } from "redux/actions/setAppActions";
import { useDebounce } from "hooks/useDebounce";
import { Layout } from "components/Layout";
import { Info } from "components/Info";
import { SuitableMethodsList } from "components/methods/SuitableMethodsList";
import "static/scss/problemsPage.scss";

export const MethodsByAgeList = ({ conditions, chosenAges, backBtnHandler }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation("methods");
    const [filteredConditions, setFilteredConditions] = useState([]);
    const [conditionsList, setConditionsList] = useState([]);
    const [isNotFound, setIsNotFound] = useState(false);
    const [info, setInfo] = useState(null);
    const [searchField, setSearchField] = useState("");
    const debouncedValue = useDebounce(searchField, 500);

    useEffect(() => {
        const chosenAgesList = chosenAges.map((item) => {
            const [from, to] = item.split("-");

            return {
                from: +from,
                to: +to,
            };
        });

        const filter = conditions.filter(({ ages }) => {
            if (!ages || !ages.min || !ages.max) return false;

            for (const { from, to } of chosenAgesList) {
                if (ages.min >= from && ages.min <= to) {
                    return true;
                } else if (from <= ages.max && to >= ages.max) {
                    return true;
                }
            }

            return false;
        });

        if (filter.length) {
            setConditionsList(filter);
        } else {
            setIsNotFound(true);
        }
    }, [conditions, chosenAges]);

    useEffect(() => {
        if (conditionsList.length) {
            if (debouncedValue.trim()) {
                const regex = new RegExp(debouncedValue, "i");
                const filter = conditionsList.filter(
                    ({ name, methodAuthor }) => regex.test(name) || regex.test(methodAuthor)
                );

                setFilteredConditions(filter);
            } else {
                setFilteredConditions(conditionsList);
            }
        }
    }, [conditionsList, debouncedValue]);

    const sendTestHandler = (condition) => {
        if (condition) {
            history.push(`/tests/create/${condition._id}`);
        } else {
            dispatch(showAlert({ type: "error", text: t("test_not_found") }));
        }
    };

    const searchHandler = (e) => {
        setSearchField(e.target.value);
    };

    const showInfoHandler = (e, condition) => {
        if (!info) {
            e.stopPropagation();

            setInfo({
                name: condition.name,
                desc: condition.message,
                conditionId: condition._id,
                author: condition.methodAuthor,
                desTeacher: condition.desTeacher,
                message: condition.message,
                scales: condition.scales,
            });
        } else {
            setInfo(null);
        }
    };

    if (info) {
        return (
            <Layout>
                <Info
                    name={info.name}
                    desc={info.desc}
                    handler={showInfoHandler}
                    conditionId={info.conditionId}
                    author={info.author}
                    desTeacher={info.desTeacher}
                    message={info.message}
                    scales={info.scales}
                />
            </Layout>
        );
    }

    return (
        <Layout>
            <div className="page full-header">
                <header className="page__header full-header">
                    <button
                        onClick={backBtnHandler}
                        className="icon-btn page__icon-btn page__icon-btn_left icon-btn_back"
                    />
                    <p className="page__title">{t("by_age")}</p>
                </header>

                {isNotFound ? (
                    <>
                        <h4 className="problem-page__h4">{t("methods_for_your")}:</h4>
                        <p className="problem-page__not-found">
                            {t("suitable_methods_title")} {t("select_not_found_title")}
                        </p>
                    </>
                ) : (
                    <SuitableMethodsList
                        debouncedValue={debouncedValue}
                        filteredConditions={filteredConditions}
                        searchField={searchField}
                        searchHandler={searchHandler}
                        sendTestHandler={sendTestHandler}
                        showInfoHandler={showInfoHandler}
                    />
                )}
            </div>
        </Layout>
    );
};
