import { useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";

import { showAlert, showDownloadFileNotice } from "redux/actions/setAppActions";
import i18n from "i18n.js";

export const useCreateReport = () => {
    const dispatch = useDispatch();
    const [isReportLoading, setIsReportLoading] = useState(false);

    const createReport = (url, fileName) => {
        if (!isReportLoading) {
            setIsReportLoading(true);

            axios
                .get(url, { responseType: "blob" })
                .then((response) => {
                    const link = document.createElement("a");

                    link.href = window.URL.createObjectURL(new Blob([response.data]));
                    link.setAttribute("download", fileName);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();

                    setTimeout(() => {
                        dispatch(showDownloadFileNotice(true));
                    }, 150);
                })
                .catch(async (err) => {
                    const errorData = JSON.parse(await err.response.data.text());
                    const text = errorData?.message || i18n.t("report_loading_error");

                    dispatch(showAlert({ type: "error", text }));
                })
                .finally(() => {
                    setIsReportLoading(false);
                });
        }
    };

    return {
        isReportLoading,
        createReport,
    };
};
