import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import axios from "axios";

import { getPupil } from "redux/actions/setPupilActions";
import { showAlert } from "redux/actions/setAppActions";
import { BackButton } from "components/BackButton";
import { Layout } from "components/Layout";
import { Loader } from "components/Loader";
import { Input } from "components/forms/Input";
import { FaCheck } from "react-icons/fa";

/**
 * @api {get} /pupils/:id/accesses доступы ученика и смена пароля
 * @apiGroup profile
 * @apiName доступы ученика и смена пароля
 *
 * @apiParam {String} id ID ученика
 *
 * @apiDescription <p>Страница с доступами ученика и смена пароля</p>
 * <p>Компонент <code>PupilAccesses.js</code> <a href="../client/src/pages/PupilAccesses.js">ссылка на файл</a></p>
 */

export const PupilAccesses = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation("pupils");
    const { id } = useParams();
    const { loading } = useSelector((state) => state.app);
    const { pupilData } = useSelector((state) => state.pupil);
    const { user } = useSelector((state) => state.auth);
    const [pupilLogin, setPupilLogin] = useState(null);
    const [isShowPassword, setIsShowPassword] = useState(false);
    const [isChangePassword, setIsChangePassword] = useState(false);
    const [passwordValue, setPasswordValue] = useState("");

    useEffect(() => {
        dispatch(getPupil(id));
    }, [dispatch, id]);

    useEffect(() => {
        if (pupilData && user) {
            if (pupilData.psych !== user._id) {
                dispatch(
                    showAlert({
                        type: "error",
                        text: t("access_denied"),
                    })
                );
            } else {
                setPupilLogin(pupilData.email);
            }
        }
    }, [pupilData, user, dispatch, t]);

    const savePassword = async () => {
        try {
            await axios.put(`/pupil/${id}/change-password`, {
                password: passwordValue,
            });

            setPasswordValue("");
            setIsChangePassword(false);
            dispatch(showAlert({ type: "success", text: t("password_changed_successfully") }));
        } catch (err) {
            const text = err.response?.data?.message || t("something_went_wrong");

            dispatch(
                showAlert({
                    type: "error",
                    text,
                })
            );
        }
    };

    const showPasswordHandler = () => {
        setIsShowPassword(!isShowPassword);
    };

    const changeHandler = (e) => {
        setPasswordValue(e.target.value.trim());
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <Layout>
            {pupilLogin && (
                <div className="page">
                    <header className="page__header">
                        <BackButton />
                        <p className="page__title">{t("pupil_accesses")}</p>
                    </header>

                    <div className="auth-page__input-group">
                        <Input
                            id="email"
                            type="text"
                            name="email"
                            placeholder={t("login")}
                            value={pupilLogin}
                            readOnly
                        />

                        {isChangePassword && (
                            <>
                                <Input
                                    id="password"
                                    type={`${isShowPassword ? "text" : "password"}`}
                                    name="password"
                                    placeholder={t("password")}
                                    onChange={changeHandler}
                                    value={passwordValue}
                                />
                                <div className="animatedCheckbox animatedCheckbox__password">
                                    <div
                                        onClick={showPasswordHandler}
                                        className={`checkbox ${isShowPassword ? "checked" : ""}`}
                                    >
                                        <FaCheck style={{ filter: "brightness(0) invert(1)" }} />
                                    </div>
                                    <p>{t("show_password")}</p>
                                </div>
                                <p style={{ textAlign: "left", opacity: 0.5 }}>
                                    {t("password_length")}
                                </p>
                            </>
                        )}

                        <div style={{ marginTop: 30 }}>
                            {!isChangePassword ? (
                                <button
                                    onClick={() => setIsChangePassword(true)}
                                    className="main-btn"
                                >
                                    {t("change_password")}
                                </button>
                            ) : (
                                <>
                                    <button
                                        onClick={savePassword}
                                        className="main-btn"
                                        disabled={passwordValue.length < 10}
                                    >
                                        {t("save")}
                                    </button>
                                    <button
                                        onClick={() => setIsChangePassword(false)}
                                        className="main-btn main-btn_orange"
                                        style={{ marginTop: 15 }}
                                    >
                                        {t("cancel")}
                                    </button>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </Layout>
    );
};
