import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { getPupils } from "redux/statisticReducer";
import { useCreateReport } from "hooks/useCreateReport";
import { declinations } from "helpers/declinations";
import { getChosenFilters, getChosenConditions } from "helpers/statistic";
import { BackButton } from "components/BackButton";
import { Range } from "components/Range";
import { Layout } from "components/Layout";
import { Loader } from "components/Loader";
import search from "static/img/ic-search.svg";

/**
 * @api {get} /statistic/classes/:id статистика класса
 * @apiGroup statistic
 * @apiName статистика класса
 *
 * @apiParam {String} id ID класса
 *
 * @apiDescription <p>Страница статистики выбранного класса</p>
 * <p>Компонент <code>StatisticPupils.js</code> <a href="../client/src/pages/statistic/StatisticPupils.js">ссылка на файл</a></p>
 */

export const StatisticPupils = () => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation("statistic");
    const classId = useParams().id;
    const { pupilsData, filters } = useSelector((state) => state.statistic);
    const { loading } = useSelector((state) => state.app);
    const [pupils, setPupils] = useState([]);
    const [filteredPupils, setFilteredPupils] = useState([]);
    const [searchField, setSearchField] = useState("");
    const [afterSearch, setAfterSearch] = useState([]);
    const [chosenFiltersCount, setChosenFiltersCount] = useState(0);
    const { createReport, isReportLoading } = useCreateReport();

    function searchHandler(e) {
        setSearchField(e.target.value);
    }

    useEffect(() => {
        dispatch(getPupils(classId));
    }, [classId, dispatch]);

    useEffect(() => {
        if (pupilsData.length) {
            setPupils(pupilsData);
        }
    }, [pupilsData]);

    useEffect(() => {
        if (classId && pupils.length) {
            let intermediateFilter = pupils.filter(
                ({ tests, isArchive }) => tests?.length && !isArchive
            );

            if (filters.sex) {
                let arr = [];

                for (let i = 0; i < intermediateFilter.length; i++) {
                    if (intermediateFilter[i].sex === filters.sex) {
                        arr.push(intermediateFilter[i]);
                    }
                }

                intermediateFilter = arr;
            }

            if (filters.years) {
                let targetAges = filters.years.replace(/\s/g, "").split(",");
                let arr = [];

                for (let i = 0; i < intermediateFilter.length; i++) {
                    let currentYears = intermediateFilter[i].birthday;
                    let date = new Date(currentYears);
                    let ageDateNumber = Date.now() - date;
                    let ageDate = new Date(ageDateNumber);
                    let year = ageDate.getUTCFullYear();
                    let age = Math.abs(1970 - year);

                    for (let j = 0; j < targetAges.length; j++) {
                        if (targetAges[j].toString() === age.toString()) {
                            arr.push(intermediateFilter[i]);
                        }
                    }
                }

                intermediateFilter = arr;
            }

            if (filters.date) {
                let arr = [];

                for (let i = 0; i < intermediateFilter.length; i++) {
                    if (intermediateFilter[i].dates.includes(filters.date)) {
                        arr.push(intermediateFilter[i]);
                    }
                }

                intermediateFilter = arr;
            }

            if (filters.category) {
                let arr = [];

                for (let i = 0; i < intermediateFilter.length; i++) {
                    for (let j = 0; j < intermediateFilter[i].categories.length; j++) {
                        if (intermediateFilter[i].categories[j] === filters.category) {
                            arr.push(intermediateFilter[i]);
                        }
                    }
                }

                intermediateFilter = arr;
            }

            if (filters.condition) {
                const arr = [];

                for (const pupil of intermediateFilter) {
                    if (pupil.conditions?.includes(filters.condition)) {
                        arr.push(pupil);
                    }
                }

                intermediateFilter = arr;
            }

            const getChosenFiltersCount = Object.values(filters).filter(
                (val) => !!val.length
            ).length;

            setChosenFiltersCount(getChosenFiltersCount);
            setFilteredPupils(intermediateFilter);
        }
    }, [classId, filters, pupils]);

    useEffect(() => {
        if (searchField.length > 0) {
            let newPupils = [];
            for (let pupil of filteredPupils) {
                let name = pupil.name;
                let slicedName = name.slice(0, searchField.length);
                let lastName = pupil.surname;
                let slicedLast = lastName.slice(0, searchField.length);
                if (
                    slicedName.toLowerCase() === searchField.toLowerCase() ||
                    slicedLast.toLowerCase() === searchField.toLowerCase()
                ) {
                    newPupils.push(pupil);
                }
            }
            setAfterSearch(newPupils);
        } else {
            setAfterSearch(filteredPupils);
        }
    }, [searchField, filteredPupils]);

    const createReportHandler = () => {
        const classInfo = pupilsData[0]?.class;
        const className = classInfo ? `${classInfo.number}${classInfo.letter}` : "";
        const pupils = afterSearch.map(({ _id }) => _id).join(",");
        const date = filters.date ? filters.date : t("all_time");
        const chosenFilters = getChosenFilters(filters);
        const chosenConditions = getChosenConditions(filters.condition, afterSearch);

        createReport(
            `statistic/psych-report?pupils=${pupils}&conditions=${chosenConditions}&date=${date}&filters=${chosenFilters}`,
            `${t("class_report")}_${className}.xlsx`
        );
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <Layout>
            <div className="page">
                <header className="page__header">
                    <BackButton />
                    <p className="page__title">{t("statistic")}</p>

                    <Link
                        to={`/statistic/classes/${classId}/filters`}
                        className={
                            !!chosenFiltersCount
                                ? `icon-btn page__icon-btn page__icon-btn_right icon-btn_filter icon-btn_filter_active`
                                : `icon-btn page__icon-btn page__icon-btn_right icon-btn_filter`
                        }
                    >
                        {!!chosenFiltersCount && (
                            <span>
                                {chosenFiltersCount}
                                <div className="dot"></div>
                            </span>
                        )}
                    </Link>
                </header>

                <div>
                    {!!afterSearch.length && (
                        <div
                            className="statistic-reports__btn statistic-reports__btn-class"
                            onClick={createReportHandler}
                        >
                            {isReportLoading ? (
                                <p>{t("report_loading", { ns: "translation" })}</p>
                            ) : (
                                <p>{t("create_report", { ns: "translation" })}</p>
                            )}
                        </div>
                    )}

                    <div className="school-page__search">
                        <img src={search} alt="" />
                        <input
                            onChange={searchHandler}
                            value={searchField}
                            placeholder={t("search_placeholder")}
                        />
                    </div>

                    {afterSearch.length ? (
                        afterSearch.map((pupil) => (
                            <Link
                                key={pupil._id}
                                to={`/statistic/pupils/${pupil._id}`}
                                className="statistic-condition"
                            >
                                <p className="statistic-condition__title">
                                    {pupil.surname} {pupil.name}
                                </p>

                                <Range
                                    total={pupil.tests.length}
                                    norm={pupil.doneTestQuantity}
                                    // notNorm={pupil.negatively}
                                    notNorm={pupil.tests.length - pupil.doneTestQuantity}
                                />

                                <div className="statistic-condition__footer">
                                    <span className="statistic-condition__indicator green">
                                        {pupil.doneTestQuantity}
                                    </span>
                                    <span className="statistic-condition__indicator blue">
                                        {pupil.tests.length - pupil.doneTestQuantity}
                                    </span>

                                    <p className="statistic-condition__members">
                                        {pupil.tests.length}{" "}
                                        {declinations(pupil.tests.length, "test", i18n.language)}
                                    </p>
                                </div>
                            </Link>
                        ))
                    ) : (
                        <p className="statistic-condition-text">{t("not_found")}</p>
                    )}
                </div>
            </div>
        </Layout>
    );
};
