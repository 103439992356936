import React, { memo, useState } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import { CONSTRUCTOR_LINK } from "config";
import logo from "static/img/logo.svg";

export const LandingHeader = memo(({ user, t, showPopup }) => {
    const [isShowPopup, setIsShowPopup] = useState(false);

    return (
        <header className="landing-header">
            <div className="landing-header__logo-wrapper">
                <Link className="landing-header__logo" to="/">
                    <img src={logo} alt="logo" />
                </Link>
            </div>
            <nav className="landing-header__nav">
                <HashLink className="landing-header__link" to="#landing-main">
                    {t("home")}
                </HashLink>
                <HashLink className="landing-header__link" to="#landing-about">
                    {t("our_solution")}
                </HashLink>
                <HashLink className="landing-header__link" to="#forWhatAnchor">
                    {t("for_whom")}
                </HashLink>
                <HashLink className="landing-header__link" to="#quizAnchor">
                    {t("our_methodology")}
                </HashLink>
                <a className="landing-header__link" href={"#landing-goal"}>
                    {t("about")}
                </a>
            </nav>
            {user ? (
                <div className="landing-header__auth">
                    <a className="landing-header__link" href={CONSTRUCTOR_LINK}>
                        {t("constructor")}
                    </a>
                </div>
            ) : (
                <div className="landing-header__auth">
                    <div className="landing-header__login-wrapper">
                        <button
                            className="landing-header__link"
                            onClick={() => setIsShowPopup(!isShowPopup)}
                        >
                            {t("login")}
                        </button>
                        {isShowPopup && (
                            <div className="landing-header__login-inner">
                                <Link to="/psych/login" className="landing-header__link">
                                    {t("link_psych_login")}
                                </Link>
                                <Link to="/pupil/login" className="landing-header__link">
                                    {t("link_pupil_login")}
                                </Link>
                                <a href={CONSTRUCTOR_LINK} className="landing-header__link">
                                    {t("link_constructor")}
                                </a>
                            </div>
                        )}
                    </div>
                    <button
                        onClick={showPopup}
                        className="landing-main__btn landing-main__btn_blue"
                    >
                        {t("start_free")}
                    </button>
                </div>
            )}
        </header>
    );
});
