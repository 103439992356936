import fightBackAgainstSadness from "static/docs/guidelines/useful-literature/Даём_отпор_грусти_и_депрессии_РТ.pdf";
import healthyBody from "static/docs/guidelines/useful-literature/Здоровый_образ_тела_в_мире_одержимом_внешностью_Рабочая_тетрадь.pdf";
import kinestheticGames from "static/docs/guidelines/useful-literature/Кинезиологические_и_нейропсихологические_игры_и_упражнения.pdf";
import practicePsychologicalTherapy from "static/docs/guidelines/useful-literature/Практическая_когнитивно_поведенческая_терапия.pdf";

export const usefulLiterature = {
    files: [
        {
            title: "Даём отпор грусти и депрессии РТ",
            file: fightBackAgainstSadness,
            fileName: "Даём_отпор_грусти_и_депрессии_РТ.pdf",
        },
        {
            title: "Здоровый образ тела в мире содержимом внешностью",
            file: healthyBody,
            fileName: "Здоровый_образ_тела_в_мире_одержимом_внешностью_Рабочая_тетрадь.pdf",
        },
        {
            title: "Кинезиологические и нейропсихологические игры и упражнения",
            file: kinestheticGames,
            fileName: "Кинезиологические_и_нейропсихологические_игры_и_упражнения.pdf",
        },
        {
            title: "Практическая когнитивно поведенческая терапия",
            file: practicePsychologicalTherapy,
            fileName: "Практическая_когнитивно_поведенческая_терапия.pdf",
        },
    ],
};
