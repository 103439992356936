import React, { memo, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IoClose } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import axios from "axios";

import { CHANGE_SHOW_TG_CONNECT, UPDATE_USER } from "redux/types";
import { selectUser } from "redux/authReducer";

export const ModalTelegramConnect = memo(() => {
    const dispatch = useDispatch();
    const { role, user } = useSelector(selectUser);
    const { t } = useTranslation("profile");
    const [step, setStep] = useState(1);

    const closeTgConnectMessageHandler = () => {
        dispatch({ type: CHANGE_SHOW_TG_CONNECT, payload: false });
    };

    const updateShowingMessage = async () => {
        try {
            await axios.patch(`${role}/update`, { isShowTgConnect: false });

            dispatch({ type: UPDATE_USER, payload: { ...user, isShowTgConnect: false } });
            dispatch({ type: CHANGE_SHOW_TG_CONNECT, payload: false });
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div className="modal-messages tg-connect">
            <div className="page popup-tg" style={{ paddingTop: 40 }}>
                <div className="popup-tg-close" onClick={closeTgConnectMessageHandler}>
                    <IoClose />
                </div>
                {step === 1 ? (
                    <>
                        <h3 style={{ margin: 0, textAlign: "center" }}>
                            {role === "psych"
                                ? t("modal_tg_connect_title_psych")
                                : t("modal_tg_connect_title_pupil")}
                        </h3>

                        <div className="modal-messages__buttons" style={{ marginTop: 24 }}>
                            <button onClick={() => setStep(2)}>
                                {t("modal_tg_connect_why_btn")}
                            </button>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="modal-messages__buttons">
                            <Link to="/settings?section=personal&key=telegram">
                                {t("modal_tg_connect_btn")}
                            </Link>
                        </div>

                        <p>
                            {role === "psych"
                                ? t("modal_tg_connect_text_psych")
                                : t("modal_tg_connect_text_pupil")}
                        </p>

                        <div className="dont-show">
                            <button onClick={updateShowingMessage}>
                                {t("modal_tg_dont_show_btn")}
                            </button>
                        </div>

                        {/*<p style={{ marginBottom: 24 }}>*/}
                        {/*    {role === "psych"*/}
                        {/*        ? t("modal_tg_connect_text_psych")*/}
                        {/*        : t("modal_tg_connect_text_pupil")}*/}
                        {/*</p>*/}

                        {/*<div className="modal-messages__buttons">*/}
                        {/*    <button onClick={updateShowingMessage}>*/}
                        {/*        {t("modal_tg_dont_show_btn")}*/}
                        {/*    </button>*/}
                        {/*    <Link to="/settings?section=personal&key=telegram">*/}
                        {/*        {t("modal_tg_connect_btn")}*/}
                        {/*    </Link>*/}
                        {/*</div>*/}
                    </>
                )}
            </div>
        </div>
    );
});
