import React from "react";
import { useTranslation } from "react-i18next";

import { MethodsListItem } from "components/methods/MethodsListItem";
import "static/scss/problemsPage.scss";
import search from "static/img/ic-search.svg";

export const SuitableMethodsList = ({
    debouncedValue,
    filteredConditions,
    searchField,
    searchHandler,
    showInfoHandler,
    sendTestHandler,
    isShowTitle = true,
}) => {
    const { t, i18n } = useTranslation("methods");

    return (
        <div className="workWithMethods-page__wrapper">
            {isShowTitle && <h4 className="problem-page__h4">{t("methods_for_your")}:</h4>}

            <div className="school-page__search" style={{ marginBottom: 0 }}>
                <img src={search} alt="" />
                <input
                    onChange={searchHandler}
                    value={searchField}
                    placeholder={t("search_placeholder")}
                />
            </div>

            {debouncedValue && !filteredConditions.length && (
                <p className="problem-page__not-found">
                    {t("suitable_methods_title")} {t("select_not_found_title")}
                </p>
            )}

            <div>
                {filteredConditions.map((condition) => (
                    <MethodsListItem
                        condition={condition}
                        showInfo={showInfoHandler}
                        sendTest={sendTestHandler}
                        language={i18n.language}
                        t={t}
                        key={condition._id}
                    />
                ))}
            </div>
        </div>
    );
};
