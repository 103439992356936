import React from "react";

import { TestButton } from "./TestButton";

export const FreeFormAnswers = ({
    freeFormValue,
    confirmBtnHandler,
    changeFreeFormAnswer,
    isConfirmPossible,
    isBackBtnActive,
    backBtnHandler,
}) => {
    return (
        <>
            <div className="answers">
                <div className="answers__item-free">
                    <input
                        type="text"
                        value={freeFormValue}
                        onChange={changeFreeFormAnswer}
                        placeholder="Введите свой ответ"
                        autoFocus
                    />
                </div>
            </div>
            <div className="answers-wrapper__btns-block">
                <TestButton
                    isActive={isBackBtnActive}
                    type="back"
                    onClickHandler={backBtnHandler}
                />
                <TestButton isActive={isConfirmPossible} onClickHandler={confirmBtnHandler} />
            </div>
        </>
    );
};
