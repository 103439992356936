import React, { useEffect, useState } from "react";

import { useDebounce } from "hooks/useDebounce";
import { declinations } from "helpers/declinations";
import { Layout } from "components/Layout";
import search from "static/img/ic-search.svg";

export const TestAppointment = ({
    conditions,
    isCreateHandler,
    createTestHandler,
    createTestInfoHandler,
    t,
    language,
}) => {
    const [filteredConditions, setFilteredConditions] = useState([]);
    const [searchField, setSearchField] = useState("");
    const debouncedValue = useDebounce(searchField, 500);

    useEffect(() => {
        if (conditions.length) {
            if (debouncedValue.trim()) {
                const regex = new RegExp(debouncedValue, "i");
                const filter = conditions.filter(
                    ({ name, methodAuthor }) => regex.test(name) || regex.test(methodAuthor)
                );

                setFilteredConditions(filter);
            } else {
                setFilteredConditions(conditions);
            }
        }
    }, [conditions, debouncedValue]);

    const searchHandler = (e) => {
        setSearchField(e.target.value);
    };

    return (
        <Layout>
            <div className="page create-test">
                <header className="page__header">
                    <button
                        className="icon-btn page__icon-btn page__icon-btn_left icon-btn_back"
                        onClick={isCreateHandler}
                    />
                    <p className="page__title">{t("send_test")}</p>
                </header>

                <div className="page__content">
                    <h3>{t("select_methodology")}</h3>

                    <div className="school-page__search" style={{ marginBottom: 0 }}>
                        <img src={search} alt="" />
                        <input
                            onChange={searchHandler}
                            value={searchField}
                            placeholder={t("search_placeholder")}
                        />
                    </div>

                    {debouncedValue && !filteredConditions.length && (
                        <p className="problem-page__not-found">
                            {t("suitable_methods_title")} {t("select_not_found_title")}
                        </p>
                    )}

                    <ul className="list">
                        {filteredConditions.map((condition, index) => (
                            <li
                                id={condition._id}
                                key={condition._id}
                                className="list__item"
                                onClick={createTestHandler}
                            >
                                <button
                                    id={index}
                                    className="list__info-btn"
                                    onClick={createTestInfoHandler}
                                />
                                <p>{condition.name}</p>
                                <h5 className="list__item-count list__item-count-margin">
                                    {condition.methodAuthor}
                                </h5>
                                <h5 className="list__item-count">
                                    {condition.body.questions.length}{" "}
                                    {t("questions_in_methodology")}
                                </h5>
                                <h5 className="list__item-count">
                                    {condition.scales.length}{" "}
                                    {declinations(condition.scales.length, "scale", language)}
                                </h5>

                                {condition.message && (
                                    <p
                                        className="list__desc"
                                        dangerouslySetInnerHTML={{
                                            __html: condition.message.slice(0, 64) + " ...",
                                        }}
                                    />
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </Layout>
    );
};
