import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { saveLastLogin } from "redux/actions/setUserActions";
import { selectUser } from "redux/authReducer";
import { AppRouter } from "app/AppRouter";
import { DownloadNotice } from "components/modals";

function App() {
    const { user } = useSelector(selectUser);
    const { downloadFile } = useSelector((state) => state.app);

    useEffect(() => {
        if (user) {
            (async () => saveLastLogin())();
        }
    }, [user]);

    return (
        <>
            <AppRouter />

            {downloadFile && <DownloadNotice />}
        </>
    );
}

export default App;
