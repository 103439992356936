import { GET_CONDITIONS_DIRECTIONS, GET_FAVORITE_METHODS, GET_METHODS } from "./types";

const initialState = {
    methods: null,
    favoriteMethods: null,
    conditionsDirections: null,
};

export const methodReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_METHODS:
            return { ...state, methods: action.payload };
        case GET_FAVORITE_METHODS:
            return { ...state, favoriteMethods: action.payload };
        case GET_CONDITIONS_DIRECTIONS:
            return { ...state, conditionsDirections: action.payload };
        default:
            return state;
    }
};

export const selectMethod = (state) => state.method;
