import axios from "axios";

import { finishLoading, showAlert, startLoading } from "../actions/setAppActions";
import { GET_CONDITIONS_DIRECTIONS, GET_FAVORITE_METHODS, GET_METHODS } from "../types";

export const fetchMethods = () => async (dispatch) => {
    try {
        dispatch(startLoading());
        const response = await axios.get("/methods");

        dispatch({ type: GET_METHODS, payload: response.data });
        dispatch(finishLoading());
    } catch (e) {
        dispatch(finishLoading());
        dispatch(showAlert({ type: "error", text: e.message }));
    }
};

export const fetchConditionsDirections = () => async (dispatch) => {
    try {
        dispatch(startLoading());
        const response = await axios.get("/conditions/directions");

        dispatch({ type: GET_CONDITIONS_DIRECTIONS, payload: response.data });
        dispatch(finishLoading());
    } catch (e) {
        dispatch(finishLoading());
        dispatch(showAlert({ type: "error", text: e.message }));
    }
};

export const getMethodsIfNeeded = () => (dispatch, getState) => {
    const state = getState().method;

    if (!state.methods) {
        return dispatch(fetchMethods());
    }
};

export const getConditionsDirectionsIfNeeded = () => (dispatch, getState) => {
    const state = getState().method;

    if (!state.conditionsDirections) {
        return dispatch(fetchConditionsDirections());
    }
};

export const fetchFavoriteMethods = () => async (dispatch) => {
    try {
        dispatch(startLoading());
        const response = await axios.get("/methods/favorite");

        dispatch({ type: GET_FAVORITE_METHODS, payload: response.data });
        dispatch(finishLoading());
    } catch (e) {
        dispatch(finishLoading());
        dispatch(showAlert({ type: "error", text: e.message }));
    }
};

export const getFavoriteMethodsIfNeeded = () => (dispatch, getState) => {
    const state = getState().method;

    if (!state.favoriteMethods) {
        return dispatch(fetchFavoriteMethods());
    }
};

export const updateFavoriteMethods = (methods) => async (dispatch) => {
    try {
        const response = await axios.put("/methods/favorite", { methods });

        dispatch({ type: GET_FAVORITE_METHODS, payload: response.data });
    } catch (e) {
        dispatch(showAlert({ type: "error", text: e.message }));
    }
};
