import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useCreateReport } from "hooks/useCreateReport";
import { BackButton } from "components/BackButton";
import { Layout } from "components/Layout";
import { StatisticClassesList } from "components/statistic/StatisticClassesList";
import { classNames } from "helpers/classNames";
import { StatisticClassesSelect } from "components/statistic/StatisticClassesSelect";
import { StatisticConditionsSelect } from "components/statistic/StatisticConditionsSelect";

/**
 * @api {get} /statistic/schools/:id статистика классов школ
 * @apiGroup statistic
 * @apiName статистика классов школ
 *
 * @apiParam {String} id ID школы
 *
 * @apiDescription <p>Страница статистики классов выбранной школы</p>
 * <p>Компонент <code>StatisticClasses.js</code> <a href="../client/src/pages/statistic/StatisticClasses.js">ссылка на файл</a></p>
 */

export const StatisticClasses = () => {
    const { t, i18n } = useTranslation("statistic");
    const schoolId = useParams().id;
    const [school, setSchool] = useState(null);
    const [classes, setClasses] = useState([]);
    const { createReport, isReportLoading } = useCreateReport();
    const [multiClassStep, setMultiClassStep] = useState(null);
    const [chosenClasses, setChosenClasses] = useState([]);
    const [chosenConditions, setChosenConditions] = useState([]);

    useEffect(() => {
        if (schoolId) {
            (async () => {
                const { data } = await axios.get(`statistic/schools/${schoolId}`);
                setClasses(data.classes);
                setSchool(data.school);
            })();
        }
    }, [schoolId]);

    const createReportHandler = () => {
        if (multiClassStep) return;
        createReport(`reports/school/${schoolId}`, `${t("summary_statistics_pupils")}.xlsx`);
    };

    const handleSetIsMultiClass = () => {
        if (multiClassStep) {
            setChosenClasses([]);
            setChosenConditions([]);
            setMultiClassStep(null);
        } else {
            setMultiClassStep("classes");
        }
    };

    const handleSetChosenClasses = (classes) => {
        setChosenClasses(classes);
    };

    return (
        <Layout>
            <div className="page full-header">
                <header className="page__header full-header">
                    <BackButton />
                    <p className="page__title">{school || t("statistic")}</p>
                </header>

                {!!classes.length && (
                    <>
                        <div
                            className="statistic-reports__btn statistic-reports__btn-class"
                            style={{ marginBottom: 16 }}
                            onClick={createReportHandler}
                        >
                            {isReportLoading ? (
                                <p>{t("report_loading", { ns: "translation" })}</p>
                            ) : (
                                <p>{t("create_summary_statistics_pupils")}</p>
                            )}
                        </div>

                        <div
                            className={classNames(
                                "statistic-reports__btn statistic-reports__btn-class",
                                {
                                    active: multiClassStep,
                                }
                            )}
                            onClick={handleSetIsMultiClass}
                        >
                            <p>
                                {multiClassStep
                                    ? t("cancel_multi_class_report")
                                    : t("create_multi_class_report")}
                            </p>
                        </div>
                    </>
                )}

                {!multiClassStep && <StatisticClassesList classes={classes} i18n={i18n} />}

                {multiClassStep === "classes" && (
                    <StatisticClassesSelect
                        classes={classes}
                        chosenClasses={chosenClasses}
                        setChosenClasses={handleSetChosenClasses}
                        setMultiClassStep={setMultiClassStep}
                        t={t}
                        i18n={i18n}
                    />
                )}

                {multiClassStep === "conditions" && (
                    <StatisticConditionsSelect
                        chosenClasses={chosenClasses}
                        chosenConditions={chosenConditions}
                        setChosenConditions={setChosenConditions}
                        t={t}
                    />
                )}
            </div>
        </Layout>
    );
};
