import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useDispatch } from "react-redux";
import { IoClose } from "react-icons/io5";

import { classNames } from "helpers/classNames";
import { showDownloadFileNotice } from "redux/actions/setAppActions";
import styles from "./DownloadNotice.module.scss";

export const DownloadNotice = () => {
    const dispatch = useDispatch();
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        setIsActive(true);
    }, []);

    const closePopup = () => {
        setIsActive(false);

        setTimeout(() => {
            dispatch(showDownloadFileNotice(false));
        }, 350);
    };

    return createPortal(
        <div
            className={classNames(styles.root, {
                [styles.active]: isActive,
            })}
        >
            <div className={styles.inner}>
                <button className={styles.close} onClick={closePopup}>
                    <IoClose />
                </button>

                <h5 className={styles.title}>Уведомление</h5>

                <p className={styles.text}>Документ был скачан, пожалуйста, проверьте загрузки</p>
            </div>
        </div>,
        document.getElementById("root")
    );
};
