import fetch from "../fetch";
import { startLoading, finishLoading, showAlert } from "../actions/setAppActions";
import { CREATE_NOTE, GET_NOTES } from "../types";
import i18n from "i18n.js";

export function createNote(data) {
    return async (dispatch, getState) => {
        try {
            dispatch(startLoading());

            const json = await fetch(
                "/api/notes/create",
                {
                    method: "POST",
                    body: JSON.stringify({ ...data }),
                    headers: { "Content-Type": "application/json" },
                },
                getState,
                dispatch
            );

            dispatch({ type: CREATE_NOTE, payload: json });
            dispatch(finishLoading());
            dispatch(showAlert({ type: "success", text: i18n.t("note_created", { ns: "notes" }) }));
        } catch (e) {
            dispatch(finishLoading());
            dispatch(showAlert({ type: "error", text: e.message }));
        }
    };
}

export function getNotes(pupilId) {
    return async (dispatch, getState) => {
        try {
            dispatch(startLoading());
            const json = await fetch(
                `/api/notes${pupilId ? `?pupilId=${pupilId}` : ""}`,
                { method: "GET" },
                getState,
                dispatch
            );

            dispatch({ type: GET_NOTES, payload: json });
            dispatch(finishLoading());
        } catch (e) {
            dispatch(finishLoading());
            dispatch(showAlert({ type: "error", text: e.message }));
        }
    };
}
