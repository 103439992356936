import moment from "moment";

export function groupByDate(arr) {
    return arr.reduce((acc, item) => {
        const date = moment(item.date).format("YYYY-MM-DD");

        const group = acc.find((g) => g.date === date);

        if (group) {
            group.items.push(item);
        } else {
            acc.push({
                date: date,
                items: [item],
            });
        }

        return acc;
    }, []);
}
