import React from "react";
import axios from "axios";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { IconContext } from "react-icons";

import reportWebVitals from "./reportWebVitals";
import { rootReducer } from "redux/rootReducer";
import { API_URL } from "config.js";
import "./static/scss/index.scss";
import App from "./App";
import "./i18n";

axios.defaults.baseURL = API_URL;
axios.defaults.withCredentials = true;

const store = createStore(
    rootReducer,
    compose(
        applyMiddleware(thunk)
        // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    )
);

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            retry: false,
        },
    },
});

if ("serviceWorker" in navigator) {
    navigator.serviceWorker
        .register("/service-worker.js")
        .then(async (registration) => {
            // console.log("Service Worker зарегистрирован с областью видимости:", registration.scope);

            await registration.update();
            //
            // if (caches) {
            //     caches.keys().then(async (names) => {
            //         await Promise.all(names.map((name) => caches.delete(name)));
            //     });
            // }
        })
        .catch((error) => {
            console.error("Ошибка при регистрации Service Worker:", error);
        });
}

const app = (
    <React.StrictMode>
        <Provider store={store}>
            <QueryClientProvider client={queryClient}>
                <IconContext.Provider value={{ className: "reactIcon" }}>
                    <App />
                </IconContext.Provider>
            </QueryClientProvider>
        </Provider>
    </React.StrictMode>
);

render(app, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
