import moment from "moment";

export const getFormDataFromDnevnikru = (data, role) => {
    const { personId_str, accessToken, shortName, login, email, sex, birthday } = data;
    const formData = {};

    personId_str && (formData.dnevnikruPersonId = personId_str);
    accessToken && (formData.dnevnikruAccessToken = accessToken);
    shortName && (formData.name = shortName.split(" ")[0]);
    birthday && (formData.birthday = moment(birthday).format("DD.MM.YYYY"));

    if (role === "psych") {
        login && (formData.login = login);
        email && (formData.email = email);
    } else if (role === "pupil") {
        login && (formData.email = login);
    }

    if (sex && sex === "Female") {
        formData.sex = "2";
    }

    return Object.keys(formData).length ? formData : null;
};
