import React, { useState } from "react";
import { Link } from "react-router-dom";

export const PupilTools = ({ tools, btnText }) => {
    const [isActive, setIsActive] = useState(false);

    const actionHandler = (callback) => {
        callback();
        setIsActive(false);
    };

    return (
        <>
            {isActive && (
                <div className="page tools tools__pupil">
                    <button onClick={() => setIsActive(!isActive)} className="tools__close-btn" />
                    <div className="tools__items tools__items-pupil">
                        {tools &&
                            tools.map((tool, index) => {
                                return tool.url ? (
                                    <Link key={index} to={tool.url} className="tools__item">
                                        {tool.title}
                                    </Link>
                                ) : (
                                    <button
                                        key={index}
                                        onClick={() => actionHandler(tool.action)}
                                        className="tools__item"
                                    >
                                        {tool.title}
                                    </button>
                                );
                            })}
                    </div>
                </div>
            )}

            <div className="tools__pupil_open-btn-wrapper">
                <button onClick={() => setIsActive(!isActive)} className="tools__pupil_open-btn">
                    {btnText}
                </button>
            </div>
        </>
    );
};
