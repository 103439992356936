import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import axios from "axios";

import { Loader } from "components/Loader";
import { showAlert } from "redux/actions/setAppActions";
import { PsychPhoneVerificationTimer } from "./PsychPhoneVerificationTimer";
import { RegisterPsychPhoneNoCall } from "./RegisterPsychPhoneNoCall";
import styles from "./PsychPhoneVerification.module.css";

export const RegisterPsychPhoneVerification = ({ setPhoneVerification, phone }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation("registration");
    const [input, setInput] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [isRepeatConfirmation, setIsRepeatConfirmation] = useState(false);
    const [verificationKey, setVerificationKey] = useState(null);
    const [isTimer, setIsTimer] = useState(true);
    const [isNoCall, setIsNoCall] = useState(false);

    const sendPhoneConfirm = async () => {
        setIsLoading(true);

        try {
            const { data } = await axios.post("/phone/confirm", {
                phone: phone.replace(/[^0-9]/g, ""),
            });

            setVerificationKey(data.key);
            setIsRepeatConfirmation(false);
            setIsLoading(false);
        } catch (err) {
            const text = err.response?.data?.message || t("phone_confirm_error");

            setIsRepeatConfirmation(true);
            dispatch(showAlert({ type: "error", text }));
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (!verificationKey && phone) {
            sendPhoneConfirm();
        }
    }, [verificationKey, phone, dispatch, t]); // eslint-disable-line react-hooks/exhaustive-deps

    const changeInputHandler = (e) => {
        const { value } = e.target;

        if (value.length === 0 || (/^\d+$/.test(value) && value.length < 5)) {
            setInput(value);
        }
    };

    const confirmHandler = () => {
        if (verificationKey) {
            (async () => {
                setIsLoading(true);

                try {
                    const { data } = await axios.post("/phone/check", {
                        key: verificationKey,
                        pin: input,
                    });

                    if (data.success) {
                        setPhoneVerification();
                    } else {
                        setInput("");
                        setIsLoading(false);
                        dispatch(
                            showAlert({
                                type: "error",
                                text: t("phone_confirm_incorrect_pin"),
                            })
                        );
                    }
                } catch (err) {
                    const text = err.response?.data?.message || t("phone_confirm_incorrect_pin");

                    setInput("");
                    setIsLoading(false);
                    dispatch(showAlert({ type: "error", text }));
                }
            })();
        }
    };

    const handleHideTimer = () => {
        setIsTimer(false);
    };

    if (isNoCall) {
        return (
            <RegisterPsychPhoneNoCall setPhoneVerification={setPhoneVerification} phone={phone} />
        );
    }

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : isRepeatConfirmation ? (
                <div className="phone-verification__confirm-btn">
                    <button onClick={sendPhoneConfirm}>{t("phone_confirm_recall_btn")}</button>
                </div>
            ) : (
                <>
                    <div className="auth-page__register-header">
                        <h3>{t("enter_code_title")}</h3>

                        <p className="auth-page__register-text">{t("enter_code_text")}</p>
                    </div>

                    <div className="phone-verification">
                        <div className="phone-verification__input-field">
                            <input
                                type="text"
                                value={input}
                                onChange={changeInputHandler}
                                autoFocus
                            />
                        </div>
                    </div>

                    <div className="phone-verification__confirm-btn">
                        <button onClick={confirmHandler} disabled={input.length !== 4}>
                            {t("phone_confirm_btn")}
                        </button>

                        {isTimer ? (
                            <PsychPhoneVerificationTimer
                                hideTimer={handleHideTimer}
                                secondsNum={59}
                            />
                        ) : (
                            <div className={styles.noCallBtn}>
                                <button onClick={() => setIsNoCall(true)}>
                                    {t("phone_confirm_no_call_btn")}
                                </button>
                            </div>
                        )}
                    </div>
                </>
            )}
        </>
    );
};
