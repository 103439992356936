import featuresSuicidalBehavior from "static/docs/guidelines/Брошюра_Особенности_суицидального_поведения.pdf";
import preventingSuicide from "static/docs/guidelines/Буклет_Профилактика_суицидов_среди_подростков.docx";
import parentsLectureTeenSuicide from "static/docs/guidelines/Лекция_для_родителей_Подростковый_суицид.docx";
import parentsGuideSuicide from "static/docs/guidelines/Памятка_для_родителей_суицид.pdf";
import parentsPresentation from "static/docs/guidelines/Презентация_для_родителей.pptx";
import parentingGuide from "static/docs/guidelines/Родительский_всеобуч.pdf";
import parentsSuicidePresentation from "static/docs/guidelines/Суицид_през._для_родителей.pptx";
import parentsSuicideManual from "static/docs/guidelines/Суицид_Методичка_для_родителей.pdf";

export const suicideRisks = {
    files: [
        {
            title: "Брошюра. Особенности суицидального поведения",
            file: featuresSuicidalBehavior,
            fileName: "Брошюра_Особенности_суицидального_поведения.pdf",
        },
        {
            title: "Буклет. Профилактика суицидов среди подростков",
            file: preventingSuicide,
            fileName: "Буклет_Профилактика_суицидов_среди_подростков.docx",
        },
        {
            title: "Лекция для родителей. Подростковый суицид",
            file: parentsLectureTeenSuicide,
            fileName: "Лекция_для_родителей_Подростковый_суицид.docx",
        },
        {
            title: "Памятка для родителей. Суицид",
            file: parentsGuideSuicide,
            fileName: "Памятка_для_родителей_суицид.pdf",
        },
        {
            title: "Презентация для родителей",
            file: parentsPresentation,
            fileName: "Презентация_для_родителей.pptx",
        },
        {
            title: "Родительский всеобуч",
            file: parentingGuide,
            fileName: "Родительский_всеобуч.pdf",
        },
        {
            title: "Суицид. Презентация для родителей",
            file: parentsSuicidePresentation,
            fileName: "Суицид_през._для_родителей.pptx",
        },
        {
            title: "Суицид. Методичка для родителей",
            file: parentsSuicideManual,
            fileName: "Суицид_Методичка_для_родителей.pdf",
        },
    ],
};
