import i18n from "i18n.js";

const i18nOptions = { ns: "statistic" };

export const getChosenFilters = (filters, lang = "ru") => {
    const filtersArr = [];
    const filterFields = {
        sex: i18n.t("filter_gender", i18nOptions),
        years: i18n.t("filter_age", i18nOptions),
        category: i18n.t("filter_risk_groups", i18nOptions),
    };

    if (filters.sex) {
        const sex =
            filters.sex === "1"
                ? i18n.t("filter_male", i18nOptions)
                : i18n.t("filter_female", i18nOptions);
        filtersArr.push(`${filterFields.sex}: ${sex}`);
    }

    if (filters.years) {
        filtersArr.push(`${filterFields.years}: ${filters.years}`);
    }

    if (filters.category) {
        filtersArr.push(`${filterFields.category}: ${filters.categoryName}`);
    }

    return filtersArr.length ? filtersArr.join(", ") : "";
};

export const getChosenConditions = (condition, pupilsList) => {
    if (condition) {
        return condition;
    }

    const uniqConditions = [];

    for (const pupil of pupilsList) {
        if (pupil.conditions) {
            for (const condition of pupil.conditions) {
                if (!uniqConditions.includes(condition)) {
                    uniqConditions.push(condition);
                }
            }
        }
    }

    return uniqConditions.join(",");
};
