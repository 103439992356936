import { useQuery } from "@tanstack/react-query";

import { fetchPupilsWithConditionsByClasses } from "api/pupils/fetchPupilsWithConditionsByClasses";

/**
 * @param {string[]} classes - array of string
 */
export function useFetchPupilsWithConditionsByClasses(classes) {
    const { data, error, isLoading } = useQuery({
        queryKey: ["classes/pupils-with-conditions", classes],
        queryFn: () => fetchPupilsWithConditionsByClasses(classes),
    });

    return {
        pupilsWithConditions: data,
        pupilsWithConditionsError: error,
        pupilsWithConditionsIsLoading: isLoading,
    };
}
