import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { selectMethod } from "redux/methodReducer";
import { getFavoriteMethodsIfNeeded, getMethodsIfNeeded } from "redux/actions/setMethodActions";
import { Loader } from "components/Loader";
import { WorkWithMethodsList } from "components/methods/WorkWithMethodsList";
import { Layout } from "components/Layout";
import { BackButton } from "components/BackButton";
import { MethodsByAge } from "components/methods/MethodsByAge";
import { MethodsByDirection } from "components/methods/MethodsByDirection";
import { MethodsGeneral } from "components/methods/MethodsGeneral";
import { MethodsSuicidalRisks } from "components/methods/MethodsSuicidalRisks";
import { MethodsMy } from "components/methods/MethodsMy";
import { MethodsFavorite } from "components/methods/MethodsFavorite";
import "static/scss/problemsPage.scss";

/**
 * @api {get} /workWithMethods/:slug список методик по выбранной категории
 * @apiGroup methods
 * @apiName список методик по выбранной категории
 *
 * @apiParam {String} slug  категория
 *
 * @apiDescription <p>Страница со списоком методик по выбранной категории</p>
 * <p>Компонент <code>WorkWithMethodsSlug.js</code> <a href="../client/src/pages/methods/WorkWithMethodsSlug.js">ссылка на файл</a></p>
 */

export const WorkWithMethodsSlug = () => {
    const dispatch = useDispatch();
    const { loading } = useSelector((state) => state.app);
    const { methods, favoriteMethods } = useSelector(selectMethod);
    const { slug } = useParams();
    const { t } = useTranslation("methods");

    useEffect(() => {
        dispatch(getMethodsIfNeeded());
        dispatch(getFavoriteMethodsIfNeeded());
    }, [dispatch]);

    if (loading) {
        return <Loader />;
    }

    if (slug === "by-age") {
        return <MethodsByAge />;
    }

    if (slug === "by-direction") {
        return <MethodsByDirection />;
    }

    if (slug === "general-list") {
        return <MethodsGeneral />;
    }

    if (slug === "my") {
        return <MethodsMy />;
    }

    if (slug === "favorite") {
        return <MethodsFavorite favoriteMethods={favoriteMethods} />;
    }

    if (slug === "suicidal-risks") {
        return <MethodsSuicidalRisks methods={methods} />;
    }

    return (
        <Layout>
            <div className="page full-header">
                <header className="page__header full-header">
                    <BackButton />
                    <p className="page__title">{t("teenage_problems")}</p>
                </header>

                {slug === "problems" && methods && (
                    <WorkWithMethodsList
                        methodsList={methods.filter(({ category }) => category === slug)}
                    />
                )}
            </div>
        </Layout>
    );
};
