import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

import { setUser } from "redux/actions/setUserActions";
import { showAlert } from "redux/actions/setAppActions";

export const usePwaPincode = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { t } = useTranslation(["login", "translation"]);
    const [isLoading, setIsLoading] = useState(false);

    const authUser = async (userId, role, pincode, path) => {
        try {
            setIsLoading(true);
            const { data } = await axios.post(`/${role}/login/${path}`, { userId, pincode });

            localStorage.setItem("pwaPinSaved", "true");
            dispatch(setUser(data));
            history.push("/dashboard");
        } catch (e) {
            if (e.response?.data?.message) {
                dispatch(showAlert({ type: "error", text: e.response.data.message }));
            } else {
                dispatch(
                    showAlert({
                        type: "error",
                        text: t("incorrect_data", { ns: "translation" }),
                    })
                );
            }
        } finally {
            setIsLoading(false);
        }
    };

    return { authUser, isLoading };
};
