import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment/moment";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

import { useCreateReport } from "hooks/useCreateReport";
import { Layout } from "components/Layout";
import { BackButton } from "components/BackButton";
import { Spinner } from "components/Spinner";
import "static/scss/managementReporting.scss";

/**
 * @api {get} /management_reporting управленческая отчётность
 * @apiGroup profile
 * @apiName управленческая отчётность
 *
 * @apiDescription <p>Страница для создания управленческой отчётности</p>
 * <p>Компонент <code>ManagementReportingPage.js</code> <a href="../client/src/pages/ManagementReportingPage.js">ссылка на файл</a></p>
 */

export const ManagementReportingPage = () => {
    const { createReport, isReportLoading } = useCreateReport();
    const { t } = useTranslation();
    const [dateFrom, setDateFrom] = useState(moment().subtract(1, "year").format("DD.MM.YYYY"));
    const [dateTo, setDateTo] = useState(moment().format("DD.MM.YYYY"));

    const createReportHandler = () => {
        const fromDate = moment(dateFrom, "DD.MM.YYYY").format("YYYY-MM-DD");
        const toDate = moment(dateTo, "DD.MM.YYYY").format("YYYY-MM-DD");

        createReport(
            `/reports/psych-management?dateRange=${fromDate}.${toDate}`,
            `${t("management_reporting")}_${fromDate}.${toDate}.docx`
        );
    };

    const changeDateFromHandler = (value) => {
        if (typeof value !== "string") {
            setDateFrom(moment(value).format("DD.MM.YYYY"));
        }
    };

    const changeDateToHandler = (value) => {
        if (typeof value !== "string") {
            setDateTo(moment(value).format("DD.MM.YYYY"));
        }
    };

    const isValidFromDate = (current) => !current.isAfter(moment(dateTo, "DD.MM.YYYY"));

    const isValidToDate = (current) => {
        if (moment(current).isBefore(moment(dateFrom, "DD.MM.YYYY"))) {
            return false;
        }
        return !current.isAfter(moment().subtract(0, "day"));
    };

    return (
        <Layout>
            <div className="page">
                <header className="page__header">
                    <BackButton />
                    <p className="page__title">{t("management_reporting")}</p>
                </header>

                <div className="list">
                    <h3 className="management-reporting__title">{t("select_date_range")}</h3>

                    <div className="management-reporting__dates">
                        <div className="management-reporting__date-block">
                            <Datetime
                                value={dateFrom}
                                onChange={changeDateFromHandler}
                                inputProps={{
                                    readOnly: true,
                                }}
                                isValidDate={isValidFromDate}
                                timeFormat={false}
                            />
                        </div>
                        <div>-</div>
                        <div className="management-reporting__date-block">
                            <Datetime
                                value={dateTo}
                                onChange={changeDateToHandler}
                                inputProps={{
                                    readOnly: true,
                                }}
                                isValidDate={isValidToDate}
                                timeFormat={false}
                            />
                        </div>
                    </div>

                    <div
                        onClick={createReportHandler}
                        className="statistic-reports__btn statistic-reports__btn-pupil-profile"
                        style={{ margin: "40px 0 0" }}
                    >
                        {isReportLoading ? (
                            <div className="spinner">
                                <Spinner />
                            </div>
                        ) : (
                            <p>{t("create_report")}</p>
                        )}
                    </div>

                    <p className="management-reporting__description">
                        {t("management_reporting_description")}
                    </p>
                </div>
            </div>
        </Layout>
    );
};
