import fetch from "../fetch";
import { startLoading, finishLoading, showAlert } from "../actions/setAppActions";
import { GET_PUPIL, GET_CATEGORIES } from "../types";

export function getPupil(id) {
    return async (dispatch, getState) => {
        try {
            dispatch(startLoading());
            const json = await fetch(`/api/pupil/${id}`, { method: "GET" }, getState, dispatch);

            dispatch({ type: GET_PUPIL, payload: json });
            dispatch(finishLoading());
        } catch (e) {
            dispatch(finishLoading());
            dispatch(showAlert({ type: "error", text: e.message }));
        }
    };
}

export function updatePupil(id, form) {
    return async (dispatch, getState) => {
        try {
            dispatch(startLoading());
            const json = await fetch(
                `/api/pupil/${id}`,
                {
                    method: "POST",
                    body: JSON.stringify({ ...form }),
                    headers: { "Content-Type": "application/json" },
                },
                getState,
                dispatch
            );

            console.log("updatePupil");

            dispatch({ type: GET_PUPIL, payload: json.pupil });
            dispatch(finishLoading());
            dispatch(showAlert({ type: "success", text: "Успешно" }));
        } catch (e) {
            dispatch(finishLoading());
            dispatch(showAlert({ type: "error", text: e.message }));
        }
    };
}

export function getCategories() {
    return async (dispatch, getState) => {
        try {
            dispatch(startLoading());
            const json = await fetch(`/api/categories`, { method: "GET" }, getState, dispatch);

            dispatch({ type: GET_CATEGORIES, payload: json.categories });
            dispatch(finishLoading());
        } catch (e) {
            dispatch(finishLoading());
            dispatch(showAlert({ type: "error", text: e.message }));
        }
    };
}
