import React, { memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import axios from "axios";

import { hideNotice, showAlert, showNotice } from "redux/actions/setAppActions";
import { validateEmail, validatePhone } from "helpers/validators";
import { LandingContactsStep1 } from "components/landing/LandingContactsStep1";
import { LandingContactsStep2 } from "components/landing/LandingContactsStep2";
import { LandingContactsStep3 } from "components/landing/LandingContactsStep3";
import { LandingContactsStep4 } from "components/landing/LandingContactsStep4";
import { Alert } from "../Alert";
import { Notice } from "../Notice";
import logo from "../../static/img/logo.svg";
import sk from "../../static/img/sk.png";
import policy from "../../static/docs/policy.pdf";
import exportIcon from "../../static/img/ic-export.svg";
import planet from "../../static/img/ic-planet.svg";
import userManual from "../../static/docs/user_manual.docx";
import terms from "../../static/docs/LSSR_terms_of_use.pdf";
import { LandingContactsPhoneVerification } from "components/landing/LandingContactsPhoneVerification";
import { checkIsMobile, checkPwa } from "helpers/devices";

const initForm = {
    name: "",
    surname: "",
    patronymic: "",
    phone: "",
    email: "",
    city: "",
    institutionType: "",
    institution: "",
    message: "",
};

export const LandingContacts = memo(({ showInstallPwaPopupHandler }) => {
    const { t } = useTranslation("landing");
    const dispatch = useDispatch();
    const { alert, notice } = useSelector((state) => state.app);
    const [form, setForm] = useState(initForm);
    const [step, setStep] = useState(1);
    const [firstCheckboxChecked, setFirstCheckboxChecked] = useState(false);
    const [secondCheckboxChecked, setSecondCheckboxChecked] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isSetPhoneConfirm, setIsSetPhoneConfirm] = useState(false);
    const [isPhoneConfirmed, setIsPhoneConfirmed] = useState(false);

    const changeHandler = (event) => {
        const { name, value } = event.target;
        setForm({ ...form, [name]: value });
    };

    const firstCheckboxHandler = () => {
        setFirstCheckboxChecked(!firstCheckboxChecked);
    };

    const secondCheckboxHandler = () => {
        setSecondCheckboxChecked(!secondCheckboxChecked);
    };

    const hideNoticeHandler = () => {
        dispatch(hideNotice());

        if (isSuccess) {
            setStep(1);
            setForm(initForm);
            setIsPhoneConfirmed(false);
            setFirstCheckboxChecked(false);
            setSecondCheckboxChecked(false);
            setIsSuccess(false);
        }
    };

    const sendForm = async () => {
        try {
            await axios.post("requests/create", { ...form });
            dispatch(showNotice(t("successful_request", { ns: "translation" })));
            setIsSuccess(true);
        } catch (err) {
            if (err.response?.data?.message) {
                dispatch(showNotice(err.response.data.message));
            } else {
                dispatch(showNotice(err.message));
            }
        }
    };

    const forwardHandler = async () => {
        if (step === 1 && form.name && form.surname) {
            setStep(step + 1);
        } else if (step === 2 && form.phone && form.email) {
            if (!validatePhone(form.phone)) {
                dispatch(
                    showAlert({
                        type: "error",
                        text: t("enter_correct_phone", { ns: "translation" }),
                    })
                );
            } else if (!validateEmail(form.email)) {
                dispatch(
                    showAlert({
                        type: "error",
                        text: t("enter_correct_email", { ns: "translation" }),
                    })
                );
            } else if (!isPhoneConfirmed) {
                dispatch(
                    showAlert({
                        type: "error",
                        text: t("need_to_verify_phone", { ns: "translation" }),
                    })
                );
            } else {
                setStep(step + 1);
            }
        } else if (step === 3 && form.city && form.institutionType && form.institution) {
            setStep(step + 1);
        } else if (step === 4 && form.message) {
            await sendForm(form);
        } else {
            dispatch(
                showAlert({
                    type: "error",
                    text: t("fill_all_fields", { ns: "translation" }),
                })
            );
        }
    };

    const setPhoneVerificationHandler = () => {
        setIsPhoneConfirmed(true);
        setIsSetPhoneConfirm(false);
    };

    return (
        <section className="landing-contacts" id="landing-contacts">
            {!!alert && <Alert text={alert.text} type={alert.type} />}
            {!!notice && <Notice text={notice.text} closeHandler={hideNoticeHandler} />}

            <h2 className="landing-contacts__title">{t("footer_title")}</h2>
            <div className="landing-contacts__content">
                <div className="landing-contacts__contacts">
                    <a
                        className="landing-contacts__contact landing-contacts__contact_email"
                        href="mailto:help@testu.online"
                    >
                        <div>
                            <p>help@testu.online</p>
                            <label>{t("footer_support")}</label>
                        </div>
                    </a>
                    <a
                        className="landing-contacts__contact landing-contacts__contact_email"
                        href="mailto:school@testu.onlin"
                    >
                        <div>
                            <p>school@testu.online</p>
                            <label>{t("footer_suggestions")}</label>
                        </div>
                    </a>
                    <a
                        className="landing-contacts__contact landing-contacts__contact_email"
                        href="mailto:ceo@testu.online"
                    >
                        <div>
                            <p>ceo@testu.online</p>
                            <label>{t("footer_partnerships")}</label>
                        </div>
                    </a>
                    <a
                        className="landing-contacts__contact landing-contacts__contact_tel"
                        href="tel:+74951207004"
                    >
                        {t("footer_phone")}
                    </a>
                    <a
                        className="landing-contacts__contact landing-contacts__contact_address"
                        href="https://yandex.ru/maps/213/moscow/house/bersenevskaya_naberezhnaya_6s3/Z04YcAdpS0MEQFtvfXt1cXpmYA==/?ll=37.609263%2C55.740654&z=17.23"
                    >
                        {t("footer_address_1")} <br /> {t("footer_address_2")}
                    </a>
                    <p className="landing-contacts__label">
                        {t("footer_about_1")} <br /> {t("footer_about_2")}
                    </p>
                    <div className="row">
                        <img className="landing-contacts__img" alt="" src={logo} />
                        <a href="https://www.sk.ru" target="_blank" rel="noreferrer">
                            <img className="landing-contacts__img2" alt="" src={sk} />
                        </a>
                    </div>
                </div>

                <div className="landing-contacts__form-wrapper">
                    <div className="landing-contacts__form">
                        {!isSetPhoneConfirm && (
                            <div className="landing-contacts__form-steps">
                                <span>
                                    {t("footer_form_step")} {step}
                                </span>
                                <span>{t("footer_form_out_of")} 4</span>
                            </div>
                        )}

                        {isSetPhoneConfirm ? (
                            <LandingContactsPhoneVerification
                                setPhoneVerification={setPhoneVerificationHandler}
                                phone={form.phone}
                                t={t}
                            />
                        ) : (
                            <>
                                {step === 1 && (
                                    <LandingContactsStep1
                                        form={form}
                                        changeHandler={changeHandler}
                                        t={t}
                                    />
                                )}

                                {step === 2 && (
                                    <LandingContactsStep2
                                        form={form}
                                        changeHandler={changeHandler}
                                        setPhoneConfirmFormHandler={() =>
                                            setIsSetPhoneConfirm(true)
                                        }
                                        isPhoneConfirmed={isPhoneConfirmed}
                                        t={t}
                                    />
                                )}

                                {step === 3 && (
                                    <LandingContactsStep3
                                        form={form}
                                        changeHandler={changeHandler}
                                        t={t}
                                    />
                                )}

                                {step === 4 && (
                                    <LandingContactsStep4
                                        form={form}
                                        changeHandler={changeHandler}
                                        firstCheckboxChecked={firstCheckboxChecked}
                                        firstCheckboxHandler={firstCheckboxHandler}
                                        secondCheckboxChecked={secondCheckboxChecked}
                                        secondCheckboxHandler={secondCheckboxHandler}
                                        t={t}
                                    />
                                )}
                            </>
                        )}

                        {!isSetPhoneConfirm && (
                            <>
                                <div className="landing-contacts__form-note">
                                    {t("footer_form_security")}
                                </div>
                                <div className="landing-contacts__form-btns">
                                    {step !== 1 && (
                                        <button
                                            onClick={() => setStep(step - 1)}
                                            className="landing-contacts__form-btn landing-contacts__form-btn_back"
                                        >
                                            {t("footer_form_button_back")}
                                        </button>
                                    )}

                                    {(step !== 4 ||
                                        (firstCheckboxChecked && secondCheckboxChecked)) && (
                                        <button
                                            onClick={forwardHandler}
                                            className="landing-contacts__form-btn landing-contacts__form-btn_forward"
                                        >
                                            {step === 4
                                                ? t("footer_form_button_send")
                                                : t("footer_form_button_next")}
                                        </button>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>

            <footer className="landing-contacts__footer">
                <div style={{ marginRight: "auto" }} className="landing-contacts__col">
                    <p className="landing-contacts__p">
                        {new Date().getFullYear()} {t("footer_copyright")}
                    </p>
                    <div className="landing-contacts__row landing-contacts__row1">
                        <img className="landing-contacts__img1" src={exportIcon} alt="" />
                        <p className="landing-contacts__p1">{t("footer_desc_1")}</p>
                    </div>
                    <div className="landing-contacts__row landing-contacts__row2">
                        <img className="landing-contacts__img3" src={planet} alt="" />
                        <p className="landing-contacts__p2">{t("footer_desc_2")}</p>
                    </div>
                </div>

                <div className="landing-contacts__col">
                    <div className="landing-contacts__row landing-contacts__row3">
                        <a href={terms} target="_blank" rel="noreferrer">
                            {t("footer_terms_of_use")}
                        </a>
                        <a href={userManual} target="_blank" rel="noreferrer">
                            {t("footer_user_manual")}
                        </a>
                        <a href={policy} target="_blank" rel="noreferrer">
                            {t("footer_personal_data_policy")}
                        </a>
                    </div>

                    <p className="landing-contacts__p4">
                        {t("footer_ask_us")}
                        <br />
                        <a href="mailto:ceo@testu.online">ceo@testu.online</a>
                    </p>
                </div>

                {checkIsMobile() && !checkPwa() && (
                    <div className="landing-contacts__col">
                        <p>
                            <button
                                onClick={showInstallPwaPopupHandler}
                                className="instruction-btn"
                            >
                                {t("application_instruction")}
                            </button>
                        </p>
                    </div>
                )}
            </footer>
        </section>
    );
});
