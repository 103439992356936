import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { BackButton } from "components/BackButton";
import { Layout } from "components/Layout";
import { useFetchPupilsWithCategories } from "hooks/useQuery";
import { RiskPupilsList } from "./risk-groups/RiskPupilsList";
import { RuskGroupsFilter } from "./risk-groups/RuskGroupsFilter";

/**
 * @api {get} /riskGroups группы риска
 * @apiGroup profile
 * @apiName группы риска
 *
 * @apiDescription <p>Страница групп риска</p>
 * <p>Компонент <code>RiskGroups.js</code> <a href="../client/src/pages/RiskGroups.js">ссылка на файл</a></p>
 */

export const RiskGroups = () => {
    const { t } = useTranslation();
    const { pupilsWithProfileCategories, pupilsWithTargetCategories, changeFiltersList } =
        useFetchPupilsWithCategories();

    const [isShowFilterPopup, setIsShowFilterPopup] = useState(false);

    const showFilterPopupHandler = () => {
        setIsShowFilterPopup(!isShowFilterPopup);
    };

    return (
        <Layout>
            <div className="page">
                <header className="page__header">
                    <BackButton />
                    <p className="page__title">{t("risk_groups")}</p>
                    <button
                        className="risk-groups__btn icon-btn page__icon-btn page__icon-btn_right icon-btn_filter icon-btn_filter_active"
                        onClick={showFilterPopupHandler}
                    >
                        {isShowFilterPopup && (
                            <RuskGroupsFilter changeFiltersList={changeFiltersList} />
                        )}
                    </button>
                </header>

                <div className="school-page__wrapper">
                    {!!pupilsWithTargetCategories.length && (
                        <div>
                            <h3>Целевые группы</h3>
                            <RiskPupilsList pupilsList={pupilsWithTargetCategories} />
                        </div>
                    )}
                    {!!pupilsWithProfileCategories.length && (
                        <div>
                            <h3>Профили</h3>
                            <RiskPupilsList pupilsList={pupilsWithProfileCategories} />
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    );
};
