import {
    START_LOADING,
    FINISH_LOADING,
    SHOW_ALERT,
    HIDE_ALERT,
    SHOW_NOTICE,
    HIDE_NOTICE,
    DOWNLOAD_FILE_NOTICE,
} from "./types";

const initialState = {
    loading: false,
    alert: null,
    notice: null,
    downloadFile: false,
};

export const appReducer = (state = initialState, action) => {
    switch (action.type) {
        case START_LOADING:
            return { ...state, loading: true };

        case FINISH_LOADING:
            return { ...state, loading: false };

        case SHOW_ALERT:
            return { ...state, alert: action.payload };

        case HIDE_ALERT:
            return { ...state, alert: null };

        case SHOW_NOTICE:
            return { ...state, notice: action.payload };

        case HIDE_NOTICE:
            return { ...state, notice: null };

        case DOWNLOAD_FILE_NOTICE:
            return { ...state, downloadFile: action.payload };

        default:
            return state;
    }
};
