import React, { memo, useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import { FiShare } from "react-icons/fi";
import Cookies from "js-cookie";

import { checkIosPwa, checkIsAndroid, checkIsIos } from "helpers/devices";

const isAndroid = checkIsAndroid();
const isIos = checkIsIos();

export const InstallPwaPopup = memo(({ closePopup }) => {
    const [isShowPopup, setIsShowPopup] = useState(false);
    const [promptInstall, setPromptInstall] = useState(null);
    const [isShowInstruction, setIsShowInstruction] = useState(false);

    useEffect(() => {
        if (isAndroid) {
            const handler = (e) => {
                e.preventDefault();

                setIsShowPopup(true);
                setPromptInstall(e);
            };

            window.addEventListener("beforeinstallprompt", handler);

            return () => window.removeEventListener("transitionend", handler);
        } else if (isIos && !checkIosPwa()) {
            setIsShowPopup(true);
        }
    }, []);

    const dontShowHandler = () => {
        Cookies.set("hidePwaInstallPopup", true, { expires: 365 });
        closePopup();
    };

    const installHandler = (e) => {
        e.preventDefault();

        if (!promptInstall) {
            return;
        }

        promptInstall.prompt();
        closePopup();
    };

    const showInstructionHandler = (e) => {
        e.preventDefault();
        setIsShowInstruction(true);
    };

    if (!isShowPopup) {
        return null;
    }

    return (
        <div className="pwa-install">
            <div className="pwa-install___inner">
                <div className="pwa-install__close" onClick={() => closePopup()}>
                    <IoClose />
                </div>
                <div className="pwa-install__content">
                    <h5>Установить приложение</h5>
                    <div className="pwa-install__buttons">
                        <button
                            onClick={(e) =>
                                isAndroid ? installHandler(e) : showInstructionHandler(e)
                            }
                            className="install"
                        >
                            Установить
                        </button>
                        <button onClick={dontShowHandler}>Больше не показывать</button>
                    </div>

                    {isShowInstruction && (
                        <div className="pwa-install__instruction">
                            <p className="pwa-install__instruction-desc">
                                Нажмите <FiShare /> <b>Поделиться</b>
                                <br /> в нижнем меню браузера <br /> и выберите{" "}
                                <b>На экран "Домой"</b>, <br /> затем - <b>Добавить</b>
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
});
