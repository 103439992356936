import fetch from '../fetch'
import { startLoading, finishLoading, showAlert } from '../actions/setAppActions'
import { GET_SCHOOLS, GET_SCHOOL, CREATE_SCHOOL, FINISH_SCHOOL_CREATING } from '../types'


export function getSchoolsINedded() {
    return (dispatch, getState) => {
        const schools = getState().school.schools

        if (!schools.length) {
            return dispatch(getSchools())
        }
    }
}

export function getSchools() {
    return async (dispatch, getState) => {
        try {
            dispatch(startLoading())
            const json = await fetch(`/api/schools/`, { method: 'GET' }, getState, dispatch)

            dispatch({ type: GET_SCHOOLS, payload: json })
            dispatch(finishLoading())

        } catch (e) {
            dispatch(finishLoading())
            dispatch(showAlert({ type: 'error', text: e.message }))
        }
    }
}

export function getSchoolINedded(id) {
    return (dispatch, getState) => {
        const state = getState().school

        if (!state.schoolData || state.schoolData['_id'] !== id) {
            return dispatch(getSchool(id))
        }
    }
}

export function getSchool(id) {
    return async (dispatch, getState) => {
        try {
            dispatch(startLoading())
            const json = await fetch(`/api/schools/${id}`, { method: 'GET' }, getState, dispatch)

            dispatch({ type: GET_SCHOOL, payload: json })
            dispatch(finishLoading())

        } catch (e) {
            dispatch(finishLoading())
            dispatch(showAlert({ type: 'error', text: e.message }))
        }
    }
}

export function createSchool(form) {
    return async (dispatch, getState) => {
        try {
            dispatch(startLoading())

            const json = await fetch('/api/schools/create', {
                method: 'POST',
                body: JSON.stringify({ ...form }),
                headers: { 'Content-Type': 'application/json', }
            }, getState, dispatch)

            dispatch({ type: CREATE_SCHOOL, payload: json.school })
            dispatch({ type: FINISH_SCHOOL_CREATING })
            dispatch(finishLoading())
            dispatch(showAlert({ type: 'success', text: 'Школа успешно создана' }))

        } catch (e) {
            dispatch(finishLoading())
            dispatch(showAlert({ type: 'error', text: e.message }))
        }
    }
}