import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import axios from "axios";

import { getPupil, updatePupil } from "redux/actions/setPupilActions";
import { Layout } from "components/Layout";
import { BackButton } from "components/BackButton";

/**
 * @api {get} /pupils/:id/categories группы риска ученика
 * @apiGroup pupils
 * @apiName группы риска ученика
 *
 * @apiParam {String} id ID ученика
 *
 * @apiDescription <p>Страница групп риска ученика</p>
 * <p>Компонент <code>CategoriesPage.js</code> <a href="../client/src/pages/CategoriesPage.js">ссылка на файл</a></p>
 */

const initialState = { categories: [] };

export const CategoriesPage = () => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const { t } = useTranslation();
    const pupilId = useParams().id;
    const [form, setForm] = useState(initialState);
    const [categories, setCategories] = useState([]);
    const { pupilData } = useSelector((state) => state.pupil);

    useEffect(() => {
        dispatch(getPupil(pupilId));
    }, [dispatch, pupilId]);

    useEffect(() => {
        (async () => {
            const { data } = await axios.get(`categories`);
            setCategories(data.categories);
        })();
    }, []);

    useEffect(() => {
        if (pupilData) {
            setForm({
                ...initialState,
                categories: pupilData.categories,
            });
        }
    }, [pupilData]);

    const checkboxHandler = (evt) => {
        const array = [...form.categories];
        const index = array.indexOf(evt.target.name);

        if (index > -1) {
            array.splice(index, 1);
        } else {
            array.push(evt.target.name);
        }
        setForm({ ...form, categories: array });
    };

    const submitHandler = async (e) => {
        e.preventDefault();
        await dispatch(updatePupil(pupilId, form));
        await queryClient.refetchQueries(["pupils/with-categories"]);
    };

    return (
        <Layout>
            <div className={`page`}>
                <header className="page__header">
                    <BackButton />
                    <p className="page__title">
                        {pupilData?.name} {pupilData?.surname}
                    </p>
                </header>
                <div className="page__content">
                    <h3>{t("risk_groups")}</h3>
                    <div className="list">
                        {categories.map((category) => (
                            <div key={category._id} className="list__checkbox">
                                <input
                                    type="checkbox"
                                    id={category._id}
                                    name={category._id}
                                    value={category._id}
                                    onChange={checkboxHandler}
                                    checked={form.categories.includes(category._id)}
                                />
                                <label htmlFor={category._id}>
                                    <div>
                                        <p>{category.title}</p>
                                    </div>
                                </label>
                            </div>
                        ))}
                    </div>
                    <div className="send-btn send-btn-static">
                        <button onClick={submitHandler} className="main-btn">
                            {t("save")}
                        </button>
                    </div>
                </div>
            </div>
        </Layout>
    );
};
