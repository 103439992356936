import diagnosticGuide from "static/docs/guidelines/diagnostic/Диагностический_комплект_методик_по_выявлению_буллинга.pdf";
import diagnosticGuideInput from "static/docs/guidelines/diagnostic/Входная_диагностика.pdf";
import diagnosticGuideParent from "static/docs/guidelines/diagnostic/Диагностика_профориентации.doc";
import diagnosticGuideMap from "static/docs/guidelines/diagnostic/Карта_наблюдения_за_состоянием_ребёнка_для_родителей.pdf";
import diagnosticGuideMethods from "static/docs/guidelines/diagnostic/Сборник_проективных_методик.pdf";
import diagnosticGuideTests from "static/docs/guidelines/diagnostic/Сборник_психодиагностических_тестов_для_подростков.docx";
import diagnosticGuideAnimal from "static/docs/guidelines/diagnostic/Тип_животного_в_методике.docx";

//diagnosticMaterialsCollections
import diagnosticGuideAudio from "static/docs/guidelines/diagnostic/Аудиовизуальная_психодиагностика.pdf";
import diagnosticGuideSchool from "static/docs/guidelines/diagnostic/Диагностика_на_ПМПК.doc";
import diagnosticGuideAge from "static/docs/guidelines/diagnostic/Диагностика_умственных_способностей_детей.doc";
import diagnosticGuideChild from "static/docs/guidelines/diagnostic/Наглядный_материал_для_обследования_детей.pdf";
import diagnosticGuidePsychological from "static/docs/guidelines/diagnostic/Психологическая_диагностика_в_школе.doc";
import diagnosticGuideExpress from "static/docs/guidelines/diagnostic/Экспресс_диагностика.doc";

// methodologyListsAndRegisters
import diagnosticGuideComplex from "static/docs/guidelines/diagnostic/Комплекс_диагностических_методик_для_заключения_на_ПМПК.doc";
import diagnosticGuideOpen from "static/docs/guidelines/diagnostic/Открытый_реестр_диагностических_методик.pdf";
import diagnosticGuideSchools from "static/docs/guidelines/diagnostic/Перечень_методик_для_обследования_школьника_по_ПМПК.pdf";

export const diagnostic = {
    folders: [
        {
            title: "Диагностические материалы",
            link: "/diagnostic-materials",
        },
        {
            title: "Списки и реестры методик",
            link: "/methodology-lists-and-registers",
        },
    ],
};

export const diagnosticMaterials = {
    folders: [
        {
            title: "Методики",
            link: "/diagnostic-materials-methods",
        },
        {
            title: "Сборники",
            link: "/diagnostic-materials-collections",
        },
    ],
    files: [
        {
            title: "Диагностический комплект методик по выявлению буллинга",
            file: diagnosticGuide,
            fileName: "Диагностический_комплект_методик_по_выявлению_буллинга.pdf",
        },
    ],
};

export const diagnosticMaterialsMethods = {
    files: [
        {
            title: "Входная диагностика",
            file: diagnosticGuideInput,
            fileName: "Входная_диагностика.pdf",
        },
        {
            title: "Диагностика профориентации",
            file: diagnosticGuideParent,
            fileName: "Диагностика_профориентации.doc",
        },
        {
            title: "Карта наблюдения за состоянием ребёнка для родителей",
            file: diagnosticGuideMap,
            fileName: "Карта_наблюдения_за_состоянием_ребёнка_для_родителей.pdf",
        },
        {
            title: "Сборник проективных методик",
            file: diagnosticGuideMethods,
            fileName: "Сборник_проективных_методик.pdf",
        },
        {
            title: "Сборник психодиагностических тестов для подростков",
            file: diagnosticGuideTests,
            fileName: "Сборник_психодиагностических_тестов_для_подростков.docx",
        },
        {
            title: "Тип животного в методике",
            file: diagnosticGuideAnimal,
            fileName: "Тип_животного_в_методике.docx",
        },
    ],
};

export const diagnosticMaterialsCollections = {
    files: [
        {
            title: "Аудиовизуальная психодиагностика",
            file: diagnosticGuideAudio,
            fileName: "Аудиовизуальная_психодиагностика.pdf",
        },
        {
            title: "Диагностика на ПМПК",
            file: diagnosticGuideSchool,
            fileName: "Диагностика_на_ПМПК.doc",
        },
        {
            title: "Диагностика умственных способностей детей",
            file: diagnosticGuideAge,
            fileName: "Диагностика_умственных_способностей_детей.doc",
        },
        {
            title: "Наглядный материал для обследования детей",
            file: diagnosticGuideChild,
            fileName: "Наглядный_материал_для_обследования_детей.pdf",
        },
        {
            title: "Психологическая диагностика в школе",
            file: diagnosticGuidePsychological,
            fileName: "Психологическая_диагностика_в_школе.doc",
        },
        {
            title: "Экспресс диагностика",
            file: diagnosticGuideExpress,
            fileName: "Экспресс_диагностика.doc",
        },
    ],
};

export const methodologyListsAndRegisters = {
    files: [
        {
            title: "Комплекс диагностических методик для заключения на ПМПК",
            file: diagnosticGuideComplex,
            fileName: "Комплекс_диагностических_методик_для_заключения_на_ПМПК.doc",
        },
        {
            title: "Открытый реестр диагностических методик",
            file: diagnosticGuideOpen,
            fileName: "Открытый_реестр_диагностических_методик.pdf",
        },
        {
            title: "Перечень методик для обследования школьника по ПМПК",
            file: diagnosticGuideSchools,
            fileName: "Перечень_методик_для_обследования_школьника_по_ПМПК.pdf",
        },
    ],
};
