export function getFileFormat(mimeType) {
    const formatMapping = {
        "image/png": "image",
        "image/jpeg": "image",
        "image/gif": "image",
        "application/pdf": "pdf",
        "application/msword": "doc",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "doc",
    };

    return formatMapping[mimeType] || "unknown";
}
