import React, { memo } from "react";

export const LandingForParents = memo(({ t }) => {
    return (
        <section className="landing-services" id="for-parents">
            <h2 className="landing-services__title">{t("for_parents")}</h2>
            <p className="landing-services__parents-text">
                Безусловно, родители главные люди для ребёнка, вы заботитесь воспитываете, учите и
                формируете личность.
            </p>
            <p className="landing-services__parents-text">
                В сознании ребёнка – именно он - центр мира. <br />
                Он слышит - когда сам готов слушать, воспринимает когда ему самому интересно.
            </p>
            <p className="landing-services__parents-text">
                Очень важно быть на связи с ребёнком, когда он нуждается в помощи, у него есть
                вопрос, он хочет знать ответ.
            </p>
            <p className="landing-services__parents-text">
                Самый критичный момент, когда ребёнку нужна помощь, а Вас рядом нет, Вас нельзя
                попросить, спросить, получить поддержку.
            </p>
            <p className="landing-services__parents-text">Задумайтесь, как часто Вас нет рядом?</p>
            <p className="landing-services__parents-text">
                Куда в эти моменты уходят запросы ребёнка?
            </p>
            <p className="landing-services__parents-text">
                Что происходит с ребёнком когда он не получает ответа?
            </p>
            <p className="landing-services__parents-text">
                Он его придумывает сам, иногда, эти придуманные им ответы приводят его в тупик,
                развиваются в тяжелые и непростые ситуации, формируют его изоляцию от мира. Почти
                половину времени ребёнок проводит в школе.
            </p>
            <p className="landing-services__parents-text">
                К кому он может обратиться в этот момент?
            </p>
            <p className="landing-services__parents-text">
                К учителю, если ему доверяет, другу, школьному психологу.
            </p>
            <p className="landing-services__parents-text">
                Если ответ не получен в момент запроса, сам вопрос (дефицит информации или внимания)
                никуда не исчезает, он превращается в кирпичик, из которого могут вырасти стены,
                которые закрывают ребёнка от Вас и изолируют его сознание.
            </p>
            <p className="landing-services__parents-text">
                Наша задача зафиксировать вопрос, потребность ребёнка в контакте со старшим
                товарищем и сделать так, чтобы пусть позже, но ребёнок всё-таки получил бы ответ на
                свой вопрос, устранил этот дефицит информации и внимания.
            </p>
            <p className="landing-services__parents-text">
                Ребёнок должен знать, что у своих родителей он все может спросить и всем поделиться.
                <br />А если по какой-то причине, именно в этот момент родителей нет рядом, он может
                обратиться к школьному психологу, в онлайн приложении психолог всегда рядом, просто
                нажми на кнопку, тебя обязательно услышат, ответят и помогут.
            </p>
            <p className="landing-services__parents-text">
                Сервис TestU.online дает родителям возможность посмотреть на своего ребёнка через
                призму психологической науки. Это дополнительная для Вас информация, полученная с
                помощью профессиональных психологов, и очень хорошо если вы не узнаете ничего
                нового.
            </p>
            <p className="landing-services__parents-text">
                Если же, вы всё-таки, сталкиваетесь с непонятными для себя определениями и
                формулировками - это повод поговорить со школьным психологом, получить
                дополнительную информацию, и возможно оказать поддержку вашему ребёнку не так как вы
                это делаете обычно.
            </p>
            <p className="landing-services__parents-text">
                У нас общая цель.
                <br />
                Мы хотим, чтоб дети избежали проблем, выросли здоровыми, самостоятельными и
                сохранили с родителями тёплые семейные отношения.
            </p>
            <p className="landing-services__parents-text">
                Наши исследования подтверждают что в помощи психолога нуждаются лишь 2-3% детей.
                Остальным помощь не нужна. Система это выявит. Если вашему ребёнку не понадобится
                квалифицированная помощь и внимание психолога, его никто не будет отвлекать. Наша
                задача помочь Психологам сконцентрироваться на работе только с детьми кому это
                действительно необходимо.
            </p>
        </section>
    );
});
