import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectMethod } from "redux/methodReducer";
import { declinations } from "helpers/declinations";
import { updateFavoriteMethods } from "redux/actions/setMethodActions";

export const MethodsListItem = ({ condition, language, t, sendTest, showInfo }) => {
    const dispatch = useDispatch();
    const { favoriteMethods } = useSelector(selectMethod);
    const [isFavorite, setIsFavorite] = useState(false);

    useEffect(() => {
        if (favoriteMethods?.length) {
            if (favoriteMethods.includes(condition._id)) {
                setIsFavorite(true);
            } else {
                setIsFavorite(false);
            }
        }
    }, [favoriteMethods, condition]);

    const sendTestHandler = () => {
        sendTest(condition);
    };

    const showInfoHandler = (e) => {
        showInfo(e, condition);
    };

    const handleFavoriteClick = (e) => {
        e.stopPropagation();
        let updatedFavorite;

        if (favoriteMethods.includes(condition._id)) {
            updatedFavorite = favoriteMethods.filter((id) => id !== condition._id);
        } else {
            updatedFavorite = [...favoriteMethods, condition._id];
        }

        dispatch(updateFavoriteMethods(updatedFavorite));
    };

    return (
        <div className="problem-page__card" onClick={sendTestHandler}>
            <div>
                <div className="problem-page__row">
                    <div className="problem-page__type problem-page__type_Test">Тест</div>
                </div>
                <h3 className="problem-page__h3">
                    {condition.name}
                    {condition.methodAuthor && <>, {condition.methodAuthor}</>}
                </h3>
                <h5 className="list__item-count list__item-count-margin">
                    {condition.body.questions.length} {t("questions_in_methodology")}
                </h5>
                <h5 className="list__item-count">
                    {condition.scales.length}{" "}
                    {declinations(condition.scales.length, "scale", language)}
                </h5>

                <h5 className="list__item-count">
                    {t("age", { ns: "translation" })}:{" "}
                    {condition.ages && (
                        <>
                            {condition.ages.min}-{condition.ages.max}{" "}
                            {declinations(condition.ages.max, "year", language)}
                        </>
                    )}
                </h5>
            </div>

            <button onClick={handleFavoriteClick} className="problem-page__card-favorite-btn">
                {isFavorite ? "❤️" : "🤍"}
            </button>

            <button className="tests-list__info-btn" onClick={showInfoHandler} />
        </div>
    );
};
