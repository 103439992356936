import React from "react";

import { useCreateReport } from "hooks/useCreateReport";
import { useFetchPupilsWithConditionsByClasses } from "hooks/useQuery/useFetchPupilsWithConditionsByClasses";
import { classNames } from "helpers/classNames";
import { Loader } from "components/Loader";
import { Spinner } from "components/Spinner";
import styles from "./StatisticClassesSelect.module.scss";

export const StatisticConditionsSelect = ({
    t,
    chosenClasses,
    chosenConditions,
    setChosenConditions,
}) => {
    const { createReport, isReportLoading } = useCreateReport();
    const { pupilsWithConditions, pupilsWithConditionsIsLoading, pupilsWithConditionsError } =
        useFetchPupilsWithConditionsByClasses(chosenClasses);

    const handleSetChosenConditions = (conditionId) => {
        if (chosenConditions.includes(conditionId)) {
            setChosenConditions(chosenConditions.filter((item) => item !== conditionId));
        } else {
            setChosenConditions([...chosenConditions, conditionId]);
        }
    };

    const handleCreateReport = () => {
        if (!pupilsWithConditions.pupils) return;

        const link = `statistic/psych-report?pupils=${pupilsWithConditions.pupils}&conditions=${chosenConditions}&date=&filters=`;
        createReport(link, `${t("create_multi_class_report")}.xlsx`);
    };

    return (
        <>
            <div>
                <h4 className={styles.title}>{t("choose_conditions_title")}</h4>

                <div className={styles.classList}>
                    {pupilsWithConditionsError && (
                        <p className="data-error">Ошибка загрузки данных</p>
                    )}

                    {!pupilsWithConditionsError &&
                        (pupilsWithConditionsIsLoading ? (
                            <Loader />
                        ) : (
                            <>
                                {!pupilsWithConditions ? (
                                    <p className="data-error">Ошибка загрузки данных</p>
                                ) : (
                                    pupilsWithConditions?.conditions?.map((item) => (
                                        <button
                                            onClick={() => handleSetChosenConditions(item._id)}
                                            key={item._id}
                                            className={classNames(
                                                [
                                                    "statistic-condition",
                                                    styles.conditionItem,
                                                    styles.classItem,
                                                ],
                                                {
                                                    [styles.active]: chosenConditions.includes(
                                                        item._id
                                                    ),
                                                }
                                            )}
                                        >
                                            {item.name}
                                        </button>
                                    ))
                                )}
                            </>
                        ))}
                </div>
            </div>

            <div className={styles.buttonWrapper}>
                <button
                    onClick={handleCreateReport}
                    disabled={chosenConditions.length === 0 || isReportLoading}
                    className="main-btn main-btn_green"
                >
                    {isReportLoading ? (
                        <div className="spinner">
                            <Spinner />
                        </div>
                    ) : (
                        <span>{t("create_report_btn")}</span>
                    )}
                </button>
            </div>
        </>
    );
};
