import React from "react";
import { IMaskInput } from "react-imask";

import { validatePhone } from "helpers/validators";
import checkIcon from "static/img/check-circle.svg";

export const LandingContactsStep2 = ({
    form,
    changeHandler,
    isPhoneConfirmed,
    setPhoneConfirmFormHandler,
    t,
}) => {
    return (
        <>
            <div className="landing-contacts__input-group">
                <div className="landing-contacts__input-field landing-contacts__phone-confirm">
                    <label htmlFor="phone">{t("footer_form_phone")}</label>
                    <IMaskInput
                        placeholder={t("footer_form_phone")}
                        id="phone"
                        type="text"
                        name="phone"
                        onChange={changeHandler}
                        value={form.phone}
                        mask="+{7}(000)000-00-00"
                        readOnly={isPhoneConfirmed}
                    />

                    {isPhoneConfirmed ? (
                        <div className="landing-contacts__phone-confirm-success-wrapper">
                            <div className="landing-contacts__phone-confirm-success">
                                {t("phone_confirm_success")}
                                <img src={checkIcon} alt="" />
                            </div>
                        </div>
                    ) : (
                        <div className="landing-contacts__phone-confirm-success-btn">
                            <button
                                onClick={setPhoneConfirmFormHandler}
                                disabled={!validatePhone(form.phone)}
                            >
                                {t("phone_confirm_btn")}
                            </button>
                        </div>
                    )}
                </div>
                <div className="landing-contacts__input-field">
                    <label htmlFor="email">{t("footer_form_email")}</label>
                    <input
                        placeholder={t("footer_form_email")}
                        id="email"
                        type="text"
                        name="email"
                        onChange={changeHandler}
                        value={form.email}
                    />
                </div>
            </div>
        </>
    );
};
