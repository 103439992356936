import React from "react";

export const LandingContactsStep3 = ({ form, changeHandler, t }) => {
    return (
        <div className="landing-contacts__input-group">
            <div className="landing-contacts__input-field">
                <label htmlFor="city">{t("footer_form_city")}</label>
                <input
                    placeholder={t("footer_form_city")}
                    id="city"
                    type="text"
                    name="city"
                    onChange={changeHandler}
                    value={form.city}
                />
            </div>
            <div className="landing-contacts__input-field">
                <label htmlFor="institutionType">{t("footer_form_institution_type")}</label>
                <input
                    placeholder={t("footer_form_institution_type")}
                    id="institutionType"
                    type="text"
                    name="institutionType"
                    onChange={changeHandler}
                    value={form.institutionType}
                />
            </div>
            <div className="landing-contacts__input-field">
                <label htmlFor="institution">{t("footer_form_institution")}</label>
                <input
                    placeholder={t("footer_form_institution")}
                    id="institution"
                    type="text"
                    name="institution"
                    onChange={changeHandler}
                    value={form.institution}
                />
            </div>
        </div>
    );
};
