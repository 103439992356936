import {RegisterPsychForm} from "../components/register/RegisterPsychForm";
import {RegisterPsychFormYanao} from "../yanaoDiff/components/RegisterPsychFormYanao/RegisterPsychFormYanao";
import {PupilsPage} from "../pages/pupils/PupilsPage";
import {PupilsPageYanao} from "../yanaoDiff/pages/PupilsPageYanao/PupilsPageYanao";

export const yanaoComponents = {
    RegisterPsychForm: {
        main: RegisterPsychForm,
        yanao: RegisterPsychFormYanao,
    },
    PupilsPageYanao: {
        main: PupilsPage,
        yanao: PupilsPageYanao,
    }
};