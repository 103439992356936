import React from "react";
import { Link } from "react-router-dom";

import { declinations } from "helpers/declinations";
import { Range } from "components/Range";

export const StatisticClassesList = ({ classes, i18n }) => {
    return (
        <div>
            {classes.map((item) => (
                <Link
                    key={item._id}
                    to={`/statistic/classes/${item._id}`}
                    className="statistic-condition"
                >
                    <p className="statistic-condition__title">
                        {item.number} {item.letter.toUpperCase()}
                    </p>

                    <Range
                        total={item.total}
                        norm={item.positively}
                        // notNorm={item.negatively}
                        notNorm={item.total - item.positively}
                    />

                    <div className="statistic-condition__footer">
                        <span className="statistic-condition__indicator green">
                            {item.positively}
                        </span>
                        {/*<span className="statistic-condition__indicator red">*/}
                        {/*    {item.negatively}*/}
                        {/*</span>*/}
                        <span className="statistic-condition__indicator blue">
                            {/*{item.total - item.positively - item.negatively}*/}
                            {item.total - item.positively}
                        </span>
                        <p className="statistic-condition__members">
                            {item.total} {declinations(item.total, "test", i18n.language)}
                        </p>
                    </div>
                </Link>
            ))}
        </div>
    );
};
