import { suicideRisks } from "./suicide-risks";
import {
    diagnostic,
    diagnosticMaterials,
    diagnosticMaterialsCollections,
    diagnosticMaterialsMethods,
    methodologyListsAndRegisters,
} from "./diagnostic";
import { usefulLiterature } from "components/profile/guidelines/useful-literature";
import { prevention } from "components/profile/guidelines/prevention";
import { preventionReminders } from "components/profile/guidelines/prevention-reminders";
import {
    preventionPrograms,
    preventionProgramsExtreme,
    preventionProgramsNpr,
    preventionProgramsRlp,
    preventionProgramsSchool,
    preventionProgramsSel,
    preventionProgramsTk,
} from "components/profile/guidelines/prevention-programs";
import {
    psychologicalInterventions,
    psychologicalInterventionsAggression,
    psychologicalInterventionsAlgorithm,
    psychologicalInterventionsBulling,
    psychologicalInterventionsHyperactivity,
    psychologicalInterventionsLanguageWork,
    psychologicalInterventionsLNRDNR,
    psychologicalInterventionsMigrant,
    psychologicalInterventionsPlayTherapy,
    psychologicalInterventionsPreparation,
    psychologicalInterventionsSuicideRisk,
    psychologicalInterventionsSupport,
    psychologicalInterventionsViolent,
} from "components/profile/guidelines/psychological-interventions";

export const mainGuidelinesList = [
    {
        title: "Диагностика",
        link: "/diagnostic",
    },
    {
        title: "Полезная литература",
        link: "/useful-literature",
    },
    {
        title: "Профилактика",
        link: "/prevention",
    },
    {
        title: "Психологические интервенции",
        link: "/psychological-interventions",
    },
    {
        title: "Суицидальные риски",
        link: "/suicide-risks",
    },
];

export const mainGuidelinesData = {
    diagnostic,
    "diagnostic-materials": diagnosticMaterials,
    "diagnostic-materials-methods": diagnosticMaterialsMethods,
    "diagnostic-materials-collections": diagnosticMaterialsCollections,
    "methodology-lists-and-registers": methodologyListsAndRegisters,
    "useful-literature": usefulLiterature,
    prevention,
    "prevention-reminders": preventionReminders,
    "prevention-programs": preventionPrograms,
    "prevention-programs-npr": preventionProgramsNpr,
    "prevention-programs-rlp": preventionProgramsRlp,
    "prevention-programs-sel": preventionProgramsSel,
    "prevention-programs-tk": preventionProgramsTk,
    "prevention-programs-school": preventionProgramsSchool,
    "prevention-programs-extreme": preventionProgramsExtreme,
    "psychological-interventions": psychologicalInterventions,
    "psychological-interventions-algorithm": psychologicalInterventionsAlgorithm,
    "psychological-interventions-preparation": psychologicalInterventionsPreparation,
    "psychological-interventions-aggression": psychologicalInterventionsAggression,
    "psychological-interventions-bulling": psychologicalInterventionsBulling,
    "psychological-interventions-migrant": psychologicalInterventionsMigrant,
    "psychological-interventions-lnr-dnr": psychologicalInterventionsLNRDNR,
    "psychological-interventions-language-work": psychologicalInterventionsLanguageWork,
    "psychological-interventions-hyperactivity": psychologicalInterventionsHyperactivity,
    "psychological-interventions-violent": psychologicalInterventionsViolent,
    "psychological-interventions-support": psychologicalInterventionsSupport,
    "psychological-interventions-play-therapy": psychologicalInterventionsPlayTherapy,
    "psychological-interventions-suicide-risk": psychologicalInterventionsSuicideRisk,
    "suicide-risks": suicideRisks,
};
