import { memo } from "react";

import "static/scss/spinner.scss";

export const Spinner = memo(() => {
    return (
        <div className="lds-ellipsis">
            <div />
            <div />
            <div />
            <div />
        </div>
    );
});
