import { useEffect } from "react";
import Moment from "react-moment";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { getAlarms, readAlarm } from "redux/actions/setAlarmActions";
import { classNames } from "helpers/classNames";
import { BackButton } from "components/BackButton";
import { Layout } from "components/Layout";

/**
 * @api {get} /alarms запросы о помощи
 * @apiGroup profile
 * @apiName запросы о помощи
 *
 * @apiDescription <p>Страница просмотра списка запросов о помощи</p>
 * <p>Компонент <code>AlarmsPage.js</code> <a href="../client/src/pages/AlarmsPage.js">ссылка на файл</a></p>
 */

export const AlarmsPage = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { t, i18n } = useTranslation();
    const { alarms } = useSelector((state) => state.alarm);

    useEffect(() => {
        dispatch(getAlarms());
    }, [dispatch]);

    const clickAlarmHandler = (alarm) => {
        dispatch(readAlarm(alarm._id));
        history.push(`/pupils/${alarm.pupil._id}`);
    };

    return (
        <Layout>
            <div className="page">
                <header className="page__header">
                    <BackButton />
                    <p className="page__title">{t("alarms_title")}</p>
                </header>
                <div className="page__content">
                    <div className="list">
                        {alarms.map((alarm) => (
                            <button
                                key={alarm._id}
                                onClick={() => clickAlarmHandler(alarm)}
                                className={classNames("list__item list__item-button", {
                                    list__item_red: !alarm.readAt,
                                })}
                            >
                                <p>
                                    {alarm.pupil.name} {alarm.pupil.surname}
                                </p>
                                <p className="list__desc">
                                    <Moment locale={i18n.language} format="HH:mm, DD MMM, YYYY">
                                        {alarm.createdAt}
                                    </Moment>
                                </p>
                            </button>
                        ))}
                    </div>
                </div>
            </div>
        </Layout>
    );
};
