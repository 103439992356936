export const prevention = {
    folders: [
        {
            title: "Памятки",
            link: "/prevention-reminders",
        },
        {
            title: "Профилактические программы",
            link: "/prevention-programs",
        },
    ],
};
