import React from "react";

export const MeetingStatus = ({ status, t }) => {
    return (
        <>
            {status === "read" && (
                <span className="list__item-meeting-status list__item-meeting-read">
                    {t("confirmed")}
                </span>
            )}

            {status === "unread" && (
                <span className="list__item-meeting-status list__item-meeting-not-read">
                    {t("not_confirmed")}
                </span>
            )}

            {status === "canceled" && (
                <span className="list__item-meeting-status list__item-meeting-canceled">
                    {t("canceled")}
                </span>
            )}

            {status === "completed" && (
                <span className="list__item-meeting-status list__item-meeting-completed">
                    {t("completed")}
                </span>
            )}
        </>
    );
};
