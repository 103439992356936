import fetch from "../fetch";
import { startLoading, finishLoading, showAlert } from "../actions/setAppActions";
import { CONFIRM_MEETING, CREATE_MEETING, GET_MEETINGS } from "../types";
import i18n from "i18n.js";
import axios from "axios";

export function createMeeting(data) {
    return async (dispatch, getState) => {
        try {
            dispatch(startLoading());

            const json = await fetch(
                "/api/meetings",
                {
                    method: "POST",
                    body: JSON.stringify({ ...data }),
                    headers: { "Content-Type": "application/json" },
                },
                getState,
                dispatch
            );

            dispatch({ type: CREATE_MEETING, payload: json.meeting });
            dispatch(finishLoading());
            dispatch(
                showAlert({ type: "success", text: i18n.t("meeting_created", { ns: "meetings" }) })
            );
        } catch (e) {
            dispatch(finishLoading());
            dispatch(showAlert({ type: "error", text: e.message }));
        }
    };
}

export function getMeetings(pupilId) {
    return async (dispatch, getState) => {
        try {
            dispatch(startLoading());
            const json = await fetch(
                `/api/meetings${pupilId ? `?pupilId=${pupilId}` : ""}`,
                { method: "GET" },
                getState,
                dispatch
            );

            dispatch({ type: GET_MEETINGS, payload: json.meetings });
            dispatch(finishLoading());
        } catch (e) {
            dispatch(finishLoading());
            dispatch(showAlert({ type: "error", text: e.message }));
        }
    };
}

export function changeMeetingStatus(id, status) {
    return async (dispatch) => {
        try {
            dispatch(startLoading());
            const response = await axios.put(`/meetings/status/${id}`, { status });
            let textKey;

            switch (status) {
                case "canceled":
                    textKey = "meeting_canceled";
                    break;
                case "completed":
                    textKey = "meeting_completed";
                    break;
                default:
                    textKey = "meeting_confirmed";
            }

            dispatch({ type: CONFIRM_MEETING, payload: response.data });
            dispatch(finishLoading());
            dispatch(showAlert({ type: "success", text: i18n.t(textKey, { ns: "meetings" }) }));
        } catch (e) {
            dispatch(finishLoading());
            dispatch(showAlert({ type: "error", text: e.message }));
        }
    };
}
