import React from "react";
import { IMaskInput } from "react-imask";

import { classNames } from "helpers/classNames";

export const Input = ({
    name,
    value,
    onChange,
    placeholder,
    isError = false,
    type = "text",
    mask = null,
    maskOptions = {},
    onClick,
    readOnly = false,
    otherProps,
}) => {
    return (
        <div
            className={classNames("input-field", {
                error: isError,
            })}
        >
            {mask ? (
                <IMaskInput
                    id={name}
                    type={type}
                    name={name}
                    placeholder={placeholder}
                    onChange={onChange}
                    value={value}
                    mask={mask}
                    {...maskOptions}
                    {...otherProps}
                />
            ) : (
                <input
                    id={name}
                    type={type}
                    name={name}
                    placeholder={placeholder}
                    onChange={onChange}
                    value={value}
                    onClick={onClick}
                    readOnly={readOnly}
                    {...otherProps}
                />
            )}
            <label htmlFor={name}>{placeholder}</label>
        </div>
    );
};
