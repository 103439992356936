import axios from "axios";

export async function fetchAllPupils() {
    try {
        const response = await axios.get("pupil");

        return response.data;
    } catch (e) {
        const text = e.response?.data?.message || "Ошибка загрузки данных";
        throw new Error(text);
    }
}
