import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";
import { useTranslation } from "react-i18next";

import { Layout } from "components/Layout";
import { BackButton } from "components/BackButton";
import { showAlert } from "redux/actions/setAppActions";
import { Loader } from "components/Loader";
import studentRegistrationTemplate from "static/docs/student_registration_template.xlsx";

/**
 * @api {get} /classes/:id/excel добавление учеников через эксель
 * @apiGroup psychologists
 * @apiName добавление учеников через эксель
 *
 * @apiParam {String} id ID Класса
 *
 * @apiDescription <p>Страница добавления учеников через эксель</p>
 * <p>Компонент <code>ClassesAddExcelPage.js</code> <a href="../client/src/pages/ClassesAddExcelPage.js">ссылка на файл</a></p>
 */

export const ClassesAddExcelPage = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation("pupils");
    const classId = useParams().id;
    const fileUploadRef = useRef(null);
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        (async () => {
            const { data } = await axios.get(`classes/${classId}`);
            setData(data);
        })();
    }, [classId]);

    const uploadFileHandler = async (e) => {
        const file = e.target.files[0];

        if (!file) {
            dispatch(showAlert({ type: "error", text: t("pupil_bulk_upload_error") }));
        } else {
            try {
                const extension = file.name.split(".").at(-1);

                if (!extension || extension !== "xlsx") {
                    dispatch(showAlert({ type: "error", text: t("incorrect_file_format") }));
                    return;
                }

                const formData = new FormData();
                formData.append("file", file);

                setIsLoading(true);

                const response = await axios.post(`pupil/register-excel/${classId}`, formData, {
                    responseType: "blob",
                });

                const link = document.createElement("a");

                link.href = window.URL.createObjectURL(new Blob([response.data]));
                link.setAttribute(
                    "download",
                    `Список_учеников_${data?.number}_${data?.letter}.xlsx`
                );
                document.body.appendChild(link);
                link.click();
                link.remove();

                dispatch(showAlert({ type: "success", text: t("pupil_bulk_upload_success") }));
            } catch (err) {
                const errorData = JSON.parse(await err.response.data.text());
                const text = errorData?.message || t("pupil_bulk_upload_error");

                dispatch(showAlert({ type: "error", text }));
            } finally {
                setIsLoading(false);

                if (fileUploadRef?.current) {
                    fileUploadRef.current.value = "";
                }
            }
        }
    };

    return (
        <Layout>
            <div className="page code-page">
                <header className="page__header">
                    <BackButton />
                    <p className="page__title code-page__title">
                        {data?.school.name}, {data?.number} {data?.letter}
                    </p>
                </header>

                <div className="page__content code-page__content">
                    <div className="code-page__buttons">
                        <a
                            className="code-page__btn-wrapper"
                            href={studentRegistrationTemplate}
                            download={`Шаблон_регистрации_учеников_${data?.number}_${data?.letter}.xlsx`}
                        >
                            <span>{t("download_template")}</span>
                        </a>

                        {isLoading ? (
                            <div
                                className="code-page__btn-wrapper"
                                style={{fontSize: 16, paddingTop: 0}}
                            >
                                <Loader/>
                                {t("uploading_text")}
                            </div>
                        ) : (
                            <label className="code-page__btn-wrapper">
                                <input
                                    onChange={uploadFileHandler}
                                    ref={fileUploadRef}
                                    type="file"
                                    accept=".xlsx"
                                    style={{display: "none"}}
                                    disabled={isLoading}
                                />
                                <span>{t("upload_pupils_list")}</span>
                            </label>
                        )}
                    </div>
                    <div className={"code-page__info"}>
                        <p className={"code-page__info-text"}>
                            1. Вам будет предложено скачать шаблон, в котором будет указано,
                            какую информацию об ученике и в каком формате необходимо внести в таблицу.<br/>
                            2. В скачанном файле введите данные учеников учебного класса (группы), которого вы
                            добавляете на платформу в настоящий момент.<br/>
                            3. Нажмите на кнопку “Загрузить список” и в появившемся окне выберите файл, в который вы
                            внесли необходимые в соответствии с шаблоном данные учеников учебного класса.<br/>
                        </p>
                    </div>
                </div>
            </div>
        </Layout>
    );
};
