import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { getFavoriteMethodsIfNeeded, getMethodsIfNeeded } from "redux/actions/setMethodActions";
import { Layout } from "components/Layout";
import { BackButton } from "components/BackButton";
import { Loader } from "components/Loader";

/**
 * @api {get} /workWithMethods работа с методиками
 * @apiGroup methods
 * @apiName работа с методиками
 *
 * @apiDescription <p>Страница работы с методиками</p>
 * <p>Компонент <code>WorkWithMethods.js</code> <a href="../client/src/pages/methods/WorkWithMethods.js">ссылка на файл</a></p>
 */

export const WorkWithMethods = () => {
    const dispatch = useDispatch();
    const { loading } = useSelector((state) => state.app);
    const { t } = useTranslation("methods");

    useEffect(() => {
        dispatch(getMethodsIfNeeded());
        dispatch(getFavoriteMethodsIfNeeded());
    }, [dispatch]);

    if (loading) {
        return <Loader />;
    }

    return (
        <Layout>
            <div className="page full-header">
                <header className="page__header full-header">
                    <BackButton />
                    <p className="page__title">{t("work_with_methods")}</p>
                </header>

                <div className="workWithMethods-page__wrapper">
                    <div className="list workWithMethods-page__list">
                        <p className="workWithMethods-page__description">
                            {t("work_with_methods_intro")}
                        </p>
                        <Link to="/workWithMethods/favorite" className="main-btn">
                            {t("favorite_methods")}
                        </Link>
                        <Link to="/workWithMethods/problems" className="main-btn">
                            {t("teenage_problems")}
                        </Link>
                        <Link to="/workWithMethods/suicidal-risks" className="main-btn">
                            {t("suicidal_risks")}
                        </Link>
                        <Link to="/workWithMethods/by-age" className="main-btn">
                            {t("by_age")}
                        </Link>
                        <Link to="/workWithMethods/by-direction" className="main-btn">
                            {t("by_direction")}
                        </Link>
                        <Link to="/workWithMethods/general-list" className="main-btn">
                            {t("general_list")}
                        </Link>
                        <Link to="/workWithMethods/my" className="main-btn">
                            {t("my_methods")}
                        </Link>
                    </div>
                    <p style={{ opacity: 0.3 }}>{t("work_with_methods_text")}</p>
                </div>
            </div>
        </Layout>
    );
};
