import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useHistory } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { FaCheck } from "react-icons/fa";

import { showAlert } from "redux/actions/setAppActions";
import { setDnevnikruUser, setUser } from "redux/actions/setUserActions";
import { checkPwa } from "helpers/devices";
import { classNames } from "helpers/classNames";
import { Alert } from "components/Alert";
import { CONSTRUCTOR_LINK, IS_PRODUCTION, SITE_URL } from "config.js";
import { DNEVNIKRU_LOGIN_URL } from "configs/dnevnikru";

/**
 * @api {get} /psych/login авторизация психолога
 * @apiGroup psychologists
 * @apiName авторизация психолога
 *
 * @apiDescription <p>Страница авторизации психолога</p>
 * <p>Компонент <code>LoginPsychPage.js</code> <a href="../client/src/pages/LoginPsychPage.js">ссылка на файл</a></p>
 */

const initialState = {
    login: "",
    password: "",
};

const referrer = document.referrer.includes("constructor");
const dnevnikruLoginUrl = `${DNEVNIKRU_LOGIN_URL}&redirect_uri=${SITE_URL}/psych/login`;

const isPwa = checkPwa();

export const LoginPsychPage = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { hash } = useLocation();
    const { t } = useTranslation(["login", "translation"]);
    const { alert } = useSelector((state) => state.app);
    const [form, setForm] = useState(initialState);
    const [isShowPassword, setIsShowPassword] = useState(false);
    const [isRememberPassword, setIsRememberPassword] = useState(false);

    useEffect(() => {
        if (hash) {
            const accessToken = hash.match(/access_token=(.*)&state=/);
            const error = hash.match(/error=(.*)&state=/);

            if (accessToken) {
                if (!accessToken[1]) {
                    dispatch(
                        showAlert({
                            type: "error",
                            text: t("dnevnikru_integration_error", { ns: "translation" }),
                        })
                    );

                    return;
                }

                (async () => {
                    try {
                        const { data } = await axios.get("/auth/dnevnikru", {
                            params: {
                                accessToken: accessToken[1],
                                role: "psych",
                            },
                        });

                        if (data.type === "login") {
                            dispatch(setUser(data.body));
                            referrer
                                ? window.location.assign(CONSTRUCTOR_LINK)
                                : history.push("/dashboard");
                        } else if (data.type === "register") {
                            dispatch(
                                setDnevnikruUser({ ...data.body, accessToken: accessToken[1] })
                            );
                            history.push("/psych/register");
                        }
                    } catch (e) {
                        if (e.response?.data?.message) {
                            dispatch(showAlert({ type: "error", text: e.response.data.message }));
                        } else {
                            dispatch(
                                showAlert({
                                    type: "error",
                                    text: t("incorrect_data", { ns: "translation" }),
                                })
                            );
                        }
                    }
                })();
            } else if (error) {
                dispatch(
                    showAlert({
                        type: "error",
                        text: t("dnevnikru_integration_error", { ns: "translation" }),
                    })
                );
            }
        }
    }, [history, hash, dispatch, t]);

    const changeHandler = (event) => {
        setForm({ ...form, [event.target.name]: event.target.value.trim() });
    };

    useEffect(() => {
        if (localStorage.getItem("remember_password")) {
            setIsRememberPassword(true);
        }
    }, []);

    const handleChangeRememberPassword = () => {
        const newIsRememberPassword = !isRememberPassword;

        if (newIsRememberPassword) {
            localStorage.setItem("remember_password", "true");
        } else {
            localStorage.removeItem("remember_password");
        }

        setIsRememberPassword(newIsRememberPassword);
    };

    const login = async (pincode) => {
        try {
            const body = { ...form };
            const params = {};

            if (pincode) {
                params.pincode = true;
            }

            const { data } = await axios.post("/psych/login", body, {
                params,
            });

            if (pincode) {
                localStorage.setItem("pwaUser", JSON.stringify(data));
                history.push("/pincode");
            } else {
                dispatch(setUser(data));

                if (isPwa) {
                    localStorage.removeItem("pwaUser");
                    localStorage.removeItem("pwaPinSaved");
                }

                referrer ? window.location.assign(CONSTRUCTOR_LINK) : history.push("/dashboard");
            }
        } catch (e) {
            if (e.response?.data?.message) {
                dispatch(showAlert({ type: "error", text: e.response.data.message }));
            } else {
                dispatch(
                    showAlert({
                        type: "error",
                        text: t("incorrect_data", { ns: "translation" }),
                    })
                );
            }
        }
    };

    return (
        <div className="main">
            {!!alert && <Alert text={alert.text} type={alert.type} />}
            <div className="page auth-page">
                <header className="page__header">
                    <button
                        onClick={() => history.push("/")}
                        className="icon-btn page__icon-btn page__icon-btn_left icon-btn_back"
                    />
                    <p className="page__title">{t("psych_login")}</p>
                </header>

                <div className="auth-page__wrapper">
                    <div className="auth-page__header">
                        <h3>{t("login_title")}</h3>
                        <Link to={"/psych/register"} className="auth-page__header-btn">
                            {t("no_account")}
                        </Link>
                    </div>
                    <div className="auth-page__input-group">
                        <div className="input-field">
                            <input
                                id="login"
                                type="text"
                                name="login"
                                value={form.login}
                                placeholder={t("login_placeholder")}
                                onChange={changeHandler}
                            />
                            <label htmlFor="login">{t("login_placeholder")}</label>
                        </div>
                        <div className="input-field">
                            <input
                                id="password"
                                type={`${isShowPassword ? "text" : "password"}`}
                                name="password"
                                value={form.password}
                                placeholder={t("password_placeholder")}
                                onChange={changeHandler}
                                autoComplete={`${isRememberPassword ? "on" : "new-password"}`}
                            />
                            <label htmlFor="password">{t("password_placeholder")}</label>
                        </div>
                        <div
                            onClick={() => setIsShowPassword(!isShowPassword)}
                            className="animatedCheckbox animatedCheckbox__password animatedCheckbox__cursor"
                        >
                            <div
                                className={classNames("checkbox", {
                                    checked: isShowPassword,
                                })}
                            >
                                <FaCheck style={{ filter: "brightness(0) invert(1)" }} />
                            </div>
                            <p>{t("show_password")}</p>
                        </div>
                        <div
                            onClick={handleChangeRememberPassword}
                            className="animatedCheckbox animatedCheckbox__password animatedCheckbox__cursor"
                        >
                            <div
                                className={classNames("checkbox", {
                                    checked: isRememberPassword,
                                })}
                            >
                                <FaCheck style={{ filter: "brightness(0) invert(1)" }} />
                            </div>
                            <p>{t("remember_password")}</p>
                        </div>

                        <div className="auth-page__buttons">
                            <Link to={`/psych/lose`}>{t("forgot_password")}</Link>

                            {!IS_PRODUCTION && (
                                <a href={dnevnikruLoginUrl} className="auth-page__dnevnikru">
                                    <img
                                        src="https://static.dnevnik.ru/img/logotypes/logotype.png"
                                        alt="Дневник.ру"
                                    />
                                    <br />
                                    <span>Войти через</span>
                                </a>
                            )}
                        </div>
                    </div>

                    <button onClick={() => login(false)} className="main-btn auth-page__btn">
                        {t("login_button")}
                    </button>

                    {isPwa && (
                        <button
                            onClick={() => login(true)}
                            className="main-btn main-btn_purple"
                            style={{ marginTop: 12 }}
                        >
                            {t("pin_code_login_button")}
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};
