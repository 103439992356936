import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { usePwaPincode } from "components/login/PwaPincode/hooks";
import { showAlert } from "redux/actions/setAppActions";
import { Alert } from "components/Alert";
import { PwaPincodeScreen } from "components/login/PwaPincodeScreen/PwaPincodeScreen";

export const PwaPincodePage = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation(["login", "translation"]);
    const { alert } = useSelector((state) => state.app);
    const { isLoading, authUser } = usePwaPincode();
    const [isPwaPinSaved, setIsPwaPinSaved] = useState(false);
    const [pwaUser, setPwaUser] = useState(null);
    const [isRepeatPin, setIsRepeatPin] = useState(false);
    const [pinValue, setPinValue] = useState("");

    useEffect(() => {
        const pwaPinSaved = localStorage.getItem("pwaPinSaved");
        const pwaUser = localStorage.getItem("pwaUser");

        if (pwaUser) {
            const parsedPwaUser = JSON.parse(pwaUser);

            if (!parsedPwaUser.userId || !parsedPwaUser.role) {
                return history.goBack();
            }

            setPwaUser(parsedPwaUser);
        } else {
            return history.goBack();
        }

        if (pwaPinSaved) {
            setIsPwaPinSaved(true);
        }
    }, [history]);

    const handleSendCode = async (pin) => {
        if (isPwaPinSaved) {
            await authUser(pwaUser.userId, pwaUser.role, pin, "pincode");
        } else {
            if (!pinValue) {
                setPinValue(pin);
                setIsRepeatPin(true);
            } else {
                if (pinValue === pin) {
                    await authUser(pwaUser.userId, pwaUser.role, pin, "set-pincode");
                } else {
                    dispatch(
                        showAlert({
                            type: "error",
                            text: t("pin_codes_dont_match"),
                        })
                    );
                }
            }
        }
    };

    return (
        <div className="main">
            {!!alert && <Alert text={alert.text} type={alert.type} />}

            <div className="page auth-page">
                <header className="page__header">
                    <p className="page__title">
                        {isRepeatPin ? t("pin_code_repeat_title") : t("pin_code_set_title")}
                    </p>
                </header>

                <PwaPincodeScreen
                    handleSendCode={handleSendCode}
                    isLoading={isLoading}
                    pwaUser={pwaUser}
                />
            </div>
        </div>
    );
};
