import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { declinations } from "helpers/declinations";
import { useDispatch, useSelector } from "react-redux";
import { getConditionsINedded } from "redux/actions/setConditionActions";
import { MethodsByAgeList } from "components/methods/MethodsByAgeList";
import { Layout } from "components/Layout";
import { BackButton } from "components/BackButton";
import "static/scss/problemsPage.scss";

const ages = [
    { from: 6, to: 7 },
    { from: 8, to: 10 },
    { from: 10, to: 12 },
    { from: 12, to: 14 },
    { from: 15, to: 16 },
    { from: 16, to: 18 },
    { from: 18, to: 21 },
];

export const MethodsByAge = () => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation("methods");
    const { conditions } = useSelector((state) => state.test);
    const [step, setStep] = useState(1);
    const [chosenAges, setChosenAges] = useState([]);

    useEffect(() => {
        dispatch(getConditionsINedded());
    }, [dispatch]);

    const changeAgesHandler = (e) => {
        const value = e.target.value;

        if (chosenAges.includes(value)) {
            setChosenAges([...chosenAges].filter((item) => item !== value));
        } else {
            setChosenAges([...chosenAges, value]);
        }
    };

    if (step === 2) {
        return (
            <MethodsByAgeList
                conditions={conditions}
                chosenAges={chosenAges}
                backBtnHandler={() => setStep(1)}
            />
        );
    }

    return (
        <Layout>
            <div className="page full-header">
                <header className="page__header full-header">
                    <BackButton />
                    <p className="page__title">{t("by_age")}</p>
                </header>

                {step === 1 && (
                    <>
                        <div className="workWithMethods-page__wrapper">
                            <>
                                <h4 className="problem-page__h4">Выберите возраст</h4>

                                <div className="work-with-methods__ages-block">
                                    {ages.map(({ from, to }, i) => {
                                        const value = `${from}-${to}`;

                                        return (
                                            <div
                                                key={i}
                                                className="work-with-methods__ages-checkbox"
                                            >
                                                <input
                                                    id={value}
                                                    type="checkbox"
                                                    checked={chosenAges.includes(value)}
                                                    onChange={changeAgesHandler}
                                                    value={value}
                                                />
                                                <label htmlFor={`${from}-${to}`}>
                                                    <div>
                                                        <p>
                                                            {from}-{to}{" "}
                                                            {declinations(
                                                                to,
                                                                "year",
                                                                i18n.language
                                                            )}
                                                        </p>
                                                    </div>
                                                </label>
                                            </div>
                                        );
                                    })}
                                </div>
                            </>
                        </div>

                        <button
                            className="work-with-methods__ages-next-btn"
                            disabled={!chosenAges.length}
                            onClick={() => setStep(2)}
                        >
                            <p>{t("continue")}</p>
                            <p>
                                {chosenAges.length ? (
                                    <>
                                        {t("ages_selected")}: {chosenAges.length}
                                    </>
                                ) : (
                                    <>{t("select_age")}</>
                                )}
                            </p>
                        </button>
                    </>
                )}
            </div>
        </Layout>
    );
};
