export const getSchoolRegion = (user) => {
    let result = "";

    if (!user?.city || !user?.region) {
        return result;
    }

    result = user.city;

    if (user.region) {
        result += `, ${user.region}`;
    }

    return result;
};
