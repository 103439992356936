import React, { useCallback, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import axios from "axios";
import moment from "moment";

import { declinations } from "helpers/declinations";
import { yearsOldCounter } from "helpers/years";
import { BackButton } from "components/BackButton";
import { Layout } from "components/Layout";
import { DocPage } from "components/solution/DocPage";
import { Diagram } from "components/solution/Diagram";
import { CreateReportButton } from "components/solution/CreateReportButton";
import { CONSTRUCTOR_LINK } from "config";
import "../static/scss/scaleDesc.scss";

/**
 * @api {get} /solutions/:id информация о пройденном тесте
 * @apiGroup tests
 * @apiName информация о пройденном тесте
 *
 * @apiParam {String} id ID теста
 *
 * @apiDescription <p>Страница с информацией о пройденном тесте</p>
 * <p>Компонент <code>SolutionPage.js</code> <a href="../client/src/pages/SolutionPage.js">ссылка на файл</a></p>
 */

export const SolutionPage = () => {
    const { role } = useSelector((state) => state.auth);
    const { t, i18n } = useTranslation("tests");
    const [test, setTest] = useState(null);
    const [html, setHtml] = useState(null);
    const [info, setInfo] = useState(null);
    const [scaleDesc, setScaleDesc] = useState(null);
    const [diagram, setDiagram] = useState(false);
    const [isDocPage, setIsDocPage] = useState(false);
    const [rows, setRows] = useState([]);
    const [isTable1, setIsTable1] = useState(false);
    const [isTable2, setIsTable2] = useState(false);
    const [isTexts, setIsTexts] = useState(false);
    const [currentText, setCurrentText] = useState({});
    let history = useHistory();
    const testId = useParams().id;

    function sortedScales(scales) {
        let newScales = [[], [], [], [], []];

        for (let i = 0; i < scales.length; i++) {
            switch (scales[i].level) {
                case 1:
                    newScales[0].push(scales[i]);
                    break;
                case 2:
                    newScales[1].push(scales[i]);
                    break;
                case 3:
                    newScales[2].push(scales[i]);
                    break;
                case 4:
                    newScales[3].push(scales[i]);
                    break;
                case 5:
                    newScales[4].push(scales[i]);
                    break;
                default:
                    break;
            }
        }
        for (let i = 0; i < newScales.length; i++) {
            newScales[i].sort((a, b) => a.score - b.score);
        }
        return [
            ...newScales[0],
            ...newScales[1],
            ...newScales[2],
            ...newScales[3],
            ...newScales[4],
        ];
    }

    const getTest = useCallback(async () => {
        try {
            const { data } = await axios.get(`/tests/${testId}`);
            setTest(data);
        } catch (e) {
            console.log(e);
        }
    }, [testId]);

    useEffect(() => {
        getTest();
    }, [getTest]);

    useEffect(() => {
        if (test) {
            setRows(sortedScales(test.solution.results));

            const html = "<html></html>";
            const parser = new DOMParser();
            const document = parser.parseFromString(html, "text/html");
            setHtml(document);
        }
    }, [test]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [isTable1, isDocPage]);

    const docBtnHandler = useCallback(() => {
        setIsDocPage(!isDocPage);
    }, [isDocPage]);

    const diagramBtnHandler = useCallback(() => {
        setDiagram(!diagram);
    }, [diagram]);

    const infoHandler = (e) => {
        return setInfo(rows[e.currentTarget.id]);
    };
    const scaleDescHandler = (e) => {
        return setScaleDesc(rows[e.target.id]);
    };

    if (!html) {
        return null;
    }

    if (isTexts) {
        return (
            <Layout>
                <div className="page">
                    <header className="page__header">
                        <button
                            onClick={() => {
                                setIsTexts(false);
                            }}
                            className="icon-btn page__icon-btn page__icon-btn_left icon-btn_back"
                        />
                    </header>
                    <h3>{currentText.question}</h3>
                    <label style={{ opacity: 0.3 }}>{t("answer")}</label>
                    <p>{currentText.answer}</p>
                </div>
            </Layout>
        );
    }

    if (isTable1) {
        return (
            <Layout>
                <div className="page">
                    <header className="page__header">
                        <button
                            onClick={() => {
                                setIsTable1(false);
                            }}
                            className="icon-btn page__icon-btn page__icon-btn_left icon-btn_back"
                        />
                    </header>
                    <h3>{t("answers")}</h3>
                    <div className="questions-list">
                        {test.solution.first.map(({ answer, question }, itemOrder) => {
                            const isTextAnswer = typeof answer === "string";
                            const isImage = !isTextAnswer && answer.isImage && answer.image;

                            if (!isTextAnswer && !isImage) {
                                return null;
                            }

                            return (
                                <div className="questions-list__item" key={itemOrder}>
                                    <div className="number-holder">
                                        <p>
                                            {t("question")} {itemOrder + 1}
                                        </p>
                                    </div>
                                    {isTextAnswer && <p>{answer}</p>}

                                    {isImage && (
                                        <>
                                            <p>Ответ:</p>
                                            <div>
                                                <img
                                                    src={`${CONSTRUCTOR_LINK}/api/static/images/${answer.image}`}
                                                    alt=""
                                                    className="questions-list__item-image"
                                                />
                                            </div>
                                        </>
                                    )}

                                    <label>{question}</label>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </Layout>
        );
    }
    if (isTable2) {
        return (
            <Layout>
                <div className="page">
                    <header className="page__header">
                        <button
                            onClick={() => {
                                setIsTable2(false);
                            }}
                            className="icon-btn page__icon-btn page__icon-btn_left icon-btn_back"
                        />
                    </header>
                    <h3>{t("table")} 2</h3>
                    <div className="questions-list">
                        {test.solution.second.map((item, itemOrder) => {
                            return (
                                <div className="questions-list__item">
                                    <div className="number-holder">
                                        <p>
                                            {t("question")} {itemOrder + 1}
                                        </p>
                                    </div>
                                    <p>{item.answer}</p>
                                    <label>{item.question}</label>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </Layout>
        );
    }

    if (scaleDesc) {
        return (
            <Layout>
                <div className="page">
                    <header className="page__header">
                        <button
                            onClick={() => {
                                setScaleDesc(null);
                                setInfo(null);
                            }}
                            className="icon-btn page__icon-btn page__icon-btn_left icon-btn_back"
                        />
                        <p className="page__title">{t("scale_description")}</p>
                    </header>
                    <div className="scale-desc" style={{ flexGrow: 1 }}>
                        <h3>{info.name}</h3>
                        <h4>{t("scale_description")}</h4>
                        <p>{info.info}</p>
                        <h4>{t("scale_levels")}:</h4>
                        <div className="scale-desc__levels">
                            {info.levels
                                ? info.levels.map((level, id1) => {
                                      return (
                                          <div className="scale-desc__level" key={id1}>
                                              <p>
                                                  {t("scale_from")} {level.from} {t("scale_to")}{" "}
                                                  {level.to}
                                              </p>
                                              <p>
                                                  <span>
                                                      {t("scale_level")} {id1 + 1}
                                                  </span>
                                              </p>
                                          </div>
                                      );
                                  })
                                : ""}
                        </div>
                        {/* <p dangerouslySetInnerHTML={{ __html: desc }} /> */}
                    </div>
                </div>
            </Layout>
        );
    }

    /* eslint-disable jsx-a11y/anchor-is-valid */
    /* eslint-disable jsx-a11y/anchor-has-content */
    if (info) {
        return (
            <Layout>
                <div className="page">
                    <header className="page__header">
                        <button
                            onClick={() => setInfo(null)}
                            className="icon-btn page__icon-btn page__icon-btn_left icon-btn_back"
                        />
                        <p className="page__title">{t("scale_description")}</p>
                    </header>
                    <div className="scale-desc" style={{ flexGrow: 1 }}>
                        <h3>{info.name}</h3>
                        <h5>
                            {t("result")}: {info.score}
                        </h5>
                        <h4>{t("scale_description")}</h4>
                        <p>{info.info}</p>
                        <h4>
                            {t("scale_level")} {info.level}
                        </h4>
                        <p>{info.interpretation}</p>
                        <h4>{t("recommendations")}:</h4>
                        <p>{info.recommendation}</p>
                        {/* <p dangerouslySetInnerHTML={{ __html: desc }} /> */}
                    </div>
                </div>
            </Layout>
        );
    }

    if (isDocPage) {
        return <DocPage test={test} handler={docBtnHandler} doc={html} />;
    }

    if (diagram) {
        return (
            <Diagram
                handler={diagramBtnHandler}
                rows={rows}
                conditionName={test.condition.name}
                test={test}
            />
        );
    }

    const goBack = () => history.goBack();
    return (
        <Layout>
            <div className="page solution-page page__small">
                <header className="page__header">
                    <BackButton />
                    <a
                        onClick={goBack}
                        className="icon-btn page__icon-btn page__icon-btn_right icon-btn_close"
                    />
                    <p className="page__title solution-page__title">{t("statistic")}</p>
                </header>

                <div className="solution-page__main">
                    <div className="solution-page__info">
                        <p className="solution-page__name">
                            {test.pupil.name} {test.pupil.surname}
                        </p>
                        {test.pupil.birthday && (
                            <p className="solution-page__birthday">
                                {yearsOldCounter(test.pupil.birthday)}{" "}
                                {declinations(
                                    yearsOldCounter(test.pupil.birthday),
                                    "year",
                                    i18n.language
                                )}
                            </p>
                        )}
                    </div>
                    {role === "psych" && (
                        <div className="solution-page__contacts">
                            <Link to={`/pupils/${test.pupil._id}`} className="profile-page__btn">
                                {t("go_to_profile")}
                            </Link>
                        </div>
                    )}
                </div>

                <div className="solution-results">
                    <div className="solution-results__wrapper">
                        <p className="solution-results__title">{t("test_result")}</p>
                        <p className="solution-results__name">{test.condition.name}</p>
                        <p
                            className="solution-results__desc"
                            dangerouslySetInnerHTML={{
                                __html: `${t("authors")}: ${test.condition.methodAuthor}`,
                            }}
                        />
                        {role === "psych" && (
                            <>
                                <p
                                    style={{ fontSize: "16px", marginBottom: "0px" }}
                                    className="solution-results__name"
                                >
                                    {t("description_for_psych")}
                                </p>
                                <p
                                    className="solution-results__desc"
                                    dangerouslySetInnerHTML={{ __html: test.condition.desTeacher }}
                                />
                            </>
                        )}
                        <p
                            style={{ fontSize: "16px", marginBottom: "0px" }}
                            className="solution-results__name"
                        >
                            {t("instruction_for_pupil")}
                        </p>
                        <p
                            style={{ marginBottom: "40px" }}
                            className="solution-results__desc"
                            dangerouslySetInnerHTML={{ __html: test.condition.message }}
                        />
                        {test.solution.first.length > 0 && (
                            <span
                                onClick={() => {
                                    setIsTable1(true);
                                }}
                                className="solution-results__doc-btn solution-results__result-btn"
                            >
                                {t("answers")}
                            </span>
                        )}
                        {test.solution.text
                            ? test.solution.text.length > 0
                                ? test.solution.text.map((text) => {
                                      return (
                                          <span
                                              style={{ marginBottom: "10px" }}
                                              onClick={() => {
                                                  setIsTexts(true);
                                                  setCurrentText(text);
                                              }}
                                              className="solution-results__doc-btn"
                                          >
                                              <p>
                                                  {test.condition.name === "Родительская анкета"
                                                      ? "Описание происшествия"
                                                      : text.question}
                                              </p>
                                          </span>
                                      );
                                  })
                                : ""
                            : ""}
                        {test.solution.second.length > 0 ? (
                            <span
                                style={{ marginBottom: "10px" }}
                                onClick={() => {
                                    setIsTable2(true);
                                }}
                                className="solution-results__doc-btn"
                            >
                                {t("table")} 2
                            </span>
                        ) : (
                            ""
                        )}
                        <span
                            style={{ marginBottom: "10px" }}
                            onClick={docBtnHandler}
                            className="solution-results__doc-btn"
                        >
                            {t("test_info")}
                        </span>
                        <span
                            style={{ marginBottom: "10px" }}
                            onClick={diagramBtnHandler}
                            className="solution-results__doc-btn solution-results__diagram-btn"
                        >
                            {t("diagram")}
                        </span>
                        <Link
                            to={`/advices?pupilId=${test?.pupil?._id}`}
                            style={{ marginBottom: "10px" }}
                            className="solution-results__doc-btn solution-results__recommendation-btn"
                        >
                            {t("add_recommendation")}
                        </Link>
                        <Link
                            to={`/notes?pupilId=${test?.pupil?._id}`}
                            style={{ marginBottom: "10px" }}
                            className="solution-results__doc-btn solution-results__note-btn"
                        >
                            {t("add_note")}
                        </Link>

                        {role === "psych" && (
                            <>
                                <CreateReportButton
                                    url={`statistic/pupil-report/${test.pupil._id}?tests=${testId}`}
                                    fileName={`${t("report")}_${test.pupil.surname}_${
                                        test.pupil.name
                                    }.xlsx`}
                                    text={t("create_report_excel")}
                                    loadingText={t("report_loading", { ns: "translation" })}
                                />
                                <CreateReportButton
                                    url={`reports/pupil/${test.pupil._id}/${testId}`}
                                    fileName={`${t("report")}_${test.pupil.surname}_${
                                        test.pupil.name
                                    }}.docx`}
                                    text={t("create_report_word")}
                                    loadingText={t("report_loading", { ns: "translation" })}
                                />
                            </>
                        )}

                        <div className="solution-results__times" style={{ marginTop: 24 }}>
                            <div className="solution-result">
                                <p className="solution-result__title">{t("completion_time")}</p>
                                <div className="solution-result__row">
                                    <span className="solution-result__row-title">
                                        {role === "pupil"
                                            ? t("your_completion_time")
                                            : t("pupil_completion_time")}
                                        :
                                    </span>
                                    <span>{moment(test.completionTime * 1000).format("m:ss")}</span>
                                </div>
                                <div className="solution-result__row">
                                    <span className="solution-result__row-title">
                                        {t("average_completion_time")}:
                                    </span>
                                    <span>
                                        {moment(test.averageCompletionTime * 1000).format("m:ss")}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="solution-results__items">
                            {rows
                                ? rows.map((row, index) => (
                                      <div
                                          onClick={infoHandler}
                                          className="solution-result"
                                          id={index}
                                          key={index}
                                      >
                                          <button
                                              id={index}
                                              className="list__info-btn"
                                              onClick={scaleDescHandler}
                                          />
                                          <p className="solution-result__title">{row.name}</p>
                                          <div className="solution-result__row">
                                              <span className="solution-result__row-title">
                                                  {row.method}:
                                              </span>
                                              <span>{row.score}</span>
                                          </div>
                                          <div className="solution-result__row">
                                              <span className="solution-result__row-title">
                                                  {t("score")}:
                                              </span>
                                              <span
                                                  style={{ background: row.color }}
                                                  className="solution-result__mark"
                                              >
                                                  {row.title}
                                              </span>
                                          </div>
                                      </div>
                                  ))
                                : ""}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};
