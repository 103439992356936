export const selectMeetingTagStyles = {
    control: (base, state) => ({
        ...base,
        font: "inherit",
        fontSize: 16,
        lineHeight: "1.38",
        letterSpacing: "0.3px",
        backgroundColor: "transparent",
        backgroundImage: state.isFocused
            ? "linear-gradient(90deg, #4e54c8 0%, #8f94fb 100%)"
            : "linear-gradient(to left, rgba(18, 36, 67, 0.1), rgba(18, 36, 67, 0.1))",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "left bottom",
        backgroundSize: "100% 1px",
        border: 0,
        boxShadow: "none",
        animationName: state.isFocused ? "inputAnimation" : "",
        animationDuration: "0.5s",
        animationTimingFunction: "ease-in-out",
    }),
    valueContainer: (provided) => ({
        ...provided,
        padding: 0,
    }),
    singleValue: (provided) => ({
        ...provided,
        color: "#122443",
    }),
};
