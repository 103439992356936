import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

import { Layout } from "components/Layout";
import { getSchoolRegion } from "helpers/regions";

/**
 * @api {get} /advices/schools список школ для рекомендации
 * @apiGroup recommendations
 * @apiName список школ для рекомендации
 *
 * @apiDescription <p>Страница со списком школ для рекомендации</p>
 * <p>Компонент <code>RecommendationsSchools.js</code> <a href="../client/src/pages/advices/AdvicesSchools.js">ссылка на файл</a></p>
 */

export const AdvicesSchools = () => {
    const { t } = useTranslation("schools");
    const { user } = useSelector((state) => state.auth);
    const history = useHistory();
    const [schools, setSchools] = useState([]);
    const schoolRegion = getSchoolRegion(user);

    useEffect(() => {
        (async () => {
            const { data } = await axios.get("schools");
            setSchools(data);
        })();
    }, []);

    return (
        <Layout>
            <div className="page">
                <header className="page__header">
                    <button
                        onClick={() => history.push("/dashboard")}
                        className="icon-btn page__icon-btn page__icon-btn_left icon-btn_back"
                    />
                    <p className="page__title">{t("advices")}</p>
                </header>
                <div className="school-page__wrapper">
                    <div className="list">
                        {schools.map((school) => (
                            <Link
                                key={school._id}
                                className="list__item"
                                to={`/advices/schools/${school._id}/classes`}
                            >
                                <p>{school.name}</p>
                                <p className="list__desc">{schoolRegion}</p>
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
        </Layout>
    );
};
