import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export const Info = ({
    handler,
    name,
    instruction,
    id,
    conditionId,
    solution,
    author,
    desTeacher,
    message,
    scales,
    fromTests,
    isVisible,
}) => {
    const { t } = useTranslation("tests");
    const { role } = useSelector((state) => state.auth);
    const [step, setStep] = React.useState(0);
    const [showScaleDescription, setShowScaleDescription] = useState(false);
    const [showDescription, setShowDescription] = useState(false);
    let location = useLocation();

    useEffect(() => {
        if (location.pathname.includes("instruction") && !fromTests) {
            setStep(1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (showDescription) {
        return (
            <div className="page">
                <header className="page__header">
                    <button
                        onClick={() => {
                            setShowDescription(false);
                        }}
                        className="icon-btn page__icon-btn page__icon-btn_left icon-btn_back"
                    />
                    <p className="page__title">{t("description_for_psych")}</p>
                    <p
                        style={{ marginTop: 40, paddingBottom: 100 }}
                        dangerouslySetInnerHTML={{ __html: desTeacher }}
                    />
                </header>
                <h4
                    style={{
                        marginBottom: "10px",
                        fontWeight: "500",
                        fontSize: "18px",
                        opacity: 0,
                    }}
                >
                    _
                </h4>
            </div>
        );
    }

    if (showScaleDescription) {
        return (
            <div className="page">
                <header className="page__header">
                    <button
                        onClick={() => {
                            setShowScaleDescription(false);
                        }}
                        className="icon-btn page__icon-btn page__icon-btn_left icon-btn_back"
                    />
                    <p className="page__title">{t("scales")}</p>
                </header>
                {scales
                    ? scales.map((scale, orderId) => {
                          return (
                              <div style={{ flexGrow: 1, marginBottom: "30px" }} key={orderId}>
                                  <h4
                                      style={{
                                          fontSize: "18px",
                                          fontWeight: 500,
                                          marginBottom: "10px",
                                      }}
                                  >
                                      {orderId + 1 + ". " + scale.name}
                                  </h4>
                                  <p
                                      style={{ fontSize: "14px", opacity: "0.5", fontWeight: 400 }}
                                      dangerouslySetInnerHTML={{ __html: scale.description }}
                                  />
                              </div>
                          );
                      })
                    : ""}
            </div>
        );
    }

    if (role === "psych" && step === 0) {
        return (
            <div className="page">
                <header className="page__header">
                    <button
                        onClick={handler}
                        className="icon-btn page__icon-btn page__icon-btn_left icon-btn_back"
                    />
                    <p className="page__title">{t("test_info")}</p>
                </header>
                <div style={{ flexGrow: 1 }}>
                    {role === "psych" || isVisible ? (
                        <h3 style={{ marginBottom: "10px" }}>{name}</h3>
                    ) : (
                        <h3 style={{ marginBottom: "10px" }}>{t("test")}</h3>
                    )}
                    {role === "psych" ? (
                        <>
                            <h4 style={{ marginBottom: "30px", fontWeight: "500", opacity: "0.5" }}>
                                {t("authors")}: {author}
                            </h4>
                        </>
                    ) : (
                        ""
                    )}
                    {role !== "pupil" ? (
                        <>
                            <h4
                                style={{
                                    marginBottom: "10px",
                                    fontWeight: "500",
                                    fontSize: "18px",
                                }}
                            >
                                {t("instruction_for_pupil")}
                            </h4>
                            <p
                                style={{ marginBottom: "50px" }}
                                dangerouslySetInnerHTML={{ __html: message }}
                            />
                        </>
                    ) : (
                        ""
                    )}
                </div>
                {/* eslint-disable jsx-a11y/anchor-is-valid */}
                {role !== "pupil" ? null : (
                    <>
                        <a
                            onClick={() => {
                                setStep(1);
                            }}
                            className="main-btn"
                        >
                            {role === "pupil" ? t("read_instruction") : t("view_questions")}
                        </a>
                    </>
                )}
                {role === "pupil" ? null : (
                    <>
                        <a
                            onClick={() => {
                                setShowDescription(true);
                            }}
                            style={{
                                marginBottom: "20px",
                                background: "linear-gradient(270deg, #EB3349 0%, #F45C43 100%)",
                                boxShadow: "0px 2px 10px -3px #EF4746",
                                cursor: "pointer",
                            }}
                            className="main-btn"
                        >
                            {t("method_info")}
                        </a>
                        <a
                            onClick={() => {
                                setShowScaleDescription(true);
                            }}
                            style={{
                                marginBottom: "20px",
                                background: "linear-gradient(90deg, #ff8008 0%, #ffc837 100%)",
                                boxShadow: "0px 2px 10px -3px #ffa520",
                                cursor: "pointer",
                            }}
                            className="main-btn"
                        >
                            {t("view_scales")}
                        </a>
                        <Link
                            to={`/tests/${conditionId}/${conditionId}?type=view`}
                            className="main-btn"
                        >
                            {t("view_questions")}
                        </Link>
                    </>
                )}
            </div>
        );
    }

    return (
        <div className="page">
            <header className="page__header">
                <button
                    onClick={() => {
                        role === "pupil" ? handler() : setStep(0);
                    }}
                    className="icon-btn page__icon-btn page__icon-btn_left icon-btn_back"
                />
                <p className="page__title">{t("instruction")}</p>
            </header>
            <div style={{ flexGrow: 1 }}>
                {role === "psych" || isVisible ? <h3>{name}</h3> : <h3>Тест</h3>}
                <p dangerouslySetInnerHTML={{ __html: instruction }} />
            </div>
            {solution && role === "pupil" ? null : (
                <Link to={`/tests/${id}/${conditionId}`} className="main-btn">
                    {role === "pupil" ? t("pass_test") : t("view_questions")}
                </Link>
            )}
        </div>
    );
};
